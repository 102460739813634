'use strict';

angular.module('ufcw', [
  'toaster',
    'ngAria',
    'ngCookies',
    'ngMessages',
    'ngResource',
    'ui.router',
    'ngSanitize',
    'ui.bootstrap',
    'rzSlider',
    'pascalprecht.translate',
    'ja.qr'
  ]);

'use strict';

angular.module("ufcw")

.config(["$stateProvider", "$urlRouterProvider", function ($stateProvider, $urlRouterProvider) {

    $urlRouterProvider.otherwise('/signin');

    $stateProvider
      .state('signin', {
        url: '/signin',
        templateUrl: '../views/signin.html',
        controller: 'SigninController as signinCtrl'
      })
      .state('start', {
        url: '/start',
        templateUrl: '../views/start.html',
        controller: 'StartController as startCtrl'
      })
      .state('login/:id/:token', {
        url: '/login',
        templateUrl: '../views/signin.html',
        controller: 'LoginController as loginCtrl'
      })
      .state('home', {
        url: '/home',
        templateUrl: '../views/home.html',
        controller: 'HomeController as homeCtrl'
      })
      .state('reservaactividad/:id', {
        url: '/reservaactividad',
        templateUrl: '../views/reservaactividad.html',
        controller: 'ReservaactividadController as raCtrl'
      })
      .state('reservaactividadcustom', {
        url: '/reservaactividadcustom',
        params: {activity: null},
        templateUrl: '../views/reservaactividadcustom.html',
        controller: 'ReservaactividadcustomController as racCtrl'
      })
      .state('signup', {
        url: '/signup',
        templateUrl: '../views/signup.html',
        controller: 'SignupController as signupCtrl'
      })
      .state('welcome/:token', {
        url: '/welcome',
        templateUrl: '../views/welcome.html',
        controller: 'WelcomeController as welcomeCtrl'
      })
      .state('recovery', {
        url: '/recovery',
        templateUrl: '../views/recovery.html',
        controller: 'RecoveryController as recoveryCtrl'
      })
      .state('setpwd', {
        url: '/setpwd',
        templateUrl: '../views/setpwd.html',
        controller: 'RecoveryController as recoveryCtrl'
      })
      .state('misreservas', {
        url: '/misreservas',
        templateUrl: '../views/misreservas.html',
        controller: 'MisreservasController as mrCtrl'
      })
      .state('historialreservas', {
        url: '/historialreservas',
        templateUrl: '../views/historialreservas.html',
        controller: 'HistorialreservasController as hrCtrl'
      })
      .state('waitinglist', {
        url: '/waitinglist',
        templateUrl: '../views/waitinglist.html',
        controller: 'WaitinglistController as wlCtrl'
      })
      .state('myprofile', {
        url: '/myprofile',
        templateUrl: '../views/myprofile.html',
        controller: 'MyProfileController as mpCtrl'
      })
      .state('notice', {
        url: '/notice',
        templateUrl: '../views/notice.html',
        controller: 'NoticeController as ntcCtrl'
      })
      .state('horarios', {
        url: '/horarios',
        templateUrl: '../views/horarios.html',
        controller: 'HorariosController as fcCtrl'
      })
      .state('horariosza', {
        url: '/horariosza',
        templateUrl: '../views/horariosza.html',
        controller: 'HorarioszaController as hzaCtrl'
      })
      .state('fronthorarios/:id', {
        url: '/fronthorarios',
        templateUrl: '../views/fronthorarios.html',
        controller: 'FronthorariosController as fhCtrl'
      })
      .state('reglas', {
        url: '/reglas',
        templateUrl: '../views/reglas.html',
        controller: 'ReglasController as reCtrl'
      })
      .state('message', {
        url: '/message',
        templateUrl: '../views/message.html',
        controller: 'MessageController as msgCtrl'
      })
      .state('advancedsearch', {
        url: '/advancedsearch',
        templateUrl: '../views/advancedsearch.html',
        controller: 'AdvancedsearchController as asCtrl'
      })                
      .state('qr', {
        url: '/qr',
        templateUrl: '../views/qr.html',
        controller: 'QrController as qrCtrl'
      })   
      .state('cuponeras/:id', {
        url: '/cuponeras',
        templateUrl: '../views/cuponeras.html',
        controller: 'CuponeraController as cpCtrl'
      })     
      .state('comprarcuponera', {
        url: '/comprarcuponera',
        templateUrl: '../views/comprarcuponera.html',
        controller: 'ComprarcuponeraController as ccpCtrl'
      })    
      .state('fixedactivities', {
        url: '/fixedactivities',
        templateUrl: '../views/fixedactivities.html',
        controller: 'FixedactivitiesController as fxCtrl'
      })
      .state('homecanchas', {
        url: '/homecanchas',
        templateUrl: '../views/homecanchas.html',
        controller: 'HomeCanchaController as homecanCtrl'
      })       
      .state('cancha/:id', {
        url: '/cancha',
        templateUrl: '../views/canchas.html',
        controller: 'CanchaController as chaCtrl'
      })          
      .state('reservacancha/:id', {
        url: '/reservacancha',
        templateUrl: '../views/reservacancha.html',
        controller: 'ResCanchaController as rescanCtrl'                
      })  
      .state('routine', {
        url: '/routine',
        templateUrl: '../views/routine.html',
        controller: 'RoutineController as rtCtrl'
      })        
      .state('map', {
        url: '/map',
        templateUrl: '../views/map.html',
        controller: 'MapController as rtCtrl'
      })        
  }])
  .config(["$qProvider", function ($qProvider) {
    $qProvider.errorOnUnhandledRejections(false);
}])
.config(['$locationProvider', function($locationProvider) {
  $locationProvider.html5Mode(true);
  $locationProvider.hashPrefix('');
}])
.run(["$locale", function ($locale) {
  $locale.NUMBER_FORMATS.GROUP_SEP = ".";
  $locale.NUMBER_FORMATS.DECIMAL_SEP = ",";
}])
.config(['$translateProvider', function($translateProvider) {
  $translateProvider.translations('en', en_lang);
  $translateProvider.translations('es', es_lang);
  $translateProvider.preferredLanguage('es');
  $translateProvider.useSanitizeValueStrategy('escape')

}]);

angular.module("ufcw")

.factory('$localstorage', ['$window', function($window) {
  return {
    set: function(key, value) {
      $window.localStorage[key] = value;
    },
    get: function(key, defaultValue) {
      return $window.localStorage[key] || defaultValue || false;
    },
    setObject: function(key, value) {
      $window.localStorage[key] = JSON.stringify(value);
    },
    getObject: function(key, defaultValue) {
      if($window.localStorage[key] != undefined){
          return JSON.parse($window.localStorage[key]);
      }else{
        return defaultValue || false;
      }
    },
    remove: function(key){
      $window.localStorage.removeItem(key);
    },
    clear: function(){
      $window.localStorage.clear();
    }
  }
}]);

angular.module("ufcw")

.factory('Request', ["$http", "$localstorage", "EnvConfig", "$log", "$state", "toaster", "$translate", function($http,$localstorage,EnvConfig,$log,$state,toaster,$translate){
        return {
          get: function(params,need_token,callback){
            var config = {
              method: "GET",
              url: EnvConfig.URL_API + params,
              headers: {"Content-Type": "application/json"}
            };
            if(need_token){
              var token = $localstorage.get('token');
              config.headers["Authorization"] = "Bearer "+token+"";
            }
            $http(config).then(
              function success(response) {
                callback(response.data);
              },
              function error(err){
                if(err.data.status === "401"){
                  toaster.pop('error', "", $translate.instant('rest.session_expired'));
                  callback($translate.instant('rest.session_expired'));
                  $localstorage.clear();
                  $state.go('signin');
                }else{
                  callback(err.data);
                }
              }
            );
          },
          post: function(params,need_token,data,callback){
            var config = {
              method: "POST",
              url: EnvConfig.URL_API + params,
              data: JSON.stringify(data),
              headers: {"Content-Type": "application/json"}
            };
            if(need_token){
              var token = $localstorage.get('token');
              config.headers["Authorization"] = "Bearer "+token+"";
            }
            $http(config).then(
              function success(response) {
                callback(response.data);
              },
              function error(err){
                if(err.data.status === "401"){
                  toaster.pop('error', "", $translate.instant('rest.session_expired'));
                  callback($translate.instant('rest.session_expired'));
                  $localstorage.clear();
                  $state.go('signin');
                }else{
                  callback(err.data);
                }
              }
            );
          },
          put: function(params,need_token,data,callback){
            var config = {
              method: "PUT",
              url: EnvConfig.URL_API + params,
              data: JSON.stringify(data),
              headers: {"Content-Type": "application/json"}
            };
            if(need_token){
              var token = $localstorage.get('token');
              config.headers["Authorization"] = "Bearer "+token+"";
            }
            
            $http(config).then(
              function success(response) {
                callback(response.data);
              },
              function error(err){
                if(err.data.status === "401"){
                  toaster.pop('error', "", $translate.instant('rest.session_expired'));
                  callback($translate.instant('rest.session_expired'));
                  $localstorage.clear();
                  $state.go('signin');
                }else{
                  callback(err.data);
                }
              }
            );
          },
          delete: function(params,need_token,callback){
            var config = {
              method: "DELETE",
              url: EnvConfig.URL_API + params,
              headers: {"Content-Type": "application/json"}
            };
            if(need_token){
              var token = $localstorage.get('token');
              config.headers["Authorization"] = "Bearer "+token+"";
            }
            $http(config).then(
              function success(response) {
                callback(response.data);
              },
              function error(err){
                if(err.data.status === "401"){
                  toaster.pop('error', "", $translate.instant('rest.session_expired'));
                  callback($translate.instant('rest.session_expired'));
                  $localstorage.clear();
                  $state.go('signin');
                }else{
                  callback(err.data);
                }
              }
            );
          },
          file: function(params,need_token,data,file,callback){
            var fd = new FormData();

            data.map(function(item){
              fd.append(item.name,item.value);
            });

            fd.append('file',file);
            var config = {
              method: "POST",
              url: EnvConfig.URL_API + params,
              data: fd,
              headers: {"Content-Type": undefined}
            };
            if(need_token){
              var token = $localstorage.get('token');
              config.headers["Authorization"] = "Bearer "+token+"";
            }
            $http(config).then(
              function success(response) {
                callback(response.data);
              },
              function error(err){
                if(err.data.status === "401"){
                  toaster.pop('error', "", $translate.instant('rest.session_expired'));
                  callback($translate.instant('rest.session_expired'));
                  $localstorage.clear();
                  $state.go('signin');
                }else{
                  callback(err.data);
                }
              }
            );
          },
          getExternal: function(url,need_token,callback){
            var config = {
              method: "GET",
              url: url,
              headers: {"Content-Type": "application/json"}
            };
            if(need_token){
              var token = $localstorage.get('token');
              config.headers["Authorization"] = "Bearer "+token+"";
            }
            $http(config).then(
              function success(response) {
                callback(response);
              },
              function error(err){
                  callback(err.data);
              }
            );
          },
        };
      }]);
'use strict';

angular.module("ufcw")
.directive("fileinput", [function() {
  return {
    scope: {
      fileinput: "=",
      filepreview: "="
    },
    link: function(scope, element, attributes) {
      element.bind("change", function(changeEvent) {
        scope.fileinput = changeEvent.target.files[0];
        var reader = new FileReader();
        reader.onload = function(loadEvent) {
          scope.$apply(function() {
            scope.filepreview = loadEvent.target.result;
          });
        }
        reader.readAsDataURL(scope.fileinput);
      });
    }
  }
}]);


'use strict';

angular.module("ufcw")

.directive('formatDate', function() {
  return {
    restrict: 'A',
    link: function(scope, element, attrs) {
      element.on('keydown', function(e){
        var k = e.keyCode;

        if(
          k != 8 &&
          k != 9 &&
          k != 13 &&
          (( k < 48 || k > 105 ) || (k > 57 && k < 96))
        ){
          e.preventDefault();
        }
      });
      element.on('keyup', function(e){
        var v = element.val();
        var k = e.keyCode;
        if(k == 8 && (v.length == 3 || v.length == 6)){
          scope.$apply(function () {
            element.val( v.slice(0,-1) );
          });
        }
        else if(v.length == 2 || v.length == 5){
          scope.$apply(function () {
            element.val( v + "/");
          });
        }
      });
    }
  }
});

'use strict';

angular.module("ufcw")
.directive('loading', ['$http','$rootScope', function ($http) {
    return {
      restrict: 'A',
      link: function (scope, element, attrs) {
        scope.isLoading = function () {
          return $http.pendingRequests.length > 0;
        };
        scope.$watch(scope.isLoading, function (value) {
            if (value) {
                element.removeClass('ng-hide'); //muestro

            } else {
              element.addClass('ng-hide'); //oculto
            }
          
        });
      }
    };
}]);


'use strict';

angular.module("ufcw")
.filter('ageRange', function() {
    return function( items, ageselected ) {
        var filtered = [];
        if(typeof ageselected == 'undefined'){
            return items;
        }
        angular.forEach(items, function(item) {
            if( item.agefrom <= ageselected && item.ageto >= ageselected ) {
                filtered.push(item);
            }
        });
        return filtered;
    };
});

'use strict';

angular.module("ufcw")
.filter('capitalFirst', function() {
  return function(input) {
    return (!!input) ? input.charAt(0).toUpperCase() + input.substr(1).toLowerCase() : '';
  }
});

'use strict';

angular.module("ufcw")

.filter('escapeRegex', function(){
    return function(str){
      return (str?str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&"):"");
    }
});
  

'use strict';

angular.module("ufcw")
  .filter('orderObjectBy', function(){
    return function(input, attribute) {
       if (!angular.isObject(input)) return input;
   
       var array = [];
       for(var objectKey in input) {
           array.push(input[objectKey]);
       }
   
       array.sort(function(a, b){
           a = parseInt(a[attribute]);
           b = parseInt(b[attribute]);
           return a - b;
       });
       return array;
    }
   });
'use strict';

angular.module("ufcw")
  .filter('time', ["$filter", function($filter) {
    return function(input) {
     var date = new Date('1970-01-01 '+input);
      return($filter('date')(date, 'HH:mm') );
    }
  }]);

var en_lang = {
    "signin":{
        "email_required": "Email required",
        "email_invalid": "Email invalid",
        "pwd_required": "Password required",
        "do_not_register": "Not signed in?",
        "create_account": "Create account",
        "soon": "You will be able to make reservations soon. Thanks for your patience!"
    },
    "recovery":{
        "text": "No problem enter your email addres and we will send you a code to reset your password."
    },
    "rest": {
        "session_expired": "Session expired, try again"
    },    
    "general":{
        "view_schedule": "See Timetable",
        "forgot_your_pwd": "Lost password?",
        "email": "Email",
        "password": "Password",
        "developed_by": "Developed by",
        "back": "Back",
        "next_day": "Next day",
        "my_profile": "My Profile",
        "my_bookings": "My Reservations",
        "rules": "Rules",
        "covid_notice": "Report COVID-19",
        "session_close": "Sign Out",
        "reserve": "Book now",
        "page_title": "Reservation Manager",
        "contact_us": "Contact Us",
        "all_activities": "All Activities",
        "all_categories": "All Categories",
        "all_buildings": "All Buildings",
        "all_people": "All Persons",
        "all_ages": "All Ages",
        "member_num": "Membership number",
        "member_id": "Identification number",
        "dob": "Date of Birth",
        "phone": "Phone number",
        "my_family_group": "My family group",
        "yes": "YES",
        "no": "NO",
        "of": "from ",
        "activities": "Activities",
        "messages": "My Messages",
        "carnetExpiration": "Healt certificate expiration",
        "payExpiration": "Payment expiration",
        "expiration_title": "Expiration notification",
        "carnetExpiration_message": "Your health certificate expires ",
        "payExpiration_mensual_message": "We inform that your monthly payment expired at ",
        "payExpiration_anual_message": "Your year payment expires at ",
        "documents": "Documentos",
        "buy_cuponeras": "Comprar cuponeras",
        "cuponeras": "Cuponeras",
        "view_routine":"See Workout's Routines"
    },
    "advsearch":{
        "filter": "Filter",
        "schedule": "Schedule",
        "age": "age",
        "no_activities": "No activities for the applied filter",
        "title": "Advanced Search"
    },
    "button": {
        "send": "Send",
        "signin": "Sign In",
        "search": "Search",
        "cancel": "Cancel",
        "reserve": "Book Time",
        "register": "Register",
        "enter": "Enter",
        "notify": "Notify",
        "close": "Close",
        "save": "Save",
        "confirm": "Book Time",
        "pending":"In process"
    },
    "days": {
        "mon": "MON",
        "tue": "TUE",
        "wed": "WED",
        "thu": "THU",
        "fri": "FRI",
        "sat": "SAT",
        "sun": "SUN",
        "monday": "Monday",
        "tuesday": "Tuesday",
        "wednesday": "Wednesday",
        "thursday": "Thursday",
        "friday": "Friday",
        "saturday": "Saturday",
        "sunday": "Sunday"
    },
    "months": {
        "january": "January",
        "february": "February",
        "march": "March",
        "april": "April",
        "may": "May",
        "june": "June",
        "july": "July",
        "august": "August",
        "september": "September",
        "october": "October",
        "november": "November",
        "december": "December"
    },
    "reserve": {
        "reserve": "Book",
        "gender": "Gender",
        "activity": "Hospital",
        "place": "Place",
        "status": "Status",
        "date": "Date",
        "hour": "Time",
        "member": "Member",
        "assistance": "Assistance",
        "info": "Info",
        "guests": "Guests",
        "view_guests": "View Guests",
        "view_my_waitinglist": "My waiting lists",
        "view_my_history": "Reservation History",
        "reserved": "RESERVED",
        "canceled": "CANCELED",
        "activity_suspended": "ACTIVITY SUSPENDED",
        "does_not_have_reserve" : "No reservations",
        "info_activity" : "Activity info",
        "add_to_waitinglist" : "Add to waiting list",
        "available_places": "places available",
        "unlimited_places": "Unlimited places",
        "there_not": "There's not",
        "view_class": "Watch Class",
        "reserve_for_family": "Book time for family member",
        "no_activities_for_today": "No activities today",
        "actions": "Actions",
        "remove_from_waitinglist": "Remove from waiting list",
        "family_assign": "Assign family member",
        "member_num_id": "Membership/ID Number",
        "guests_to": "Guest to ",
        "for_me": "For me",
        "waiting_text": "Find out when there is availability.",
        "history": "Reservation History",
        "status_reserved": "Reserved",
        "status_canceled": "Canceled",
        "status_active": "Active",
        "my_reserves": "My Reservations",
        "canceled_ok": "Reservation canceled.",
        "cancel": "Cancel reservation",
        "must_select_dependent": "Must pick a family member",
        "title": "Schedule of activities",
        "success": "Reservation succesfully made",
        "modal_title_confirm": "Confirm reservation",
        "modal_title_cancel": "Cancel reservation",
        "add_to_waitinglist": "Add to waiting list",
        "waitinglist_added": "Successfully added to waiting list",
        "no_activities_yet": "There are no activities yet",
        "building": "Building"
    },
    "maintenance": {
        "title": "We are under maintenance!",
        "msg": "Thanks for your patience.",
        "msg2": "Please try again later."
    },
    "setpwd": {
        "title": "Passsword recovery assistant.",
        "sub_title": "Please enter the code sent by email, and then enter your password.",
        "code": "Code",
        "required_code": "Code required",
        "confirm_pwd": "Retype password",
        "pwd_not_match": "Password dont match"
    },
    "signup": {
        "title": "Member Signup",
        "sub_title": "Please enter your information and press signup.",
        "sub_title2": "You will get a email to confirm you own your email account.",
        "member_num": "Membership number",
        "member_num_required": "Membership number required",
        "member_id_required": "Identification number required",
        "member_id_invalid": "Please enter Identification number without points or slashes",
        "dob": "Date of Birth",
        "dob_required": "Date of Birth required",
        "cellphone": "Mobile number",
        "cellphone_required": "Mobile number required",
        "confirm_pwd_required": "Retype password required",
        "success": "User registered successfully! Please check your email to complete the registration process."
    },
    "welcome": {
        "title": "Congratulations, account created successfully!",
        "sub_title": "Yo can signin and book your activities!",
        "success": "User created successfully!"
    },
    "privacy": {
        "title": "Privacy Policy"
    },
    "notice": {
        "title": "Report COVID-19",
        "text": "When you report COVID positive we will start our internal protocols. 1st calling you to the provided phone to confirm informaiton. After we will alert members which shared acitivties with you that they where in contact with a COVID positive. We warrant confidentiality of your information."
    },
    "rules": {
        "title": "Rules"
    },
    "confirm": {
        "title": "I confirm COVID-19 positive",
        "text": "Are you sure to confirm COVID-19 positive and notify us?"
    },
    "profile": {
        "title": "My profile",
        "current_pwd": "Current Password",
        "current_pwd_invalid": "Invalid Current password",
        "current_pwd_placeholder": "Enter last password",
        "new_pwd": "New Password",
        "new_pwd_invalid": "Invalid New Password",
        "new_pwd_placeholder": "Enter New Password",
        "saved": "Profile updated.",
        "phone_invalid": "Wrong Phone Number",
        "select_lang": "Select language",
        "lang": "Language"
    },
    "showinfo": {
        "about": "About"
    },
    "waitinglist": {
        "title": "Waiting lists",
        "removed": "Removed from waiting list."
    },
    "lang": {
        "es": "Spanish",
        "en": "English"
    },
    "message": {
        "open": "Open",
        "delete": "Delete message",
        "you_dont_have": "You don't have any message to show",
        "received":"Received",
        "at": "at"
    },
    "api": {
        "general": {
            "days": {
                "monday": "Monday",
                "tuesday": "Tuesday",
                "wednesday": "Wednesday",
                "thursday": "Thursday",
                "friday": "Friday",
                "saturday": "Saturday",
                "sunday": "Sunday"
            },
            "month": {
                "january": "January",
                "february": "February",
                "march": "March",
                "april": "April",
                "may": "May",
                "june": "June",
                "july": "July",
                "august": "August",
                "september": "September",
                "october": "October",
                "november": "November",
                "december": "December"
            }
        },
        "api-admin": {
            "activitiescanceled": {
                "id_required": "Activity ID required",
                "invalid_id": "Invalid ID",
                "date_required": "Date required",
                "user_id_required": "User ID required",
                "cancel_reason": "Cancelation reason is required",
                "list_user_required": "The list of members is required"
            },
            "activitycategory": {
                "id_required": "Category ID required",
                "invalid_id": "Invalid ID",
                "was_deleted": "Category was deleted",
                "name_required": "Name required"
            },
            "activity": {
                "id_required": "Activity ID required",
                "invalid_id": "Invalid ID",
                "name_required": "Name required",
                "category_id_required": "Category required",
                "company_id_required": "Company required",
                "status_required": "Status required"
            },
            "activitytime": {
                "user_required": "User required",
                "invalid_conf": "Invalid configuration",
                "invalid_activity": "Activity ID invalid",
                "activity_required": "Activity ID required",
                "starttime_required": "Start time required",
                "endtime_required": "End time required"
            },
            "adminuser": {
                "invalid_action": "Invalid action",
                "user_name_required": "User name required",
                "user_lastname_required": "User last name required",
                "company_id_required": "Company required",
                "user_type_required": "User type required",
                "invalid_user_type": "Invalid user type",
                "user_email_required": "User required",
                "user_id_required": "User ID required",
                "invalid_user_id": "Invalid user",
                "email_exist": "Email already exist",
                "user_deleted": "User deleted"
            },
            "assistance": {
                "list_user_required": "Members list required",
                "list_check_user": "List saved, please double check the attendees, there where updates after opening this page.",
                "list_ok": "List entered"
            },
            "fixreservation": {
                "user_id_required": "User ID required",
                "activity_required": "Activity ID required",
                "date_required": "Date required",
                "invalid_user_id": "Invalid user",
                "invalid_activity": "Invalid Activity",
                "reservation_exists": "Reservation already exists"
            },
            "holiday": {
                "id_required": "ID required",
                "invalid_id": "Invalid ID",
                "deleted_ok": "Deleted OK",
                "name_required": "Name required",
                "year_required": "Year required",
                "month_required": "Month required",
                "day_required": "Day required"
            },
            "location": {
                "id_required": "ID required",
                "invalid_id": "Invalid ID",
                "name_required": "Name required"
            },
            "message": {
                "no_msg": "No messages",
                "msg_not_exist": "Message doesn't exist",
                "saved": "Message saved",
                "invalid_id": "Invalid ID",
                "read": "Message read",
                "msg_deleted": "Message deleted"
            },
            "product": {
                "id_required": "ID required",
                "invalid_id": "Invalid ID",
                "deleted_ok": "Deleted OK",
                "name_required": "Name required",
                "price_required": "Price required",
                "currency_required": "Currency required"
            },
            "recovery": {
                "email_required": "User mail required",
                "invalid_email": "Invalid user mail",
                "invalid_user_id": "Invalid user",
                "email_sent": "Mail sent",
                "code_required": "Recovery code required",
                "pwd_required": "Password required",
                "invalid_code": "Invalid code",
                "expired_code": "Expired code",
                "pwd_change_ok": "Password updated",
                "pwd_recovery": "Password recovery"
            },
            "report": {
                "type_required": "Type required",
                "date_from_required": "Start date required",
                "date_to_required": "End date required"
            },
            "reservation": {
                "type_required": "Type required",
                "invalid_user_id": "Invalid user",
                "id_required": "ID required",
                "error_check_attendance": "There was a problem with the reservation, couldn't mark assistance",
                "error_cancel_attendance": "There was a problem with the reservation, it couldn't be canceled",
                "user_id_required": "User ID required",
                "activity_id_required": "Activity ID required",
                "invalid_conf": "Invalid configuration",
                "user_deleted": "Member unsubscribed correctly",
                "reservation_not_allowed": "Dear user, you are not authorized to make reservations. Reason:",
                "invalid_schedule": "Invalid schedule",
                "invalid_reservation": "Invalid reservation",
                "generic_error": "An error occurred, please close the page and try again.",
                "invalid_activity": "Invalid Activity",
                "invalid_category": "Invalid Category",
                "invalid_Location": "Invalid Location",
                "no_more_places": "There are no more spots for this activity, try to book another time or another activity ...",
                "invalid_user_cat": "Invalid user category",
                "dob_error": "There is a problem with your date of birth, please contact administration to fix it",
                "out_of_age": "This activity isn't for your age.",
                "reservation_same_time": "You already have reservations at the same time.",
                "reservation_exists": "Reservation already exist",
                "penalization_msg_start": "You have a penalty of",
                "penalization_msg_end": "hours",
                "same_activity_msg_start": "Can't book ",
                "same_activity_msg_end": "consecutive activities of the same category",
                "max_per_day_msg_start": "You can't book more than",
                "max_per_day_msg_end": "activities same day",
                "not_allowed_only_members": "Acción no permitida para su tipo de usuario."
            },
            "responsible": {
                "user_name_required": "User name required",
                "user_lastname_required": "User last name required",
                "user_extid_required": "User number required",
                "company_id_required": "Company required",
                "user_email_required": "Mail required",
                "user_id_required": "User ID required",
                "invalid_user_id": "Invalid user"
            },
            "signin": {
                "user_id_required": "User ID required",
                "pwd_required": "Password required",
                "invalid_user_id": "Invalid username or password",
                "invalid_user": "Invalid user"
            },
            "signup": {
                "user_email_required": "User required",
                "company_id_required": "Company required",
                "invalid_user_email": "Invalid user",
                "user_name_required": "User name required",
                "user_lastname_required": "User last name required",
                "user_extid_required": "User number required",
                "pwd_required": "Password required",
                "user_type_required": "User type required",
                "invalid_user_type": "Invalid user type",
                "user_already_registered": "Email already registered",
                "invalid_user": "Invalid user",
                "reg_confirmation": "Confirmation of registration",
                "token_required": "User required"
            },
            "uploadimage": {
                "image_required": "Image required",
                "image_name_required": "Image name required",
                "file_not_image": "File is not an image",
                "invalid_type": "Invalid image type",
                "error_save": "Error saving image",
                "saved_ok": "Image saved"
            },
            "usercategory": {
                "id_required": "User ID required",
                "invalid_id": "Invalid ID",
                "name_required": "Name required",
                "age_from_required": "From age required",
                "age_to_required": "To age required"
            },
            "user": {
                "user_name_required": "User name required",
                "user_lastname_required": "User last name required",
                "user_extid_required": "User number required",
                "company_id_required": "Company required",
                "user_id_required": "User ID required",
                "invalid_user_id": "Invalid user",
                "invalid_email": "Invalid mail"
            },
            "usertype": {
                "id_required": "ID required",
                "invalid_id": "Invalid ID",
                "name_required": "Name required"
            },
            "whassistance": {
                "user_dont_exist": "User does not exist",
                "user_inactive": "Inactive user",
                "not_activities_today": "You have no activities scheduled today.",
                "assitant_error": "There was a problem with the reservation, couldn't check the attendance"
            },
            "whuser": {
                "user_name_required": "User name required. Received:",
                "user_lastname_required": "User last name required. Received:",
                "user_extid_required": "User number required. Received:",
                "company_id_required": "Company required. Received:",
                "doc_required": "User number required. Received:",
                "gender_required": "Company required. Received:"
            },
            "notification": {
                "id_required": "ID required",
                "subject_required": "Subject required",
                "title_required": "Title required",
                "text_required": "Body required",
                "invalid_user": "Invalid user",
                "invalid_main_user": "Invalid main user",
                "invalid_action": "Invalid action",
                "invalid_conf": "Invalid configuration",
                "email_sent": "Email sent",
                "invalid_schedule": "Invalid schedule",
                "invalid_activity": "Invalid Activity",
                "invalid_reservation": "Invalid ID",
                "confirm_reservation": "Reservation confirmation",
                "you_cancel": "You cancel",
                "we_cancel": "We cancel",
                "reason": "Reason:",
                "reservation_cancel": "Cancel"
            }
        },
        "api": {
            "activitytime": {
                "invalid_conf": "Invalid configuration",
                "user_required": "User required",
                "activity_required": "Activity required",
                "invalid_user": "Invalid user"
            },
            "email": {
                "id_required": "ID required",
                "subject_required": "Subject required",
                "title_required": "Title required",
                "text_required": "Body required",
                "invalid_user": "Invalid user",
                "invalid_main_user": "Invalid main user",
                "invalid_action": "Invalid action",
                "invalid_conf": "Invalid configuration",
                "email_sent": "Email sent"
            },
            "message": {
                "wrong_user": "Wrong user",
                "msg_not_exist": "Message doesn't exist",
                "was_an_error": "An error has occurred",
                "msg_saved": "Message saved",
                "invalid_id": "Invalid ID",
                "msg_read": "Message read"
            },
            "notification": {
                "id_required": "ID required",
                "subject_required": "Subject required",
                "title_required": "Title required",
                "text_required": "Body required",
                "invalid_user": "Invalid user",
                "invalid_main_user": "Invalid main user",
                "invalid_action": "Invalid action",
                "invalid_conf": "Invalid configuration",
                "email_sent": "Email sent",
                "invalid_schedule": "Invalid schedule",
                "invalid_activity": "Invalid Activity",
                "invalid_reservation": "Invalid ID"
            },
            "recovery": {
                "email_required": "User mail required",
                "invalid_email": "Invalid user mail",
                "invalid_user_id": "Invalid user",
                "email_sent": "Mail sent",
                "code_required": "Recovery code required",
                "pwd_required": "Password required",
                "invalid_code": "Invalid code",
                "expired_code": "Expired code",
                "pwd_change_ok": "Password updated",
                "not_access": "Disabled user, contact administration"
            },
            "reservation": {
                "type_required": "Type required",
                "invalid_user_id": "Invalid user",
                "id_required": "ID required",
                "user_id_required": "User ID required",
                "activity_id_required": "Activity ID required",
                "invalid_conf": "Invalid configuration",
                "user_deleted": "Unsubscribed user",
                "reservation_not_allowed": "Dear member, you are not authorized to make reservations. Reason:",
                "invalid_schedule": "Invalid schedule",
                "invalid_reservation": "Invalid reservation",
                "generic_error": "An error occurred, please close the page and try again.",
                "invalid_activity": "Invalid Activity",
                "invalid_category": "Invalid Category",
                "invalid_Location": "Invalid Location",
                "no_more_places": "There are no more places for this activity, try to book another time or another activity ...",
                "invalid_user_cat": "Invalid user category",
                "dob_error": "There is a problem with your date of birth, please contact administration to fix it",
                "out_of_age": "This activity is not for your age.",
                "reservation_same_time": "You already have reservations whose schedule coincides with other activities.",
                "reservation_exists": "Reservation already exists",
                "invalid_main_user_id": "Invalid main user",
                "invalid_action": "Invalid action",
                "invalid_user_category": "Your category doesn't allow you to book this activity",
                "penalization_msg_start": "You have a penalty of",
                "penalization_msg_end": "hours",
                "max_actives_reservation_msg_start": "You can't have more than",
                "max_actives_reservation_msg_end": " simultaneous reservations",
                "same_activity_msg_start": "Can't book ",
                "same_activity_msg_end": "consecutive activities of the same category",
                "max_per_day_msg_start": "You can't book more than",
                "max_per_day_msg_end": "activities the same day",
                "invalid_id": "Invalid ID",
                "cant_cancel_msg": "Sorry, the time to cancel this reservation was expired",
                "cancel_ok": "Reservation canceled",
                "free_place_msg": "Free spot alert",
                "free_place_body_a": "A spot was released for the class",
                "free_place_body_b": "from",
                "free_place_body_c": "to",
                "free_place_body_d": "for the day",
                "already_reserved": "This activity has been reserved for another member"
            },
            "signin": {
                "user_id_required": "User ID required",
                "pwd_required": "Password required",
                "invalid_user_id": "Invalid username or password",
                "dibaled_user_msg": "Disabled user, remember to check your email to validate it. If you have already done and can't log in, contact administration.",
                "invalid_conf": "Invalid configuration"
            },
            "signup": {
                "company_id_required": "Company required",
                "invalid_user_email": "Invalid user",
                "user_name_required": "User name required",
                "user_lastname_required": "User last name required",
                "user_extid_required": "User number required",
                "pwd_required": "Password required",
                "user_type_required": "User type required",
                "invalid_user_type": "Invalid user type",
                "user_already_registered": "Email already registered",
                "invalid_user": "Invalid user",
                "token_required": "User required",
                "user_email_required": "User email required",
                "invalid_email": "Invalid email",
                "invalid_conf": "Invalid configuration",
                "deleted_user": "Diabled user",
                "wrong_user_data": "Wrong user info",
                "confirmation": "Registration confirmation",
                "dibaled_user_msg": "Disabled user, remember to check your email to validate it. If you have already done and can't log in, contact administration."
            },
            "user": {
                "user_name_required": "User name required",
                "user_lastname_required": "User last name required",
                "user_extid_required": "User number required",
                "company_id_required": "Company required",
                "user_id_required": "User ID required",
                "invalid_user_id": "Invalid user",
                "invalid_email": "Invalid mail",
                "invalid_action": "Invalid action",
                "user_email_required": "User email required",
                "user_already_registered": "Email already exists",
                "last_pwd_invalid": "Invalid old password entered",
                "user_deleted": "User deleted"
            },
            "waitinglist": {
                "user_id_required": "User ID required",
                "invalid_user_id": "Invalid user",
                "activity_id_required": "Activity ID required",
                "invalid_schedule": "Invalid schedule",
                "user_already_exist": "You are already on the list",
                "user_added": "User added to waiting list",
                "invalid_reservation_id": "Invalid ID",
                "reservation_id_required": "Invalid ID",
                "invalid_action": "Invalid action",
                "invalid_main_user_id": "Invalid main user",
                "user_removed_from_list": "Member removed from waiting list"
            }
        }
    }
  };
var es_lang = {
    "signin":{
        "email_required": "Email requerido",
        "email_invalid": "Email inválido",
        "pwd_required": "Contraseña requerida",
        "do_not_register": "¿No estás registrado?",
        "create_account": "Crear cuenta",
        "soon": "Proximamente vas a poder acceder a hacer tus reservas online. Gracias por tu paciencia!"
    },
    "recovery":{
        "text": "No hay problema, ingresá tu dirección de correo y te enviaremos un e-mail con un código para que puedas ingresar tu nueva contraseña."
    },
    "rest": {
        "session_expired": "Sesión expirada, ingrese nuevamente"
    },
    "reservationcustom":{
        "type_custom": "Tipo de juego",
        "single": "Single", 
        "time_custom": "Tiempo de juego",
        "inittime": "Hora de inicio",
        "doble": "Doble",
        "error_document": "Cédula incorrecta",
        "member_num_id": "Ingrese Nro. de socio o cédula"
    },
    "general":{
        "view_schedule": "Ver horarios",
        "forgot_your_pwd": "¿Olvidaste la contraseña?",
        "email": "Email",
        "password": "Contraseña",
        "developed_by": "Desarrollado por",
        "back": "Volver",
        "next_day": "Siguiente día",
        "my_profile": "Mi Perfil",
        "my_bookings": "Mis Reservas",
        "rules": "Reglas",
        "covid_notice": "Aviso COVID-19",
        "session_close": "Cerrar sesión",
        "reserve": "Reservar",
        "page_title": "Gestor de Reservas",
        "contact_us": "Contáctanos",
        "all_activities": "Todas las actividades",
        "all_categories": "Todas las categorías",
        "all_buildings": "Todas las sucursales",
        "all_people": "Todas las personas",
        "all_ages": "Todas las edades",
        "select_activity": "Seleccione una actividad",
        "member_num": "Socio Nro.",
        "member_id": "Cédula",
        "dob": "Nacimiento",
        "phone": "Teléfono",
        "my_family_group": "Mi grupo familiar",
        "yes": "SI",
        "no": "NO",
        "of": "de",
        "activities": "Actividades",
        "messages": "Mis Mensajes",
        "carnetExpiration": "Venc. C. Salud",
        "payExpiration": "Venc. de Pago",
        "expiration_title": "Aviso",
        "carnetExpiration_message": "Le informamos que su C. Salud vence el ",
        "carnetAlreadyExpirated_message": "Le informamos que su C. Salud venció el ",
        "payExpiration_mensual_message": "Le informamos que su Mensualidad venció el ",
        "payExpiration_anual_message": "Le informamos que su Anualidad vence el ",
        "documents": "Documentos",
        "buy_cuponeras": "Comprar cuponeras",
        "edit_action": "Editar",
        "view_action": "Ver",
        "view_routine":"Rutinas de ejercicios"
        },
    "advsearch":{
        "filter": "Filtro",
        "schedule": "Horario",
        "age": "Edad",
        "no_activities": "No existen actividades para la selección",
        "title": "Búsqueda avanzada"
    },
    "button": {
        "send": "Enviar",
        "signin": "Entrar",
        "search": "Buscar",
        "cancel": "Cancelar",
        "reserve": "Reservar",
        "register": "Registrar",
        "enter": "Ingresar",
        "notify": "Notificar",
        "close": "Cerrar",
        "save": "Guardar",
        "confirm": "Confirmar",
        "pending":"En proceso",
        "login": "Iniciar sesión"
    },
    "days": {
        "mon": "LUN",
        "tue": "MAR",
        "wed": "MIE",
        "thu": "JUE",
        "fri": "VIE",
        "sat": "SAB",
        "sun": "DOM",
        "monday": "Lunes",
        "tuesday": "Martes",
        "wednesday": "Miércoles",
        "thursday": "Jueves",
        "friday": "Viernes",
        "saturday": "Sábado",
        "sunday": "Domingo"
    },
    "months": {
        "january": "Enero",
        "february": "Febrero",
        "march": "Marzo",
        "april": "Abril",
        "may": "Mayo",
        "june": "Junio",
        "july": "Julio",
        "august": "Agosto",
        "september": "Setiembre",
        "october": "Octubre",
        "november": "Noviembre",
        "december": "Diciembre"
    },
    "reserve": {
        "reserve": "Reserva",
        "gender": "Género",
        "activity": "Actividad",
        "place": "Lugar",
        "status": "Estado",
        "date": "Fecha",
        "hour": "Hora",
        "member": "Socio",
        "assistance": "Asistencia",
        "info": "Info",
        "guests": "Invitados",
        "view_guests": "Ver Invitados",
        "view_my_waitinglist": "Ver mis listas de espera",
        "view_my_history": "Ver historial de reservas",
        "reserved": "RESERVADA",
        "canceled": "CANCELADA",
        "activity_suspended": "SUSPENDIDA",
        "does_not_have_reserve" : "No tiene reservas para listar",
        "info_activity" : "Información sobre la actividad",
        "add_to_waitinglist" : "Agendarse a la lista de espera",
        "available_places": "lugares disponibles",
        "unlimited_places": "Lugares ilimitados",
        "there_not": "No hay",
        "view_class": "Ver clase",
        "reserve_for_family": "Reservar para un familiar",
        "no_activities_for_today": "No hay actividades para el día de hoy",
        "actions": "Acciones",
        "remove_from_waitinglist": "Quitarme de la lista de espera",
        "family_assign": "Asignar a un familiar",
        "member_num_id": "Cédula",
        "guests_to": "Invitados a",
        "for_me": "Para mí",
        "waiting_text": "Enterate cuando quede un cupo disponible.",
        "history": "Historial de Reservas",
        "status_reserved": "Reservado",
        "status_canceled": "Cancelado",
        "status_active": "Activo",
        "my_reserves": "Mis Reservas",
        "canceled_ok": "Reserva cancelada correctamente",
        "cancel": "Cancelar reserva",
        "must_select_dependent": "Debe seleccionar un familiar",
        "title": "Horarios de actividades",
        "success": "Reserva realizada correctamente",
        "modal_title_confirm": "Confirme la reserva",
        "modal_title_cancel": "Cancelar reserva",
        "waitinglist_added": "Ingresado a lista de espera correctamente",
        "invalid_guest": "La cédula ingresada no es correcta",
        "edit_guests": "Ver/Modificar invitados",
        "reservation_updated": "Reserva modificada correctamente",
        "no_activities_yet": "Aún no hay actividades para mostrar",
        "building": "Sucursal",
        "customer_search_txt": "Buscar socio"
    },
    "maintenance": {
        "title": "La plataforma de reservas está en mantenimiento!",
        "msg": "Por favor disculpa las molestias.",
        "msg2": "En breve podrás reservar nuevamente."
    },
    "setpwd": {
        "title": "Asistente de recuperación de contraseña",
        "sub_title": "Por favor ingresa el código que te enviamos por email, luego puedes ingresar la nueva contraseña y listo!",
        "code": "Código",
        "required_code": "Código requerido",
        "confirm_pwd": "Confirmar contraseña",
        "pwd_not_match": "Las contraseñas no coinciden"
    },
    "signup": {
        "title": "Registro de usuario",
        "sub_title": "Por favor completa tus datos y haz clic en el botón registrarse.",
        "sub_title2": "Recibirás un email a la casilla que ingreses con un link para confirmar tu cuenta.",
        "member_num": "Nro. de socio",
        "member_num_required": "Nro. de socio requerido",
        "member_id_required": "Cédula requerida",
        "member_id_invalid": "La cédula debe ser ingresada sin puntos ni guiones",
        "dob": "Fecha de nacimiento",
        "dob_required": "Fecha de nacimiento requerida",
        "cellphone": "Celular",
        "cellphone_required": "Celular requerido",
        "confirm_pwd_required": "Confirmar contraseña es requerido",
        "success": "Usuario registrado existosamente! Revisa tu correo que te hemos enviado un email de confirmación",
        "first_name": "Nombre",
        "first_name_required": "Nombre requerido",
        "last_name": "Apellido",
        "last_name_required": "Apellido requerido",
        "gender_male": "Masculino",
        "gender_female": "Femenino",
        "gender_required": "Sexo requerido",
        "member_common": "Socio ",
        "member_coupon": "Socio Cuponera"
    },
    "welcome": {
        "title": "¡Excelente, tu cuenta ha sido activada con éxito!",
        "sub_title": "¡Ya puedes ingresar y reservar tus clases!",
        "success": "Usuario activado existosamente!"
    },
    "privacy": {
        "title": "Politicas de privacidad"
    },
    "notice": {
        "title": "Aviso de COVID-19 positivo",
        "text": "Al comunicar que se realizó el examen y dio COVID positivo el club comenzará un protocolo primero corroborando con usted via teléfono que esta información es correcta. Una vez corroborada la información se procederá a comunicar a todos los socios que participaron en alguna actividad con usted que estuvieron en contacto con una persona COVID positivo. El club garantiza la confidencialidad de sus datos.",
        "msg_sent": "Aviso enviado correctamente"
    },
    "rules": {
        "title": "Reglas básicas de uso"
    },
    "confirm": {
        "title": "Confirmo COVID-19 positivo",
        "text": "Esta seguro que desea confirmar que es COVID-19 positivo y dar aviso al club?"
    },
    "profile": {
        "title": "Mis Datos",
        "current_pwd": "Contraseña actual",
        "current_pwd_invalid": "Contraseña actual inválida",
        "current_pwd_placeholder": "Indroduzca contraseña anterior",
        "new_pwd": "Contraseña nueva",
        "new_pwd_invalid": "Contraseña nueva inválida",
        "new_pwd_placeholder": "Introduzca contraseña nueva",
        "saved": "Datos de usuario actualizados correctamente",
        "phone_invalid": "Teléfono incorrecto",
        "select_lang": "Seleccione el idioma",
        "lang": "Idioma",
        "view_doc":"Ver Otro",
        "doc":"Otro",
        "view_cert_aptitude":"Ver C. Salud",
        "cert_aptitude":"C. Salud",
        "view_cert_covid":"Ver Resultado de Hisopado",
        "cert_covid":"Resultado de Hisopado"
    },
    "showinfo": {
        "about": "Información sobre",
        "reason_canceled": "Motivo de la suspensión"
    },
    "waitinglist": {
        "title": "Listas de espera",
        "removed": "Removido de la lista de espera correctamente"
    },
    "lang": {
        "es": "Español",
        "en": "Inglés"
    },
    "message": {
        "open": "Abrir",
        "delete": "Eliminar mensaje",
        "you_dont_have": "No tiene mensajes para listar",
        "received":"Recibido el",
        "at": "a las"
    },
    "api": {
        "general": {
            "days": {
                "monday": "Lunes",
                "tuesday": "Martes",
                "wednesday": "Miércoles",
                "thursday": "Jueves",
                "friday": "Viernes",
                "saturday": "Sábado",
                "sunday": "Domingo"
            },
            "month": {
                "january": "Enero",
                "february": "Febrero",
                "march": "Marzo",
                "april": "Abril",
                "may": "Mayo",
                "june": "Junio",
                "july": "Julio",
                "august": "Agosto",
                "september": "Setiembre",
                "october": "Octubre",
                "november": "Noviembre",
                "december": "Diciembre"
            }
        },
        "api-admin":{
            "activitiescanceled": {
                "id_required": "Identificador de actividad requerido",
                "invalid_id": "Identificador de actividad inválido",
                "date_required": "La fecha de la actividad es requerida",
                "user_id_required": "El identificador del usuario es requerido",
                "cancel_reason": "El motivo de la cancelación es requerida",
                "list_user_required": "La lista de socios es requerida"
            },
            "activitycategory": {
                "id_required": "Identificador de categoría requerido",
                "invalid_id": "Identificador de categoría inválido",
                "was_deleted": "Categoría eliminada correctamente",
                "name_required": "El nombre de la categoría es requerido"
            },
            "activity": {
                "id_required": "Identificador de actividad requerido",
                "invalid_id": "Identificador de actividad inválido",
                "name_required": "El nombre de la actividad es requerido",
                "category_id_required": "El identificador de categoría es requerido",
                "company_id_required": "El identificador de la compañía es requerido",
                "status_required": "Estado de la actividad requerido"
            },
            "activitytime": {
                "user_required": "Usuario requerido",
                "invalid_conf": "Configuración inválida",
                "invalid_activity": "Identificador de actividad inválida",
                "activity_required": "Identificador de actividad requerido",
                "starttime_required": "Hora de inicio requerida",
                "endtime_required": "Hora de fin requerida"
            },
            "adminuser": {
                "invalid_action": "Acción inválida",
                "user_name_required": "Nombre de usuario requerido",
                "user_lastname_required": "Apellido de usuario requerido",
                "company_id_required": "El identificador de la compañía es requerido",
                "user_type_required": "Tipo de usuario requerido",
                "invalid_user_type": "Tipo de usuario inválido",
                "user_email_required": "Usuario requerido",
                "user_id_required": "El identificador del usuario es requerido",
                "invalid_user_id": "Identificador de usuario inválido",
                "email_exist": "Ya existe el email",
                "user_deleted": "Usuario eliminado"
            },
            "assistance": {
                "list_user_required": "La lista de socios es requerida",
                "list_check_user": "Lista ingresada, revise los asistentes, existen socios que no fueron enviados en la lista",
                "list_ok": "Lista ingresada"
            },
            "fixreservation": {
                "user_id_required": "El identificador del usuario es requerido",
                "activity_required": "Identificador de actividad requerido",
                "date_required": "La fecha de la actividad es requerida",
                "invalid_user_id": "Identificador de usuario inválido",
                "invalid_activity": "Actividad inválida",
                "reservation_exists": "Reserva ya existe"
            },
            "holiday": {
                "id_required": "Identificador requerido",
                "invalid_id": "Identificador inválido",
                "deleted_ok": "Eliminado correctamente",
                "name_required": "Nombre requerido",
                "year_required": "Año requerido",
                "month_required": "Mes requerido",
                "day_required": "Día requerido"
            },
            "location": {
                "id_required": "Identificador requerido",
                "invalid_id": "Identificador inválido",
                "name_required": "Nombre requerido"
            },
            "message": {
                "no_msg": "Sin mensajes",
                "msg_not_exist": "No existe el mensaje",
                "saved": "Mensaje guardado",
                "invalid_id": "Identificador de mensaje inválido",
                "read": "Mensaje leído"
            },
            "product": {
                "id_required": "Identificador de producto requerido",
                "invalid_id": "Identificador de producto inválido",
                "deleted_ok": "Eliminado correctamente",
                "name_required": "Nombre requerido",
                "price_required": "Precio requerido",
                "currency_required": "Moneda requerido"
            },
            "recovery": {
                "email_required": "Email de usuario requerido",
                "invalid_email": "Email de usuario inválido",
                "invalid_user_id": "Identificador de usuario inválido",
                "email_sent": "Email enviado",
                "code_required": "Código de recuperación requerido",
                "pwd_required": "Contraseña requerida",
                "invalid_code": "Código inválido",
                "expired_code": "Código expirado",
                "pwd_change_ok": "Contraseña actualizada correctamente",
                "pwd_recovery": "Recuperación de contraseña"
            },
            "report": {
                "type_required": "Tipo requerido",
                "date_from_required": "Fecha incio requerida",
                "date_to_required": "Fecha fin requerida"
            },
            "reservation": {
                "type_required": "Tipo de reserva requerido",
                "invalid_user_id": "Identificador de usuario inválido",
                "id_required": "Identificador de reserva requerido",
                "error_check_attendance": "Hubo un problema con la reserva, no se pudo marcar la asistencia",
                "error_cancel_attendance": "Hubo un problema con la reserva, no se pudo cancelar",
                "user_id_required": "El identificador del usuario es requerido",
                "activity_id_required": "Identificador de actividad requerido",
                "invalid_conf": "Configuración inválida",
                "user_deleted": "Usuario dado de baja",
                "reservation_not_allowed": "Estimado socio, usted no está habilitado para realizar reservas. Motivo:",
                "invalid_schedule": "Horario de Actividad inválida",
                "invalid_reservation": "Reserva inválida",
                "generic_error": "Ocurrió un error, por favor cierre la página e intentelo nuevamente.",
                "invalid_activity": "Actividad inválida",
                "invalid_category": "Categoría inválida",
                "invalid_Location": "Espacio inválido",
                "error_document": "Cédula incorrecta",
                "member_num_id": "Ingrese cédula",
                "no_more_places": "Ya no quedan cupos para esta actividad, intenta reservar otro horario u otra actividad...",
                "invalid_user_cat": "Categoría de usuario inválida",
                "dob_error": "Hay un problema con su fecha de nacimiento, por favor comuníquese con administración para solucionarlo",
                "out_of_age": "Esta actividad no es para su edad.",
                "reservation_same_time": "Ya tiene reservas cuyo horario coincide con otras actividades.",
                "reservation_exists": "Reserva ya existe",
                "penalization_msg_start": "Tiene una penalización de ",
                "penalization_msg_end": "horas",
                "same_activity_msg_start": "No puede reservar",
                "same_activity_msg_end": "actividades consecutivas de la misma categoría",
                "max_per_day_msg_start": "No puede reservar más de",
                "max_per_day_msg_end": "actividades en el mismo día",
                "not_allowed_only_members": "Acción no permitida para su tipo de usuario.",
                "invalid_init_hour": "Hora de inicio inválida",
                "invalid_duration": "Duración inválida",
                "reservation_exceed_end": "Excede la hora de fin de la actividad",
                "reservation_less_current_end": "La hora de inicio debe ser mayor a la actual"
            },
            "responsible": {
                "user_name_required": "Nombre de usuario requerido",
                "user_lastname_required": "Apellido de usuario requerido",
                "user_extid_required": "N° de Socio requerido",
                "company_id_required": "El identificador de la compañía es requerido",
                "user_email_required": "Email requerido",
                "user_id_required": "El identificador del usuario es requerido",
                "invalid_user_id": "Identificador de usuario inválido"
            },
            "signin": {
                "user_id_required": "El identificador del usuario es requerido",
                "pwd_required": "Contraseña requerida",
                "invalid_user_id": "Usuario o contraseña inválidos",
                "invalid_user": "Usuario inválido"
            },
            "signup": {
                "user_email_required": "Identificador de usuario requerido",
                "company_id_required": "El identificador de la compañía es requerido",
                "invalid_user_email": "Identificador de usuario inválido",
                "user_name_required": "Nombre de usuario requerido",
                "user_lastname_required": "Apellido de usuario requerido",
                "user_extid_required": "N° de Socio requerido",
                "pwd_required": "Contraseña requerida",
                "user_type_required": "Tipo de usuario requerido",
                "invalid_user_type": "Tipo de usuario inválido",
                "user_already_registered": "El usuario ya se encuentra registrado",
                "invalid_user": "Identificador de usuario inválido",
                "reg_confirmation": "Confirmación de registro",
                "token_required": "Identificador de usuario requerido",
                "dibaled_user_msg": "Disabled user, remember to check your email to validate it. If you have already done and can't log in, contact administration."
            },
            "uploadimage": {
                "image_required": "La imagen es requerida",
                "image_name_required": "El nombre de imagen es requerido",
                "file_not_image": "El archivo no es una imagen",
                "invalid_type": "Tipo de imagen inválido",
                "error_save": "Error al guardar la imagen",
                "saved_ok": "Imagen guardada correctamente."
            },
            "usercategory": {
                "id_required": "El identificador es requerido",
                "invalid_id": "Identificador inválido",
                "name_required": "Nombre requerido",
                "age_from_required": "Edad desde requerida",
                "age_to_required": "Edad hasta requerida"
            },
            "user": {
                "user_name_required": "Nombre de usuario requerido",
                "user_lastname_required": "Apellido de usuario requerido",
                "user_extid_required": "N° de Socio requerido",
                "company_id_required": "El identificador de la compañía es requerido",
                "user_id_required": "El identificador del usuario es requerido",
                "invalid_user_id": "Identificador de usuario inválido",
                "invalid_email": "Email inválido"
            },
            "usertype": {
                "id_required": "Identificador de tipo de usuario requerido",
                "invalid_id": "Identificador inválido",
                "name_required": "Nombre de tipo de usuario requerido"
            },
            "whassistance": {
                "user_dont_exist": "Usuario inexistente",
                "user_inactive": "Usuario inactivo",
                "not_activities_today": "No tiene actividades para el día de hoy.",
                "assitant_error": "Hubo un problema con la reserva, no se pudo marcar la asistencia"
            },
            "whuser": {
                "user_name_required": "Nombre de usuario requerido. Recibido:",
                "user_lastname_required": "Apellido de usuario requerido. Recibido:",
                "user_extid_required": "N° de Socio requerido. Recibido:",
                "company_id_required": "El identificador de la compañía es requerido. Recibido:",
                "doc_required": "Documento requerido. Recibido:",
                "gender_required": "El género es requerido. Recibido"
            },
            "notification": {
                "id_required": "Identificador de usuario requerido",
                "subject_required": "Sujeto del mensaje requerido",
                "title_required": "Título del mensaje requerido",
                "text_required": "Texto del mensaje requerido",
                "invalid_user": "Usuario inválido",
                "invalid_main_user": "Usuario titular inválido",
                "invalid_action": "Acción inválida",
                "invalid_conf": "Configuración inválida",
                "email_sent": "Email enviado",
                "invalid_schedule": "Horario Actividad inválida",
                "invalid_activity": "Actividad inválida",
                "invalid_reservation": "Identificador de reserva inválido",
                "confirm_reservation": "Confirmación de reserva",
                "you_cancel": "Has cancelado",
                "we_cancel": "Hemos canceldo",
                "reason": "Motivo:",
                "reservation_cancel": "Cancelación de Reserva"
            }
        },
        "api":{
            "activitytime": {
                "invalid_conf": "Configuración inválida",
                "user_required": "Usuario requerido",
                "activity_required": "Actividad requerida",
                "invalid_user": "Usuario inválido",
                "invalid_activity": "Actividad inválida"
            },
            "email": {
                "id_required": "Identificador de usuario requerido",
                "subject_required": "Sujeto del mensaje requerido",
                "title_required": "Título del mensaje requerido",
                "text_required": "Texto del mensaje requerido",
                "invalid_user": "Usuario inválido",
                "invalid_main_user": "Usuario titular inválido",
                "invalid_action": "Acción inválida",
                "invalid_conf": "Configuración inválida",
                "email_sent": "Email enviado"
            },
            "message": {
                "wrong_user": "Usuario incorrecto",
                "msg_not_exist": "No existe el mensaje",
                "was_an_error": "Hubo un error:",
                "msg_saved": "Mensaje guardado",
                "invalid_id": "Identificador de mensaje inválido",
                "msg_read": "Mensaje leído",
                "msg_deleted": "Mensaje eliminado"
            },
            "notification": {
                "id_required": "Identificador de usuario requerido",
                "subject_required": "Sujeto del mensaje requerido",
                "title_required": "Título del mensaje requerido",
                "text_required": "Texto del mensaje requerido",
                "invalid_user": "Usuario inválido",
                "invalid_main_user": "Usuario titular inválido",
                "invalid_action": "Acción inválida",
                "invalid_conf": "Configuración inválida",
                "email_sent": "Email enviado",
                "invalid_schedule": "Horario Actividad inválida",
                "invalid_activity": "Actividad inválida",
                "invalid_reservation": "Identificador de reserva inválido"
            },
            "recovery": {
                "email_required": "Email de usuario requerido",
                "invalid_email": "Email de usuario inválido",
                "invalid_user_id": "Identificador de usuario inválido",
                "email_sent": "Email enviado",
                "code_required": "Codigo de recuperación requerido",
                "pwd_required": "Contraseña requerida",
                "invalid_code": "Código inválido",
                "expired_code": "Código expirado",
                "pwd_change_ok": "Contraseña actualizada correctamente",
                "not_access": "Usuario inhabilitado, comunicarse con administración"
            },
            "reservation": {
                "type_required": "Tipo de reserva requerido",
                "invalid_user_id": "Identificador de usuario inválido",
                "id_required": "Identificador de reserva requerido",
                "user_id_required": "El identificador del usuario es requerido",
                "activity_id_required": "Identificador de actividad requerido",
                "invalid_conf": "Configuración inválida",
                "user_deleted": "Usuario dado de baja",
                "reservation_not_allowed": "Estimado socio, usted no está habilitado para realizar reservas. Motivo:",
                "invalid_schedule": "Horario Actividad inválida",
                "invalid_reservation": "Reserva inválida",
                "generic_error": "Ocurrió un error, por favor cierre la página e intentelo nuevamente.",
                "invalid_activity": "Actividad inválida",
                "invalid_category": "Categoría inválida",
                "invalid_Location": "Espacio inválido",
                "no_more_places": "Ya no quedan cupos para esta actividad, intenta reservar otro horario u otra actividad...",
                "invalid_user_cat": "Categoría de usuario inválida",
                "dob_error": "Hay un problema con su fecha de nacimiento, por favor comuníquese con administración para solucionarlo",
                "out_of_age": "Esta actividad no es para su edad.",
                "reservation_same_time": "Ya tiene reservas cuyo horario coincide con otras actividades.",                
                "reservation_exists": "Reserva ya existe",
                "invalid_main_user_id": "Usuario titular inválido",
                "invalid_action": "Acción inválida",
                "invalid_user_category": "Su categoría de socio no le permite reservar para esta clase.",
                "invalid_guest_category": "La categoría del invitado no le permite reservar para esta clase.",
                "penalization_msg_start": "Tiene una penalización de ",
                "penalization_msg_end": "horas",
                "max_actives_reservation_msg_start": "No puede tener más de",
                "max_actives_reservation_msg_end": "reservas simultaneas.",
                "same_activity_msg_start": "No puede reservar",
                "same_activity_msg_end": "actividades consecutivas de la misma categoría",
                "max_per_day_msg_start": "No puede reservar más de",
                "max_per_day_msg_end": "actividades en el mismo día",
                "invalid_id": "Identificador inválido",
                "cant_cancel_msg": "Lo sentimos ya no puede cancelar esta reserva",
                "cancel_ok": "Reserva cancelada correctamente",
                "free_place_msg": "Aviso de lugar disponible",
                "free_place_body_a": "Se liberó un cupo para la clase",
                "free_place_body_b": "de",
                "free_place_body_c": "a",
                "free_place_body_d": "para el día",
                "not_allowed_only_members": "Acción no permitida para su tipo de usuario.",
                "already_reserved": "Esta actividad acaba de ser reservada por otro socio",
                "invalid_init_hour": "Hora de inicio inválida",
                "invalid_duration": "Duración inválida",
                "reservation_exceed_end": "Excede la hora de fin de la actividad",
                "reservation_less_current_end": "La hora de inicio debe ser mayor a la actual",
                "carnet_expired": "Tiene el C.Salud de salud vencido",
                "no_more_cuponera": "No tiene más actividades disponibles en la cuponera.",
                "cancelation_exists": "El horario seleccionado esta suspendido",
                "invalid_guests": "Los datos de uno o más invitados son incorrectos"
            },
            "signin": {
                "user_id_required": "El identificador del usuario es requerido",
                "pwd_required": "Contraseña requerida",
                "invalid_user_id": "Usuario o contraseña inválidos",
                "dibaled_user_msg": "Usuario inhabilitado, recuerde revisar su email para validarlo. Si ya lo hizo y no puede iniciar sesi&oacute;n comunicarse con administraci&oacute;n.",
                "invalid_conf": "Configuración inválida"
            },
            "signup": {
                "company_id_required": "El identificador de la compañía es requerido",
                "invalid_user_email": "Identificador de usuario inválido",
                "user_name_required": "Nombre de usuario requerido",
                "user_lastname_required": "Apellido de usuario requerido",
                "user_extid_required": "N° de Socio requerido",
                "pwd_required": "Contraseña requerida",
                "user_type_required": "Tipo de usuario requerido",
                "invalid_user_type": "Tipo de usuario inválido",
                "user_already_registered": "El usuario ya se encuentra registrado",
                "invalid_user": "Identificador de usuario inválido",
                "token_required": "Identificador de usuario requerido",
                "user_email_required": "Email de usuario requerido",
                "invalid_email": "Email inválido",
                "invalid_conf": "Configuración inválida",
                "deleted_user": "Usuario inhabilitado",
                "wrong_user_data": "Datos de socio incorrectos",
                "confirmation": "Confirmación de registro"
            },
            "user": {
                "user_name_required": "Nombre de usuario requerido",
                "user_lastname_required": "Apellido de usuario requerido",
                "user_extid_required": "N° de Socio requerido",
                "company_id_required": "El identificador de la compañía es requerido",
                "user_id_required": "El identificador del usuario es requerido",
                "invalid_user_id": "Identificador de usuario inválido",
                "invalid_email": "Email de usuario inválido",
                "invalid_action": "Acción inválida",
                "user_email_required": "Email de usuario requerido",
                "user_already_registered": "El usuario ya se encuentra registrado",
                "last_pwd_invalid": "Contraseña anterior ingresada inválida",
                "user_deleted": "Usuario eliminado",
                "guests_repeated": "Hay uno o más Nros. de socio/documentos que se repiten"
            },
            "waitinglist": {
                "user_id_required": "El identificador del usuario es requerido",
                "invalid_user_id": "Identificador de usuario inválido",
                "activity_id_required": "Identificador de actividad requerido",
                "invalid_schedule": "Horario Actividad inválida",
                "user_already_exist": "Socio ya registrado en esta lista de espera",
                "user_added": "Socio agregado a la lista de espera",
                "invalid_reservation_id": "Identificador de reserva inválido",
                "reservation_id_required": "Identificador de reserva requerido",
                "invalid_action": "Acción inválida",
                "invalid_main_user_id": "Usuario titular inválido",
                "user_removed_from_list": "Socio removido de la lista de espera"
            }
        }
      }
  };

angular.module('ufcw')

.constant('EnvConfig', {
  "URL_API"			: "https://api-agenda.clubtabare.com.uy/",
  "URL_IMAGES"  : "https://squalus-public-production.s3.us-east-2.amazonaws.com/agenda/tabare/",
  "URL_FILES"  : "https://squalus-public-production.s3.us-east-2.amazonaws.com/agenda/tabare/",
  "COMPANY_ID"  : "1",
  "SIGNIN_ENABLED":true,
  "RESERVATION_ENABLED":true,
  "footer_app_name":"Club Tabaré",
  "footer_email":"info@clubtabare.com.uy",
  "footer_facebook":"https://www.facebook.com/clubatleticotabare",
  "footer_twitter":"https://twitter.com/clubtabare",
  "footer_instagram":"https://www.instagram.com/clubtabare/",
  "footer_youtube":"",
  "show_aviso_covid":false,
  "show_reglas":false,
  "signup_nrosocio":false,
  "signup_dob":false,
  "projectname":"tabare",
  "show_payment_expiration": false,
  "show_carnet_expiration": true
});
'use strict';

angular.module('ufcw')

.controller('main', ['$scope','$rootScope','$state','$stateParams','$log','$localstorage','EnvConfig','$translate','Request','toaster', function($scope,$rootScope,$state,$stateParams,$log,$localstorage,EnvConfig,$translate,Request,toaster) {
  $rootScope.footer_app_name    = EnvConfig.footer_app_name;
  $rootScope.footer_email       = EnvConfig.footer_email;
  $rootScope.footer_facebook    = EnvConfig.footer_facebook;
  $rootScope.footer_twitter     = EnvConfig.footer_twitter;
  $rootScope.footer_instagram   = EnvConfig.footer_instagram;
  $rootScope.footer_youtube     = EnvConfig.footer_youtube;
  $rootScope.show_aviso_covid   = EnvConfig.show_aviso_covid;
  $rootScope.show_cuponeras     = EnvConfig.show_cuponeras;
  $rootScope.show_reglas        = EnvConfig.show_reglas;
  $rootScope.signup_nrosocio    = EnvConfig.signup_nrosocio;
  $rootScope.signup_dob         = EnvConfig.signup_dob;
  $rootScope.projectname        = EnvConfig.projectname;
  $rootScope.user               = {};
  $rootScope.user.profile_img   = "images/Icono_persona.png";
  $rootScope.reservasButton     = "/home";
    
  Request.get('appconfiguration/?id='+EnvConfig.COMPANY_ID,false,function(response) {
    if(response.status !== 0){
      alert('Error loading configuration');
    }else{
      var appconfig = response.description.configuration;
      var config = response.description.variables[0];
      if(EnvConfig.useDefaultStyle){
        $rootScope.color1=appconfig.color1;
        $rootScope.color2=appconfig.color2;
        $rootScope.color3=appconfig.color3;
        $rootScope.color4=appconfig.color4;
        $localstorage.set('useRoutines',appconfig.useRoutines);
        $rootScope.useRoutines=appconfig.useRoutines;
      }
      $localstorage.set('maxDaysViewFields',config.maxDaysViewFields);
    }
  });
  
  
  $rootScope.isNumeric = function (n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
  };
  
	$rootScope.$on('$stateChangeSuccess', function(evt, toState, toParams, fromState, fromParams) {

    if(!$rootScope.inArray(toState.name,['signin','start','recovery','setpwd','signup','welcome/:token','horarios','horariosza','fronthorarios/:id','routine','homecanchas','cancha/:id','login/:id/:token'])){
      
      var token = $localstorage.get('token');
      var user  = $localstorage.getObject('user');
      var messages  = $localstorage.getObject('messages');
      var user_files = $localstorage.getObject('user_files');
      $rootScope.validate_terms = $localstorage.getObject('validate_terms');
      
      $rootScope.showLogout = $localstorage.get('showLogout');

      var user_profile = [];

      if(!token || !user){
        $rootScope.user = {};
        $localstorage.clear();
        $state.go('signin');
      }

      if(user){
        user.nameToShow = user.firstName.split(" ");
        user.nameToShow = user.nameToShow[0];
        $translate.use(user.lang);
        
        var today = new Date();
        var dob = new Date(user.dob+" 12:00:00");
        var today_month = today.getMonth()+1;
        var today_day = today.getDate();
        var dob_month = dob.getMonth()+1;
        var dob_day = dob.getDate();
        
        if(today_day === dob_day && today_month === dob_month){
          user.show_globes = true;
        }else{
          user.show_globes = false;
        }

        if(user_files) {
          user_profile = user_files.filter(function (item) {
            if (item.fileType === "PERFIL") {
              return item;
            }
          });
          if (user_profile.length > 0) {
            user.profile_img = EnvConfig.URL_FILES + user_profile[0].companyId + '/' + user_profile[0].userId + '/' + user_profile[0].fileName;
          }else{
            user.profile_img = "images/Icono_persona.png";
          }
        }

        $rootScope.reservasButton = ($rootScope.user.userAccess==='CANCHA')?"/homecanchas":"/home";

        $rootScope.user = user;
        $rootScope.messages = messages;
      }
    }else{
      var user  = $localstorage.getObject('user');
      if(user){
        user.nameToShow  = user.firstName.split(" ");
        user.nameToShow  = user.nameToShow[0];
        user.profile_img = "images/Icono_persona.png";
        $rootScope.user  = user;
      }
    }
    
  });

  $rootScope.logout = function(){
    $localstorage.clear();
    $rootScope.user = {};
    $state.go('signin');
  };

  $rootScope.inArray = function(value, haystack){
    var length = haystack.length;
    for(var i = 0; i < length; i++) {
      if(haystack[i] == value)
      return true;
    }
    return false;
  };

  $rootScope.validateCI = function (pNumero) {
		try {
			if(pNumero){
				pNumero = ""+pNumero;
				if (pNumero.length == 8 || pNumero.length == 7) {
					var _formula = [2, 9, 8, 7, 6, 3, 4];
					var _suma = 0;
					var _guion = 0;
					var _aux = 0;
					var _numero = [0, 0, 0, 0, 0, 0, 0, 0];

					if (pNumero.length == 8) {
						_numero[0] = pNumero[0];
						_numero[1] = pNumero[1];
						_numero[2] = pNumero[2];
						_numero[3] = pNumero[3];
						_numero[4] = pNumero[4];
						_numero[5] = pNumero[5];
						_numero[6] = pNumero[6];
						_numero[7] = pNumero[7];
					}
					//Para cédulas menores a un millón.
					if (pNumero.length == 7) {
						_numero[0] = 0;
						_numero[1] = pNumero[0];
						_numero[2] = pNumero[1];
						_numero[3] = pNumero[2];
						_numero[4] = pNumero[3];
						_numero[5] = pNumero[4];
						_numero[6] = pNumero[5];
						_numero[7] = pNumero[6];
					}

					_suma = (_numero[0] * _formula[0]) + (_numero[1] * _formula[1]) + (_numero[2] * _formula[2]) + (_numero[3] * _formula[3]) + (_numero[4] * _formula[4]) + (_numero[5] * _formula[5]) + (_numero[6] * _formula[6]);

					for (var i = 0; i < 10; i++) {
						_aux = _suma + i;
						if (_aux % 10 == 0) {
							_guion = _aux - _suma;
							i = 10;
						}
					}

					if (_numero[7] == _guion) {
						return true;
					}
					else {
						return false;
					}
				}

				else {
					throw ('La Cédula debe tener 7 u 8 caracteres.');
				}
			}
		}
		catch (ex) { 
      toaster.pop('info', "", ex);
		}
	};

}]);

'use strict';

angular.module('ufcw')
  .controller('HistorialreservasController', ["$log", "Request", "$localstorage", "$state", "$rootScope", "toaster", "$uibModal", "$document", "$translate", function ($log,Request,$localstorage,$state,$rootScope,toaster,$uibModal,$document,$translate) {
    var vm = this;
    vm.errorMsg = null;
    var user  = $localstorage.getObject('user');

    vm.users = $localstorage.getObject('dependents');
    vm.has_dependent = (vm.users.length === 0)?false:true;
    vm.users.push(user);

    vm.from_date = new Date();
    vm.from_date.setDate(vm.from_date.getDate() - 7);
    vm.to_date   = new Date();

    var config  = $localstorage.getObject('config');
    vm.useBuilding = config.useBuilding;

    $rootScope.user = user;
    $rootScope.headLander = true;
    $rootScope.titulopagina = $translate.instant('reserve.history');

    var STATUS_MAP = {
      RESERVED: $translate.instant('reserve.status_reserved'),
      CANCHARESERVED: $translate.instant('reserve.status_reserved'),
      CUPONERARESERVED: $translate.instant('reserve.status_reserved'),
      CANCELED: $translate.instant('reserve.status_canceled'),
      CANCHACANCELED: $translate.instant('reserve.status_canceled'),
      CUPONERACANCELED: $translate.instant('reserve.status_canceled'),
      null: '',
      '': '',
      undefined: '',
    };

    var ATTENDANCE_MAP = {
      1: $translate.instant('general.yes'),
      0: $translate.instant('general.no'),
      null: '-',
      '': '',
      undefined: '',
    };

    var days = [$translate.instant('days.sunday'), $translate.instant('days.monday'), $translate.instant('days.tuesday'), $translate.instant('days.wednesday'), $translate.instant('days.thursday'), $translate.instant('days.friday'), $translate.instant('days.saturday')];
    var months = [$translate.instant('months.january'), $translate.instant('months.february'), $translate.instant('months.march'), $translate.instant('months.april'), $translate.instant('months.may'), $translate.instant('months.june'), $translate.instant('months.july'),$translate.instant('months.august'),$translate.instant('months.september'),$translate.instant('months.october'),$translate.instant('months.november'),$translate.instant('months.december')];

    vm.load = function(){
      var params = "?from=2022-07-01&to=2023-01-01";
      var from = "";
      var to   = "";
      if(vm.from_date && vm.to_date){
        if(vm.from_date > vm.to_date){
          toaster.pop('info', "", $translate.instant('La fecha DESDE no puede ser mayor a la fecha HASTA'));
          return;
        }
        from = vm.from_date.getFullYear() + "-" + (vm.from_date.getMonth()+1) + "-" + vm.from_date.getDate();
        to = vm.to_date.getFullYear() + "-" + (vm.to_date.getMonth()+1) + "-" + vm.to_date.getDate();
        params = "?from="+from+"&to="+to;
      }

      Request.get('reservation/'+params,true,function(response) {
        if(response.status !== 0){
          toaster.pop('info', "", $translate.instant('api.'+response.description));
        }else{
          vm.reservas = response.description;
          vm.reservas.map(function(item){
            item.status = STATUS_MAP[item.status];
            item.attendance = ATTENDANCE_MAP[item.attendance];
            item.reservationdate = item.reservationdate.replace(/-/g, '/');
            var d = new Date(item.reservationdate);
            
            var dayName = days[d.getDay()];
            var dayNumber = d.getDate();
            var monthName = months[d.getMonth()];
            var year = d.getFullYear();
            item.day = dayName +" "+ dayNumber+ " " + $translate.instant("general.of") + " "+ monthName +" "+year;

            var hours = d.getHours().toString(); 
            var minutes = d.getMinutes().toString(); 
            hours = (hours.length===1)?"0"+hours:hours;
            minutes = (minutes.length===1)?minutes+"0":minutes;
            item.start_custom = hours + ":" + minutes;
          });
          vm.no_reservations = (vm.reservas.length === 0)?true:false;
        }
      });
    };

    vm.load();

  //DATETIMEPICKERS

  function getDayClass(data) {
    var date = data.date,
      mode = data.mode;
    if (mode === 'day') {
      var dayToCheck = new Date(date).setHours(0,0,0,0);

      for (var i = 0; i < vm.events.length; i++) {
        var currentDay = new Date(vm.events[i].date).setHours(0,0,0,0);

        if (dayToCheck === currentDay) {
          return vm.events[i].status;
        }
      }
    }

    return '';
  }

  vm.today = function() {
    vm.dt = new Date();
  };
  vm.today();

  vm.clear = function() {
    vm.dt = null;
  };

  vm.inlineOptions = {
    customClass: getDayClass,
    minDate: new Date(),
    showWeeks: true
  };

  // Disable weekend selection
  function disabled(data) {
    var date = data.date,
      mode = data.mode;
    return mode === 'day' && (date.getDay() === 0 || date.getDay() === 6);
  }

  vm.dateOptions = {
    dateDisabled: disabled,
    formatYear: 'yy',
    maxDate: new Date(2020, 5, 22),
    minDate: new Date(),
    startingDay: 1
  };

  vm.toggleMin = function() {
    vm.inlineOptions.minDate = vm.inlineOptions.minDate ? null : new Date();
    vm.dateOptions.minDate = vm.inlineOptions.minDate;
  };

  vm.toggleMin();

  vm.open1 = function() {
    vm.popup1.opened = true;
  };

  vm.open2 = function() {
    vm.popup2.opened = true;
  };

  vm.setDate = function(year, month, day) {
    vm.dt = new Date(year, month, day);
  };

  vm.formats = ['dd-MMMM-yyyy', 'yyyy/MM/dd', 'dd.MM.yyyy', 'shortDate'];
  vm.format = vm.formats[0];
  vm.altInputFormats = ['M!/d!/yyyy'];

  vm.popup1 = {
    opened: false
  };

  vm.popup2 = {
    opened: false
  };

  var tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  var afterTomorrow = new Date();
  afterTomorrow.setDate(tomorrow.getDate() + 1);
  vm.events = [
    {
      date: tomorrow,
      status: 'full'
    },
    {
      date: afterTomorrow,
      status: 'partially'
    }
  ];

  vm.openGuests = function (size, parentSelector) {
    var parentElem = parentSelector ? 
      angular.element($document[0].querySelector('.modal-demo ' + parentSelector)) : undefined;
    var modalInstance = $uibModal.open({
      animation: vm.animationsEnabled,
      ariaLabelledBy: 'modal-title',
      ariaDescribedBy: 'modal-body',
      templateUrl: 'guests.html',
      controller: 'ModalEditGuestsController',
      controllerAs: 'modalCtrl',
      size: size,
      backdrop: 'static',
      keyboard: false,
      appendTo: parentElem,
      resolve: {
        activity: function () {
          return vm.activity;
        }
      }
    });

    modalInstance.result.then(function () {
    }, function () {
      // $log.info('Modal dismissed at: ' + new Date());
    });
  };

  vm.showGuests = function(activity){
    if(activity.allowGuest ===1 && angular.isString(activity.description)){
      activity.guests = activity.description.split(',');
      delete activity.description;
    }
    vm.activity = activity;
    vm.openGuests();
  };

}]);

'use strict';

angular.module('ufcw')
  .controller('MisreservasController', ["$log", "Request", "$localstorage", "$state", "$rootScope", "toaster", "$uibModal", "$document", "EnvConfig", "$translate", function ($log,Request,$localstorage,$state,$rootScope,toaster,$uibModal,$document,EnvConfig,$translate) {
    var vm = this;
    vm.errorMsg = null;
    var user  = $localstorage.getObject('user');

    vm.users = $localstorage.getObject('dependents');
    vm.has_dependent = (vm.users.length === 0)?false:true;
    vm.users.push(user);

    var config  = $localstorage.getObject('config');
    vm.useBuilding = config.useBuilding;

    $rootScope.user = user;
    $rootScope.headLander = true;
    $rootScope.titulopagina = $translate.instant("reserve.my_reserves");

    var days = [$translate.instant('days.sunday'), $translate.instant('days.monday'), $translate.instant('days.tuesday'), $translate.instant('days.wednesday'), $translate.instant('days.thursday'), $translate.instant('days.friday'), $translate.instant('days.saturday')];
    var months = [$translate.instant('months.january'), $translate.instant('months.february'), $translate.instant('months.march'), $translate.instant('months.april'), $translate.instant('months.may'), $translate.instant('months.june'), $translate.instant('months.july'),$translate.instant('months.august'),$translate.instant('months.september'),$translate.instant('months.october'),$translate.instant('months.november'),$translate.instant('months.december')];

    vm.load = function(){
      //get next reservations for user
      
      Request.get('reservation/',true,function(response) {
        if(response.status !== 0){
          toaster.pop('info', "", $translate.instant('api.'+response.description));
        }else{
          vm.reservas = response.description;
          vm.reservas.map(function(item){
            item.reservationdate = item.reservationdate.replace(/-/g, '/');
            
            var d = new Date(item.reservationdate);
            var dayName = days[d.getDay()];
            var dayNumber = d.getDate();
            var monthName = months[d.getMonth()];
            item.day = dayName +" "+ dayNumber+ " " + $translate.instant('general.of') + " "+ monthName;
            var hours = d.getHours().toString(); 
            var minutes = d.getMinutes().toString(); 
            hours = (hours.length===1)?"0"+hours:hours;
            minutes = (minutes.length===1)?minutes+"0":minutes;
            item.start_custom = hours + ":" + minutes;
          });
          vm.no_reservations = (vm.reservas.length === 0)?true:false;
        }
      });
    };

    vm.load();

    vm.sendNotification = function(id,action){
      var data = {"type":"email","action":action,"reservationId":id};
      Request.post('notification/',true,data,function() {
      });
    };

    vm.cancelReservation = function (id) {
      Request.delete('reservation/?id='+id,true,function(response) {
        if(response.status !== 0){
          toaster.pop('error', "", $translate.instant('api.'+response.description));
        }else{
          toaster.pop('success', "", $translate.instant("reserve.canceled_ok"));
          vm.load();
          vm.sendNotification(id,'cancel');
        }
      });
    };

    // MODAL THINGS
    vm.animationsEnabled = true;
    
    vm.open = function (size, parentSelector) {
      
      var parentElem = parentSelector ? 
        angular.element($document[0].querySelector('.modal-demo ' + parentSelector)) : undefined;
      var modalInstance = $uibModal.open({
        animation: vm.animationsEnabled,
        ariaLabelledBy: 'modal-title',
        ariaDescribedBy: 'modal-body',
        templateUrl: 'confirmareserva.html',
        controller: 'ModalReservaController',
        controllerAs: 'modalCtrl',
        size: size,
        backdrop: 'static',
        keyboard: false,
        appendTo: parentElem,
        resolve: {
          title: function () {
            return vm.title;
          },
          day: function () {
            return vm.activity.day;
          },
          dependents: function () {
            return vm.dependents;
          },
          action: function () {
            return vm.action;
          },
          activity: function () {
            return vm.activity;
          },
          showInvitation: function () {
            return vm.showInvitation;
          }
        }
      });
  
      modalInstance.result.then(function (result) {
          if(result.status === 'OK'){
            vm.activity = result.activity;
            vm.cancelReservation(vm.activity.id);
          }
      }, function () {
        // $log.info('Modal dismissed at: ' + new Date());
      });
    };



    vm.cancelRes = function(activity){
      vm.activity = activity;
      vm.at = vm.activity.id;
      vm.id = $rootScope.user.id;
      vm.moredays = (vm.showNextDay)?0:1;
      vm.title = $translate.instant("reserve.cancel");
      vm.showInvitation = false;
      vm.action = "CANCEL";
      vm.open();
    };

    vm.openGuests = function (size, parentSelector) {
      var parentElem = parentSelector ? 
        angular.element($document[0].querySelector('.modal-demo ' + parentSelector)) : undefined;
      var modalInstance = $uibModal.open({
        animation: vm.animationsEnabled,
        ariaLabelledBy: 'modal-title',
        ariaDescribedBy: 'modal-body',
        templateUrl: 'guests.html',
        controller: 'ModalEditGuestsController',
        controllerAs: 'modalCtrl',
        size: size,
        backdrop: 'static',
        keyboard: false,
        appendTo: parentElem,
        resolve: {
          activity: function () {
            return vm.activity;
          }
        }
      });
  
      modalInstance.result.then(function () {
      }, function () {
        // $log.info('Modal dismissed at: ' + new Date());
      });
    };

    vm.showGuests = function(activity){
      if(activity.allowGuest === 1 && angular.isString(activity.description)){
        activity.guests = activity.description.split(',');
        delete activity.description;
      }
      vm.activity = activity;
      vm.activity.editGuests = true;
      vm.openGuests();
    };

  }]);

  angular.module('ufcw').controller('ModalReservaController', ["$uibModalInstance", "$log", "title", "day", "dependents", "action", "activity", "showInvitation", "toaster", "$translate", function ($uibModalInstance,$log,title,day,dependents,action,activity,showInvitation,toaster,$translate) {
    var vm = this;
    
    vm.title = title;
    vm.activity = activity;
    vm.day = day;
    vm.dependents = dependents;
    vm.action = action;
    vm.showInvitation = showInvitation;

    var d = new Date(vm.activity.reservationdate);
    var hours = d.getHours().toString(); 
    var minutes = d.getMinutes().toString(); 
    hours = (hours.length===1)?"0"+hours:hours;
    minutes = (minutes.length===1)?minutes+"0":minutes;
    vm.activity.start_custom = hours + ":" + minutes;
    
    vm.ok = function () {
      try {
        if(vm.action === 'DEPENDENT' && !vm.selDependent){
          throw $translate.instant("reserve.must_select_dependent");
        }
        if(vm.inv1){
          vm.activity.description += ""+vm.inv1;
        }
        if(vm.inv2){
          vm.activity.description += ", "+vm.inv2;
        }
        if(vm.inv3){
          vm.activity.description += ", "+vm.inv3;
        }
        $uibModalInstance.close({dependent: vm.selDependent, activity:vm.activity, status: 'OK'});
      } catch (error) {
        toaster.pop('error', "", error);
      }
    };
  
    vm.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  }]);

  angular.module('ufcw').component('modalComponent', {
    templateUrl: 'confirmareserva.html',
    bindings: {
      resolve: '<',
      close: '&',
      dismiss: '&'
    },
    controller: function () {
      var vm = this;
  
      vm.$onInit = function () {
        vm.title = vm.resolve.title;
        vm.activity = vm.resolve.activity;
        vm.day = vm.resolve.day;
        vm.dependents = vm.resolve.dependents;
        vm.action = vm.resolve.action;
        vm.showInvitation = vm.resolve.showInvitation;
      };
  
      vm.ok = function () {
        vm.close({$value: 'OK'});
      };
  
      vm.cancel = function () {
        vm.dismiss({$value: 'cancel'});
      };
    }
  });

'use strict';

angular.module('ufcw')
  .controller('AdvancedsearchController', ["$log", "Request", "$localstorage", "$rootScope", "$uibModal", "$document", "toaster", "$translate", function ($log,Request,$localstorage,$rootScope,$uibModal,$document,toaster,$translate) {
    var vm = this;
    
    vm.edad = {
      value: 25,
      options: {
          floor: 1,
          ceil: 100
      }
    };

  vm.hora = {
    value: 0,
    maxValue: 24,
    options: {
        floor: 0,
        ceil: 24,
    }
  };

  var config  = $localstorage.getObject('config');

    vm.viewAgeFilter = true;
    if(config.viewSearchByAge!==1){
      vm.viewAgeFilter = false;
      vm.edad.value = 120;
    }
//estos son los que tienen el valor, cuando hacen click en buscar lo tenes
//vm.edad.value
//vm.hora.value

    $rootScope.headLander = true;
    $rootScope.titulopagina = $translate.instant("advsearch.title");
    // vm.advSearch = {};
    vm.listdays = {};
    vm.selActivity = 0;
    vm.emptylist = 0;
    // var msg = "El socio "+vm.user.firstName+" "+vm.user.lastName+", Nro. "+vm.user.externalId+" o un integrante de su grupo familiar";
    //     msg+= " da aviso de que posee COVID-19 positivo. <br><br>";
    //     msg+= "Sus datos de contacto son: <br><br>";
    //     msg+= "Tel&eacute;fono: "+vm.user.phone+". <br>";
    //     msg+= "C&eacute;dula: "+vm.user.identificationNumber+". <br><br>";
    //     msg+= "Grupo familiar: <br>";

    // vm.dependents.map(function(item){
    //   msg+= "      "+item.firstName+" "+item.lastName+", Nro. "+item.externalId+". <br>";
    // });


    // vm.send = function(){
    //   var data = {subject:"Club Malvín - Aviso de COVID-19 positivo",title:"Aviso de COVID-19 Positivo",user:vm.user.id,msg:msg};
    //   Request.post('email/',true,data,function(response) {
    //     if(response.status !== 0){
    //       toaster.pop('error', "", response.description);
    //     }else{
          // toaster.pop('test');
    //     }
    //   });
    // };

    // MODAL
    vm.animationsEnabled = true;

    Request.get('activitycategory',true,function(response) {
      if(response.status !== 0){
        toaster.pop('info', "", $translate.instant('api.'+response.description));
      }else{
        vm.listactivities = response.description;
       
        
        // vm.activities.map(function(item){
        //   item.icon = (item.icon)?EnvConfig.URL_IMAGES+item.icon:EnvConfig.URL_IMAGES+'icono_calendario.png';
        // });
      }
    });

    vm.search = function(){
      var axdays = [];
      var axhours = {};
      var axactivity;
      var axage;


    //   console.log('edad',vm.edad.value);
    // console.log('hora',vm.hora);

      // var days =[];
      
      axdays= vm.getDaysSelected();

      if (vm.hora.value.toString().length===1){
        axhours.start = "0"+vm.hora.value+":00";
      }else{
        axhours.start = vm.hora.value+":00";
      }

      if (vm.hora.maxValue.toString().length===1){
        axhours.end = "0"+vm.hora.maxValue+":00";
      }else{
        axhours.end = vm.hora.maxValue+":00";
      }

      if (vm.selActivity){
        axactivity = vm.selActivity;
      }else{
        axactivity= 0;
      }

      if (vm.edad.value>0){
        axage = vm.edad.value;
      }else{
        axage= 0;
      }

      

      var data = {days:axdays,hours:axhours,activity:axactivity,age:axage};
      Request.post('advancedsearch/',true,data,function(response) {
        if(response.status !== 0){
          toaster.pop('error', "", $translate.instant('api.'+response.description));
        }else{
          vm.listactivitytimes = response.description.list;
          if (vm.listactivitytimes.length===0){
            vm.emptylist = 1;
          }else{
            vm.emptylist = 0;
          }
          
        }
      });
    };


    vm.getnameday=function(codday){
      var nameday = "";
      switch (codday) {
        case 0:
          nameday = $translate.instant('days.sunday');
          break;
        case 1:
          nameday = $translate.instant('days.monday');
          break;
        case 2:
          nameday = $translate.instant('days.tuesday');
          break;
        case 3:
          nameday = $translate.instant('days.wednesday');
          break;
        case 4:
          nameday = $translate.instant('days.thursday');
          break;
        case 5:
          nameday = $translate.instant('days.friday');
          break;
        case 6:
          nameday = $translate.instant('days.saturday');
          break;
    
        default:
          break;
      }
      return nameday;
    };
  
    vm.loadDaysOfWeek = function(){
      var mList = [
        {
          id: 0,
          value: $translate.instant('days.sunday'),
        },
        {
          id: 1,
          value: $translate.instant('days.monday'),
        },
        {
          id: 2,
          value: $translate.instant('days.tuesday'),
        },{
          id: 3,
          value: $translate.instant('days.wednesday'),
        },
        {
          id: 4,
          value: $translate.instant('days.thursday'),
        },
        {
          id: 5,
          value: $translate.instant('days.friday'),
        },
        {
          id: 6,
          value: $translate.instant('days.saturday'),
        }
      ];
      return mList;
  
    };

    vm.listdays = vm.loadDaysOfWeek();

    vm.getDaysSelected=function(){
      var ressultArr = [];
      if (vm.listdays.monday){
        ressultArr.push(1);
      }
      if (vm.listdays.tuesday){
        ressultArr.push(2);
      }
      if (vm.listdays.wednesday){
        ressultArr.push(3);
      }
      if (vm.listdays.thursday){
        ressultArr.push(4);
      }
      if (vm.listdays.friday){
        ressultArr.push(5);
      }
      if (vm.listdays.saturday){
        ressultArr.push(6);
      }
      if (vm.listdays.sunday){
        ressultArr.push(0);
      }
  
      return ressultArr;
    };
    
   
  }]);

 
'use strict';

angular.module('ufcw')
  .controller('CanchaController', ["$rootScope", "$log", "$location", "Request", "$localstorage", "$uibModal", "$document", "$state", "toaster", "$translate", "$window", "EnvConfig", function ($rootScope,$log,$location,Request,$localstorage,$uibModal,$document,$state,toaster,$translate,$window,EnvConfig) {
    
    var vm = this;
    vm.reservation_enabled = true;

    var user  = $localstorage.getObject('user');

    //redirect signin if show cachas config is disabled
    vm.showCanchas = EnvConfig.show_canchas;
    if(!vm.showCanchas){
      $state.go('signin');
    }
    
    vm.dependents = $localstorage.getObject('dependents');
    vm.has_dependent = (vm.dependents.length === 0)?false:true;
    var config  = $localstorage.getObject('config');
    if(config.reservationEnabled===0){
      vm.reservation_enabled = false;
      vm.notice_title = config.noticeAlertTitle;
      vm.notice_text = config.noticeAlertText;
    }else{
      vm.reservation_enabled = true;
    }

    vm.backPath = "/home";
    var token  = $localstorage.get('token');
    if(!token){
      vm.backPath = "/homecanchas";
    }

    vm.showNextDay = true;

    if(user){
      user.profile_img = "images/Icono_persona.png";
      user.nameToShow = user.firstName.split(" ");
      user.nameToShow = user.nameToShow[0];
      $rootScope.user = user;
      $rootScope.headLander = true;
    }    
    
    $rootScope.titulopagina = $translate.instant("Reserva de Canchas");
   
    var days = [$translate.instant('days.sunday'), $translate.instant('days.monday'), $translate.instant('days.tuesday'), $translate.instant('days.wednesday'), $translate.instant('days.thursday'), $translate.instant('days.friday'), $translate.instant('days.saturday')];
    var months = [$translate.instant('months.january'), $translate.instant('months.february'), $translate.instant('months.march'), $translate.instant('months.april'), $translate.instant('months.may'), $translate.instant('months.june'), $translate.instant('months.july'),$translate.instant('months.august'),$translate.instant('months.september'),$translate.instant('months.october'),$translate.instant('months.november'),$translate.instant('months.december')];
    var d = new Date();
    
    function unique(value, index, self){
      return self.indexOf(value) === index;
    }

    vm.sendNotification = function(id,action){
      var data = {"type":"email","action":action,"reservationId":id};
      Request.post('notification/',true,data,function() { });

    };
    
    vm.daycount = 0;

    vm.changeDate = function(option){
      if(option==='ADD'){
        vm.daycount+=1;
        d.setDate(d.getDate() + 1);
      }else{
        vm.daycount-=1;
        d.setDate(d.getDate() - 1);
      }
      if(vm.daycount>0){
        vm.showNextDay = false;
      }else if(vm.daycount===0){
        vm.showNextDay = true;
      }

      var today = new Date();
      var canchaLimit = new Date();
      var maxDaysViewFields  = $localstorage.get('maxDaysViewFields');

      if(maxDaysViewFields > 0){
        
        canchaLimit.setDate(today.getDate() + parseInt(maxDaysViewFields));
        if(d<=canchaLimit){
          vm.showNextDay = true;
        }else{
          vm.showNextDay = false;
        }
      }
      
    };

    vm.load = function(){
      var id = $location.search().id; 

      var dayName = days[d.getDay()];
      var dayNumber = d.getDate();

      var monthName = months[d.getMonth()];
      
      vm.day=dayName +" "+ dayNumber+ " " + $translate.instant("general.of") + " "+ monthName;

      var params = "?id="+id+"&days="+vm.daycount+"&userId="+$rootScope.user.id;

      //cambiar a cuponeraactividad
      Request.get('canchaactivity/'+params,true,function(response) {
        if(response.status !== 0){
          vm.errorMsg = $translate.instant(response.description);
        }else{
          // $localstorage.setObject('config',response.config);
          // var config  = $localstorage.getObject('config');
          // if(config.reservationEnabled===0){
          //   vm.reservation_enabled = false;
          //   vm.notice_title = config.noticeAlertTitle;
          //   vm.notice_text = config.noticeAlertText;
          // }

          vm.activities = response.description;
          
          var categories = vm.activities.map(function(item) {
            return item.CategoryName;
          });
          var filter_act = vm.activities.map(function(item) {
            return item.name;
          });
          
          vm.maxActiveResFlag = response.maxActiveReservation;
          if (vm.maxActiveResFlag>0){
            vm.dayStartRes = response.maxActiveReservationStartDay;
          }else{
            vm.dayStartRes = 0;
          }
          vm.categories = categories.filter(unique);
          vm.categories.sort();
          vm.filter_act = filter_act.filter(unique);
          vm.filter_act.sort();
          vm.no_activities = (vm.activities.length === 0)?true:false;
          
          vm.show_selectCat = (vm.categories.length>1)?true:false;
          vm.show_selectAct = (vm.filter_act.length>1)?true:false;
          
        }
      });
    };

    vm.reservation = function (id,at,day,desc) {
      var data = {"usr":id,"at":at,"day":day,"description":desc};

      Request.post('canchareservation/',true,data,function(response) {
        if(response.status !== 0){
          var res = response.description.substring(0, 4);
          var prefix = (res==="api.")?res:"";
          toaster.pop('error', "", $translate.instant(prefix+response.description));
        }else{
          toaster.pop('success', "", $translate.instant("reserve.success"));
          $window.open(EnvConfig.URL_PAGOSCANCHA+"product/?type=CANCHA&id="+response.description.id,"_self");
        }
      });
    };

    vm.cancelReservation = function (id) {
      Request.delete('canchareservation/?id='+id+'&cId='+vm.infocuponera.id,true,function(response) {
        if(response.status !== 0){
          var res = response.description.substring(0, 4);
          var prefix = (res==="api.")?res:"";
          toaster.pop('error', "", $translate.instant(prefix+response.description));
          // toaster.pop('error', "", $translate.instant('api.'+response.description));
        }else{
          toaster.pop('success', "", $translate.instant("reserve.canceled_ok"));
          vm.load();
          vm.sendNotification(id,'cancel');
        }
      });
    };

    vm.load();

    // MODAL THINGS
    vm.animationsEnabled = true;
    
    vm.open = function (size, parentSelector) {
      var parentElem = parentSelector ? 
        angular.element($document[0].querySelector('.modal-demo ' + parentSelector)) : undefined;
      var modalInstance = $uibModal.open({
        animation: vm.animationsEnabled,
        ariaLabelledBy: 'modal-title',
        ariaDescribedBy: 'modal-body',
        templateUrl: 'confirmareservacancha.html',
        controller: 'ModalCanchaReservaController',
        controllerAs: 'modalCtrl',
        size: size,
        backdrop: 'static',
        keyboard: false,
        appendTo: parentElem,
        resolve: {
          title: function () {
            return vm.title;
          },
          day: function () {
            return vm.day;
          },
          dependents: function () {
            return vm.dependents;
          },
          action: function () {
            return vm.action;
          },
          activity: function () {
            return vm.activity;
          }
        }
      });
  
      modalInstance.result.then(function (result) {
          if(result.status === 'OK'){
            vm.activity = result.activity;
            switch (vm.action) {
              case 'PARENT':
                vm.reservation(vm.id,vm.at,vm.moredays,vm.activity.guests);
              break;
              case 'DEPENDENT':
                vm.reservation(result.dependent,vm.at,vm.moredays,vm.activity.guests);
              break;
              case 'CANCEL':
                vm.cancelReservation(vm.activity.reservationId);
              break;
            }
          }
      }, function () {
        // $log.info('Modal dismissed at: ' + new Date());
      });
    };

    vm.doReservation = function(action,activity){
      vm.activity = activity;
      vm.activity.guests = "";
      vm.action = action;
      vm.at = vm.activity.id;
      
      if(!$rootScope.user.id){
        vm.openSignup();
      }else{
        vm.id = $rootScope.user.id;
        vm.moredays = vm.daycount;
        if(action === 'PARENT' || action === 'DEPENDENT'){
          vm.title = $translate.instant("reserve.modal_title_confirm");
        }else if(action === 'CANCEL'){
          vm.title = $translate.instant("reserve.modal_title_cancel");
        }
        
        vm.open();
      }

    };

    vm.showOccupancy = function(max,total){
      if(max === -1){
        return true;
      }
      var occupancy = max-total;
      if(occupancy<1){
        return false;
      }
      return true;
    };

    vm.showInfo = function(activity){
      vm.activity = activity;
      vm.openInfo();
    };

    vm.openInfo = function (size, parentSelector) {
      var parentElem = parentSelector ? 
        angular.element($document[0].querySelector('.modal-demo ' + parentSelector)) : undefined;
      var modalInstance = $uibModal.open({
        animation: vm.animationsEnabled,
        ariaLabelledBy: 'modal-title',
        ariaDescribedBy: 'modal-body',
        templateUrl: 'showInfo.html',
        controller: 'ModalInfoController',
        controllerAs: 'modalCtrl',
        size: size,
        backdrop: 'static',
        keyboard: false,
        appendTo: parentElem,
        resolve: {
          activity: function () {
            return vm.activity;
          }
        }
      });
  
      modalInstance.result.then(function () {
      }, function () {
        // $log.info('Modal dismissed at: ' + new Date());
      });
    };

    vm.addToWaiting = function(activity){
      vm.activity = activity;
      vm.title = $translate.instant("reserve.add_to_waitinglist");
      vm.openWaitingList();
    };

    vm.openWaitingList = function (size, parentSelector) {
      var parentElem = parentSelector ? 
        angular.element($document[0].querySelector('.modal-demo ' + parentSelector)) : undefined;
      var modalInstance = $uibModal.open({
        animation: vm.animationsEnabled,
        ariaLabelledBy: 'modal-title',
        ariaDescribedBy: 'modal-body',
        templateUrl: 'waitingreservation.html',
        controller: 'ModalWaitingController',
        controllerAs: 'modalCtrl',
        size: size,
        backdrop: 'static',
        keyboard: false,
        appendTo: parentElem,
        resolve: {
          title: function () {
            return vm.title;
          },
          day: function () {
            return vm.day;
          },
          user: function () {
            return $rootScope.user;
          },
          members: function () {
            return vm.dependents;
          },
          activity: function () {
            return vm.activity;
          }
        }
      });
  
      modalInstance.result.then(function (result) {
          if(result.status === 'OK'){
            vm.activity = result.activity;
            vm.member = result.member;
            if(vm.member && vm.activity){

              var data = {"usr":vm.member,"at":vm.activity.id,"day":vm.daycount};

              Request.post('waitinglist/',true,data,function(response) {
                if(response.status !== 0){
                  toaster.pop('error', "", $translate.instant('api.'+response.description));
                }else{
                  toaster.pop('success', "", $translate.instant("reserve.waitinglist_added"));
                }
              });
            }
          }
      }, function () {
        // $log.info('Modal dismissed at: ' + new Date());
      });
    };



    vm.openSignup = function(){
      vm.user = {};
      vm.user.companyId = EnvConfig.COMPANY_ID;
      vm.user.profile_img = "images/Icono_persona.png";
      vm.openSignupForm();
    };

    vm.openSignupForm = function (size, parentSelector) {
      var parentElem = parentSelector ? 
        angular.element($document[0].querySelector('.modal-demo ' + parentSelector)) : undefined;
      // size = "lg";
      var modalInstance = $uibModal.open({
        animation: vm.animationsEnabled,
        ariaLabelledBy: 'modal-title',
        ariaDescribedBy: 'modal-body',
        templateUrl: 'signup.html',
        controller: 'ModalSignupFormController',
        controllerAs: 'modalCtrl',
        size: size,
        backdrop: 'static',
        keyboard: false,
        appendTo: parentElem,
        resolve: {
          user: function () {
            return vm.user;
          }
        }
      });
  
      modalInstance.result.then(function (result) {
        if(result.status === 'OK'){
          if(result.action === 'signin'){
            vm.signIn(result.user.email,result.user.password);
          }
          if(result.action === 'signup'){
            vm.signUp(result.user);
          }
        }
      }, function () {
        // $log.info('Modal dismissed at: ' + new Date());
      });
    };

    vm.signUp = function (user){
        Request.post('signupcancha',false,user,function(response) {
          if(response.status !== 0){
            toaster.pop('error', "", $translate.instant('api.'+response.description));
          }else{
            toaster.pop('success', "", $translate.instant("signup.success"));
            vm.signIn(user.email, user.password);
          }
        });
    };

    vm.signIn = function (user, pwd){
      var data = {user:user,password:pwd};
      Request.put('signin',false,data,function(response) {
        if(response.status !== 0){
          toaster.pop('error', "", $translate.instant('api.'+response.description));
          $localstorage.clear();
        }else{
          $localstorage.set('token',response.token);
          $localstorage.setObject('user',response.description);
          $localstorage.setObject('dependents',response.dependents);
          $localstorage.setObject('user_files',response.user_files);
          $rootScope.user = response.description;
          $rootScope.user.profile_img = "images/Icono_persona.png";
          $rootScope.user.nameToShow = $rootScope.user.firstName.split(" ");
          $rootScope.user.nameToShow = $rootScope.user.nameToShow[0];
          $rootScope.reservasButton = ($rootScope.user.userAccess==='CANCHA')?"/homecanchas":"/home";
          vm.user = $rootScope.user;
          $translate.use(response.description.lang);
          $localstorage.set('showMsg',(response.configuration.showInitialMsg===1)?true:false);
          $localstorage.setObject('config',response.configuration);
          $localstorage.setObject('messages',response.messages);
          $rootScope.messages = response.messages;
          $rootScope.headLander = true;
          vm.doReservation('PARENT',vm.activity);
        }
      });
    };

  }]);

  angular.module('ufcw').controller('ModalCanchaReservaController', ["$uibModalInstance", "$log", "title", "day", "dependents", "action", "activity", "toaster", "$translate", "Request", function ($uibModalInstance,$log,title,day,dependents,action,activity,toaster,$translate,Request) {
    var vm = this;

    vm.title = title;
    vm.activity = activity;
    vm.day = day;
    vm.dependents = dependents;
    vm.action = action;
    vm.guest = [];
    vm.error_guest = [];

    vm.ok = function () {

      try {
        if(vm.action === 'DEPENDENT' && !vm.selDependent){
          throw $translate.instant("reserve.must_select_dependent");
        }
        
        if(vm.guest.length > 0){
          vm.activity.guests = vm.guest.toString();
        }

        if(!vm.activity.reservationId && vm.activity.validateGuest===1){
          Request.get('user/?guests='+vm.guest.toString(),true,function(response) {
            if(response.code === "ERROR"){
              vm.error_guest = response.description;
              toaster.pop('error', "", $translate.instant("reserve.invalid_guest"));
            }else{
              $uibModalInstance.close({dependent: vm.selDependent, activity:vm.activity, status: 'OK'});
            }
          });
        }else{
          $uibModalInstance.close({dependent: vm.selDependent, activity:vm.activity, status: 'OK'});
        }

      } catch (error) {
        toaster.pop('error', "", error);
      }
    };
  
    vm.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  }]);

  angular.module('ufcw').controller('ModalWaitingController', ["$uibModalInstance", "$log", "title", "day", "user", "members", "activity", "toaster", function ($uibModalInstance,$log,title,day,user,members,activity,toaster) {
    var vm = this;
    
    vm.title = title;
    vm.activity = activity;
    vm.day = day;
    vm.user = user;
    vm.members = members;
    vm.has_dependent = (vm.members.length === 0)?false:true;

    vm.ok = function () {
      try {
        if(!vm.member){
          vm.member = vm.user.id;
        }
        $uibModalInstance.close({member: vm.member, activity:vm.activity, status: 'OK'});
      } catch (error) {
        toaster.pop('error', "", error);
      }
    };
  
    vm.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  }]);

  angular.module('ufcw').controller('ModalInfoController', ["$uibModalInstance", "$log", "activity", "toaster", function ($uibModalInstance,$log,activity,toaster) {
    var vm = this;
    
    vm.activity = activity;
    
    vm.ok = function () {
      try {
        $uibModalInstance.close({status: 'OK'});
      } catch (error) {
        toaster.pop('error', "", error);
      }
    };
  
    vm.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  }]);

  angular.module('ufcw').controller('ModalSignupFormController', ["$uibModalInstance", "$log", "user", "toaster", "$translate", function ($uibModalInstance,$log,user,toaster,$translate) {
    var vm = this;
    
    vm.user = user;
    vm.tipoSocio = "cancha";
    vm.show_signup = false;
    vm.title = $translate.instant("Inicio de sesión");

    function formatDate(num){
      return (num.toString().length===1)?"0"+num:""+num;
    }

    vm.updateTitle = function () {
      vm.title = $translate.instant("Registro de Usuario");
    };

    vm.signin = function () {
      vm.user.email = vm.email;
      vm.user.password = vm.password;
      $uibModalInstance.close({user: vm.user, action:'signin', status: 'OK'});
    };

    vm.signup = function () {
      try {
        vm.user.dob = vm.dob.getFullYear()+"-"+formatDate(vm.dob.getMonth()+1)+"-"+formatDate(vm.dob.getDate());  
        $uibModalInstance.close({user: vm.user, action:'signup', status: 'OK'});
      } catch (error) {
        toaster.pop('error', "", error);
      }
    };
  
    vm.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };

  }]);

  angular.module('ufcw').component('modalComponent', {
    templateUrl: 'confirmareservacancha.html',
    bindings: {
      resolve: '<',
      close: '&',
      dismiss: '&'
    },
    controller: function () {
      var vm = this;
  
      vm.$onInit = function () {
        vm.title = vm.resolve.title;
        vm.activity = vm.resolve.activity;
        vm.day = vm.resolve.day;
        vm.dependents = vm.resolve.dependents;
        vm.action = vm.resolve.action;
      };
  
      vm.ok = function () {
        vm.close({$value: 'OK'});
      };
  
      vm.cancel = function () {
        vm.dismiss({$value: 'cancel'});
      };
    }
  });

  angular.module('ufcw').component('modalComponent', {
    templateUrl: 'waitingreservation.html',
    bindings: {
      resolve: '<',
      close: '&',
      dismiss: '&'
    },
    controller: function () {
      var vm = this;
  
      vm.$onInit = function () {
        vm.title = vm.resolve.title;
        vm.activity = vm.resolve.activity;
        vm.day = vm.resolve.day;
        vm.user = vm.resolve.user;
        vm.members = vm.resolve.members;
      };
  
      vm.ok = function () {
        vm.close({$value: 'OK'});
      };
  
      vm.cancel = function () {
        vm.dismiss({$value: 'cancel'});
      };
    }
  });

  angular.module('ufcw').component('modalComponent', {
    templateUrl: 'showInfo.html',
    bindings: {
      resolve: '<',
      close: '&',
      dismiss: '&'
    },
    controller: function () {
      var vm = this;
  
      vm.$onInit = function () {
        vm.activity = vm.resolve.activity;
      };
  
      vm.ok = function () {
        vm.close({$value: 'OK'});
      };
    }
  });

  angular.module('ufcw').component('modalComponent', {
    templateUrl: 'signup.html',
    bindings: {
      resolve: '<',
      close: '&',
      dismiss: '&'
    },
    controller: function () {
      var vm = this;
  
      vm.$onInit = function () {
        vm.user = vm.resolve.user;
      };
  
      vm.ok = function () {
        vm.close({$value: 'OK'});
      };
    }
  });

  angular.module('ufcw').controller('ModalWaitingController', ["$uibModalInstance", "$log", "title", "day", "user", "members", "activity", "toaster", function ($uibModalInstance,$log,title,day,user,members,activity,toaster) {
    var vm = this;
    
    vm.title = title;
    vm.activity = activity;
    vm.day = day;
    vm.user = user;
    vm.members = members;
    vm.has_dependent = (vm.members.length === 0)?false:true;

    vm.ok = function () {
      try {
        if(!vm.member){
          vm.member = vm.user.id;
        }
        $uibModalInstance.close({member: vm.member, activity:vm.activity, status: 'OK'});
      } catch (error) {
        toaster.pop('error', "", error);
      }
    };
  
    vm.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  }]);

  angular.module('ufcw').component('modalComponent', {
    templateUrl: 'waitingreservation.html',
    bindings: {
      resolve: '<',
      close: '&',
      dismiss: '&'
    },
    controller: function () {
      var vm = this;
  
      vm.$onInit = function () {
        vm.title = vm.resolve.title;
        vm.activity = vm.resolve.activity;
        vm.day = vm.resolve.day;
        vm.user = vm.resolve.user;
        vm.members = vm.resolve.members;
      };
  
      vm.ok = function () {
        vm.close({$value: 'OK'});
      };
  
      vm.cancel = function () {
        vm.dismiss({$value: 'cancel'});
      };
    }
  });
'use strict';

angular.module('ufcw')
  .controller('ComprarcuponeraController', ["$log", "Request", "$localstorage", "$state", "$rootScope", "toaster", "$uibModal", "$document", "EnvConfig", "$window", "$translate", function ($log,Request,$localstorage,$state,$rootScope,toaster,$uibModal,$document,EnvConfig,$window,$translate) {
    var vm = this;
    
    vm.errorMsg = null;
    var user  = $localstorage.getObject('user');
    var token  = $localstorage.get('token');
    
    vm.URL_PAGOS = EnvConfig.URL_PAGOS;

    $rootScope.user = user;
    $rootScope.headLander = true;
    $rootScope.titulopagina = $translate.instant("general.buy_cuponeras");
   
    vm.token=token;

    //get activities for user category
    Request.get('activitycategory',true,function(response) {
      if(response.status !== 0){
        toaster.pop('info', "", $translate.instant('api.'+response.description));
      }else{
        vm.activities = response.description;
        vm.activities.map(function(item){
          item.icon = (item.icon)?EnvConfig.URL_IMAGES+item.icon:EnvConfig.URL_IMAGES+'icono_calendario.png';
          item.description = (item.description)?angular.fromJson(item.description):item.description;
        });
      }
    });

    Request.get('cuponera',true,function(response) {
      if(response.status !== 0){
        toaster.pop('info', "", $translate.instant('api.'+response.description));
      }else{
        vm.cuponeras = response.description;
        // vm.cuponeras.map(function(item){
          // item.icon = (item.icon)?EnvConfig.URL_IMAGES+item.icon:EnvConfig.URL_IMAGES+'icono_calendario.png';
          // item.description = (item.description)?angular.fromJson(item.description):item.description;
        // });
      }
    });



  // MODAL
  vm.animationsEnabled = true;

  vm.open = function (size, parentSelector) {
    var tam = (size)?size:'lg';
    var parentElem = parentSelector ?
      angular.element($document[0].querySelector('.modal-demo ' + parentSelector)) : undefined;
    var modalInstance = $uibModal.open({
      animation: vm.animationsEnabled,
      ariaLabelledBy: 'modal-title',
      ariaDescribedBy: 'modal-body',
      templateUrl: 'initialmsg.html',
      controller: 'ModalInitialMsgController',
      controllerAs: 'modalCtrl',
      backdrop: 'static',
      keyboard: false,
      size: tam,
      appendTo: parentElem,
      resolve: {
        msg: function () {
          return vm.msg;
        }
      }
    });

    modalInstance.result.then(function () {
      }, function () {
    });
  };

  vm.showInit = function(){
    var config = $localstorage.getObject('config');
    vm.msg = config.initialMsg;
    vm.open();
  };

  if(vm.show_msg === 'true'){
    vm.showInit();
  }


  }]);

  angular.module('ufcw').controller('ModalInitialMsgController', ["$uibModalInstance", "$log", "msg", "$localstorage", "$sce", function ($uibModalInstance,$log,msg,$localstorage,$sce) {
    var vm = this;
    vm.msg = $sce.trustAsHtml(msg);

    vm.ok = function () {
      $uibModalInstance.close({status: 'OK'});
    };

    vm.cancel = function () {
      $localstorage.set('showMsg',false);
      $uibModalInstance.dismiss('cancel');
    };

  }]);

  angular.module('ufcw').component('modalComponent', {
  templateUrl: 'initialmsg.html',
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&'
  },
  controller: function () {
    var vm = this;

    vm.$onInit = function () {
      vm.msg = vm.resolve.msg;
    };

    vm.ok = function () {
      vm.close({$value: 'OK'});
    };

    vm.cancel = function () {
      vm.dismiss({$value: 'cancel'});
    };
  }
});




'use strict';

angular.module('ufcw')
  .controller('CuponeraController', ["$rootScope", "$log", "$location", "Request", "$localstorage", "$uibModal", "$document", "$state", "toaster", "$translate", function ($rootScope,$log,$location,Request,$localstorage,$uibModal,$document,$state,toaster,$translate) {
    
    var vm = this;
    vm.reservation_enabled = true;
    vm.carnetExpirated = false;
    vm.payExpirated = false;
    vm.payment_text = "";
    

    // var id = $location.search().id; 
    var user  = $localstorage.getObject('user');
    vm.dependents = $localstorage.getObject('dependents');
    vm.has_dependent = (vm.dependents.length === 0)?false:true;
    var config  = $localstorage.getObject('config');
    if(config.reservationEnabled===0){
      vm.reservation_enabled = false;
      vm.notice_title = config.noticeAlertTitle;
      vm.notice_text = config.noticeAlertText;
    }else{
      vm.reservation_enabled = true;
    }

    $rootScope.user = user;
    vm.showNextDay = true;

    
    $rootScope.headLander = true;
    $rootScope.titulopagina = $translate.instant("Actividades cuponera");
   
    var days = [$translate.instant('days.sunday'), $translate.instant('days.monday'), $translate.instant('days.tuesday'), $translate.instant('days.wednesday'), $translate.instant('days.thursday'), $translate.instant('days.friday'), $translate.instant('days.saturday')];
    var months = [$translate.instant('months.january'), $translate.instant('months.february'), $translate.instant('months.march'), $translate.instant('months.april'), $translate.instant('months.may'), $translate.instant('months.june'), $translate.instant('months.july'),$translate.instant('months.august'),$translate.instant('months.september'),$translate.instant('months.october'),$translate.instant('months.november'),$translate.instant('months.december')];
    var d = new Date();
    
    function unique(value, index, self){
      return self.indexOf(value) === index;
    }

    vm.sendNotification = function(id,action){
      var data = {"type":"email","action":action,"reservationId":id};
      Request.post('notification/',true,data,function() { });

    };
    
    vm.daycount = 0;

    vm.changeDate = function(option){
      if(option==='ADD'){
        vm.daycount+=1;
        d.setDate(d.getDate() + 1);
      }else{
        vm.daycount-=1;
        d.setDate(d.getDate() - 1);
      }
      if(vm.daycount>0){
        vm.showNextDay = false;
      }else if(vm.daycount===0){
        vm.showNextDay = true;
      }
      
        var cuponeraExpiration = new Date(vm.infocuponera.expirationDate);
        if(d<=cuponeraExpiration){
          
                    vm.showNextDay = true;

        }else{
          vm.showNextDay = false;
        }


    };

    vm.load = function(){
      var id = $location.search().id; 

      Request.get('usercuponera/?id='+id+"&detail=true",true,function(response) {
        if(response.status !== 0){
          toaster.pop('info', "", $translate.instant('api.'+response.description));
        }else{
          
          vm.infocuponera = response.description.cuponera[0];
          // vm.infocuponera.created = vm.infocuponera.created.replace(/(.+) (.+)/, "$1T$2Z");
          // vm.infocuponera.created = new Date(vm.infocuponera.created).getTime();
          vm.infocuponera.created = (vm.infocuponera.created)? new Date(vm.infocuponera.created):vm.infocuponera.created;
          vm.cuponera_detail = {}; 
          vm.cuponera_detail.reservations = response.description.reservations;
          vm.cuponera_detail.title = response.description.cuponera[0];
        }
      });


      var dayName = days[d.getDay()];
      var dayNumber = d.getDate();

      var monthName = months[d.getMonth()];
      
      vm.day=dayName +" "+ dayNumber+ " " + $translate.instant("general.of") + " "+ monthName;

      var params = "?id="+id+"&days="+vm.daycount+"&userId="+$rootScope.user.id;

      //cambiar a cuponeraactividad
      Request.get('cuponeraactivity/'+params,true,function(response) {
        if(response.status !== 0){
          vm.errorMsg = $translate.instant(response.description);
        }else{
          // $localstorage.setObject('config',response.config);
          // var config  = $localstorage.getObject('config');
          // if(config.reservationEnabled===0){
          //   vm.reservation_enabled = false;
          //   vm.notice_title = config.noticeAlertTitle;
          //   vm.notice_text = config.noticeAlertText;
          // }

          vm.activities = response.description;
          var categories = vm.activities.map(function(item) {
            return item.CategoryName;
          });
          var filter_act = vm.activities.map(function(item) {
            return item.name;
          });
          
          vm.maxActiveResFlag = response.maxActiveReservation;
          if (vm.maxActiveResFlag>0){
            vm.dayStartRes = response.maxActiveReservationStartDay;
          }else{
            vm.dayStartRes = 0;
          }
          vm.categories = categories.filter(unique);
          vm.categories.sort();
          vm.filter_act = filter_act.filter(unique);
          vm.filter_act.sort();
          vm.no_activities = (vm.activities.length === 0)?true:false;
          
          vm.show_selectCat = (vm.categories.length>1)?true:false;
          vm.show_selectAct = (vm.filter_act.length>1)?true:false;
          
          

        }
      });
    };

    vm.reservation = function (id,at,day,desc) {
      var data = {"usr":id,"at":at,"day":day,"description":desc,"cId":vm.infocuponera.id};
// console.log(day);
      Request.post('cuponerareservation/',true,data,function(response) {
        if(response.status !== 0){
          var res = response.description.substring(0, 4);
          var prefix = (res==="api.")?res:"";
          toaster.pop('error', "", $translate.instant(prefix+response.description));
        }else{
          toaster.pop('success', "", $translate.instant("reserve.success"));
          vm.load();
          vm.sendNotification(response.description.id,'confirm');

          //if payment post to payment page
          // window.open('payment');
          ////
        }
      });
    };

    vm.cancelReservation = function (id) {
      Request.delete('cuponerareservation/?id='+id+'&cId='+vm.infocuponera.id,true,function(response) {
        if(response.status !== 0){
          var res = response.description.substring(0, 4);
          var prefix = (res==="api.")?res:"";
          toaster.pop('error', "", $translate.instant(prefix+response.description));
          // toaster.pop('error', "", $translate.instant('api.'+response.description));
        }else{
          toaster.pop('success', "", $translate.instant("reserve.canceled_ok"));
          vm.load();
          vm.sendNotification(id,'cancel');
        }
      });
    };

    vm.load();

    // MODAL THINGS
    vm.animationsEnabled = true;
    
    vm.open = function (size, parentSelector) {
      var parentElem = parentSelector ? 
        angular.element($document[0].querySelector('.modal-demo ' + parentSelector)) : undefined;
      var modalInstance = $uibModal.open({
        animation: vm.animationsEnabled,
        ariaLabelledBy: 'modal-title',
        ariaDescribedBy: 'modal-body',
        templateUrl: 'confirmareserva.html',
        controller: 'ModalReservaController',
        controllerAs: 'modalCtrl',
        size: size,
        backdrop: 'static',
        keyboard: false,
        appendTo: parentElem,
        resolve: {
          title: function () {
            return vm.title;
          },
          day: function () {
            return vm.day;
          },
          dependents: function () {
            return vm.dependents;
          },
          action: function () {
            return vm.action;
          },
          activity: function () {
            return vm.activity;
          }
        }
      });
  
      modalInstance.result.then(function (result) {
          if(result.status === 'OK'){
            vm.activity = result.activity;
            switch (vm.action) {
              case 'PARENT':
                vm.reservation(vm.id,vm.at,vm.moredays,vm.activity.guests);  
              break;
              case 'DEPENDENT':
                vm.reservation(result.dependent,vm.at,vm.moredays,vm.activity.guests);
              break;
              case 'CANCEL':
                vm.cancelReservation(vm.activity.reservationId);
              break;
            }
          }
      }, function () {
        // $log.info('Modal dismissed at: ' + new Date());
      });
    };

    vm.openVirtual = function (size, parentSelector) {      
      var parentElem = parentSelector ? angular.element($document[0].querySelector('.modal-demo ' + parentSelector)) : undefined;
      var modalInstance = $uibModal.open({
        animation: vm.animationsEnabled,
        ariaLabelledBy: 'modal-title',
        ariaDescribedBy: 'modal-body',
        templateUrl: 'clasevirtual.html',
        controller: 'ModalVirtualController',
        controllerAs: 'modalCtrl',
        size: size,
        backdrop: 'static',
        keyboard: false,
        appendTo: parentElem,
        resolve: {
          title: function () {
            return vm.title;
          },
          activity: function () {
            return vm.activity;
          }
        }
      });
  
      modalInstance.result.then(function () {
        
          // if(result.status === 'OK'){
          //   vm.activity = result.activity;
          //   switch (vm.action) {
          //     case 'PARENT':
          //       vm.reservation(vm.id,vm.at,vm.moredays,vm.activity.guests);  
          //     break;
          //     case 'DEPENDENT':
          //       vm.reservation(result.dependent,vm.at,vm.moredays,vm.activity.guests);
          //     break;
          //     case 'CANCEL':
          //       vm.cancelReservation(vm.activity.reservationId);
          //     break;
          //   }
          // }
      }, function () {
        // $log.info('Modal dismissed at: ' + new Date());
      });
    };

    vm.doReservation = function(action,activity){
      vm.activity = activity;
      vm.activity.guests = "";
      vm.action = action;
      vm.at = vm.activity.id;
      vm.id = $rootScope.user.id;
      vm.moredays = vm.daycount;
      if(action === 'PARENT' || action === 'DEPENDENT'){
        vm.title = $translate.instant("reserve.modal_title_confirm");
      }else if(action === 'CANCEL'){
        vm.title = $translate.instant("reserve.modal_title_cancel");
      }
      
      vm.open();
    };

    vm.showVirtual = function(activity){
      vm.title = $translate.instant("reserve.view_class");
      vm.activity = activity;
      vm.at = vm.activity.id;
      vm.id = $rootScope.user.id;
      
      vm.openVirtual();
    };

    vm.showOccupancy = function(max,total){
      if(max === -1){
        return true;
      }
      var occupancy = max-total;
      if(occupancy<1){
        return false;
      }
      return true;
    };

    vm.showInfo = function(activity){
      vm.activity = activity;
      vm.openInfo();
    };

    vm.openInfo = function (size, parentSelector) {
      var parentElem = parentSelector ? 
        angular.element($document[0].querySelector('.modal-demo ' + parentSelector)) : undefined;
      var modalInstance = $uibModal.open({
        animation: vm.animationsEnabled,
        ariaLabelledBy: 'modal-title',
        ariaDescribedBy: 'modal-body',
        templateUrl: 'showInfo.html',
        controller: 'ModalInfoController',
        controllerAs: 'modalCtrl',
        size: size,
        backdrop: 'static',
        keyboard: false,
        appendTo: parentElem,
        resolve: {
          activity: function () {
            return vm.activity;
          }
        }
      });
  
      modalInstance.result.then(function () {
      }, function () {
        // $log.info('Modal dismissed at: ' + new Date());
      });
    };

    vm.addToWaiting = function(activity){
      vm.activity = activity;
      vm.title = $translate.instant("reserve.add_to_waitinglist");
      vm.openWaitingList();
    };

    vm.openWaitingList = function (size, parentSelector) {
      var parentElem = parentSelector ? 
        angular.element($document[0].querySelector('.modal-demo ' + parentSelector)) : undefined;
      var modalInstance = $uibModal.open({
        animation: vm.animationsEnabled,
        ariaLabelledBy: 'modal-title',
        ariaDescribedBy: 'modal-body',
        templateUrl: 'waitingreservation.html',
        controller: 'ModalWaitingController',
        controllerAs: 'modalCtrl',
        size: size,
        backdrop: 'static',
        keyboard: false,
        appendTo: parentElem,
        resolve: {
          title: function () {
            return vm.title;
          },
          day: function () {
            return vm.day;
          },
          user: function () {
            return $rootScope.user;
          },
          members: function () {
            return vm.dependents;
          },
          activity: function () {
            return vm.activity;
          }
        }
      });
  
      modalInstance.result.then(function (result) {
          if(result.status === 'OK'){
            vm.activity = result.activity;
            vm.member = result.member;
            if(vm.member && vm.activity){

              var data = {"usr":vm.member,"at":vm.activity.id,"day":vm.daycount};

              Request.post('waitinglist/',true,data,function(response) {
                if(response.status !== 0){
                  toaster.pop('error', "", $translate.instant('api.'+response.description));
                }else{
                  toaster.pop('success', "", $translate.instant("reserve.waitinglist_added"));
                }
              });
            }
          }
      }, function () {
        // $log.info('Modal dismissed at: ' + new Date());
      });
    };



    vm.viewDetail = function(){
      vm.detail = vm.cuponera_detail;
      vm.openDetailCupon();
    };

    vm.openDetailCupon = function (size, parentSelector) {
      var parentElem = parentSelector ? 
        angular.element($document[0].querySelector('.modal-demo ' + parentSelector)) : undefined;
      size = "lg";
      var modalInstance = $uibModal.open({
        animation: vm.animationsEnabled,
        ariaLabelledBy: 'modal-title',
        ariaDescribedBy: 'modal-body',
        templateUrl: 'detailCupon.html',
        controller: 'ModalDetalleCuponeraController',
        controllerAs: 'modalCtrl',
        size: size,
        backdrop: 'static',
        keyboard: false,
        appendTo: parentElem,
        resolve: {
          detail: function () {
            return vm.detail;
          }
        }
      });
  
      modalInstance.result.then(function () {
      }, function () {
        // $log.info('Modal dismissed at: ' + new Date());
      });
    };

  }]);

  angular.module('ufcw').controller('ModalReservaController', ["$uibModalInstance", "$log", "title", "day", "dependents", "action", "activity", "toaster", "$translate", "Request", function ($uibModalInstance,$log,title,day,dependents,action,activity,toaster,$translate,Request) {
    var vm = this;
    
    vm.title = title;
    vm.activity = activity;
    vm.day = day;
    vm.dependents = dependents;
    vm.action = action;
    vm.guest = [];
    vm.error_guest = [];
    vm.ok = function () {
      try {
        if(vm.action === 'DEPENDENT' && !vm.selDependent){
          throw $translate.instant("reserve.must_select_dependent");
        }
        
        if(vm.guest.length > 0){
          vm.activity.guests = vm.guest.toString();
        }

        if(!vm.activity.reservationId && vm.activity.validateGuest===1){
          Request.get('user/?guests='+vm.guest.toString(),true,function(response) {
            if(response.code === "ERROR"){
              vm.error_guest = response.description;
              toaster.pop('error', "", $translate.instant("reserve.invalid_guest"));
            }else{
              $uibModalInstance.close({dependent: vm.selDependent, activity:vm.activity, status: 'OK'});
            }
          });
        }else{
          $uibModalInstance.close({dependent: vm.selDependent, activity:vm.activity, status: 'OK'});
        }

      } catch (error) {
        toaster.pop('error', "", error);
      }
    };
  
    vm.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  }]);

  angular.module('ufcw').controller('ModalWaitingController', ["$uibModalInstance", "$log", "title", "day", "user", "members", "activity", "toaster", function ($uibModalInstance,$log,title,day,user,members,activity,toaster) {
    var vm = this;
    
    vm.title = title;
    vm.activity = activity;
    vm.day = day;
    vm.user = user;
    vm.members = members;
    vm.has_dependent = (vm.members.length === 0)?false:true;

    vm.ok = function () {
      try {
        if(!vm.member){
          vm.member = vm.user.id;
        }
        $uibModalInstance.close({member: vm.member, activity:vm.activity, status: 'OK'});
      } catch (error) {
        toaster.pop('error', "", error);
      }
    };
  
    vm.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  }]);

  angular.module('ufcw').controller('ModalInfoController', ["$uibModalInstance", "$log", "activity", "toaster", function ($uibModalInstance,$log,activity,toaster) {
    var vm = this;
    
    vm.activity = activity;
    
    vm.ok = function () {
      try {
        $uibModalInstance.close({status: 'OK'});
      } catch (error) {
        toaster.pop('error', "", error);
      }
    };
  
    vm.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  }]);

  angular.module('ufcw').controller('ModalDetalleCuponeraController', ["$uibModalInstance", "$log", "detail", "toaster", "$translate", function ($uibModalInstance,$log,detail,toaster,$translate) {
    var vm = this;
    
    vm.detail = detail.reservations;
    vm.title = detail.title;

    var STATUS_MAP = {
      CUPONERARESERVED: $translate.instant('reserve.status_reserved'),
      CUPONERACANCELED: $translate.instant('reserve.status_canceled'),
      null: '',
      '': '',
      undefined: '',
    };

    vm.haveRes = (vm.detail.length>0)?true:false;
    
    vm.detail.map(function(item){
      item.statusText = STATUS_MAP[item.status];
      item.reservationdate = new Date(item.reservationdate);
    });

    vm.ok = function () {
      try {
        $uibModalInstance.close({status: 'OK'});
      } catch (error) {
        toaster.pop('error', "", error);
      }
    };
  
    vm.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  }]);

  angular.module('ufcw').controller('ModalVirtualController', ["$uibModalInstance", "$log", "title", "activity", function ($uibModalInstance,$log,title,activity) {
    var vm = this;
    
    vm.title = title;
    vm.activity = activity;
  
    

    vm.ok = function () {
      // try {
      //   if(vm.action === 'DEPENDENT' && !vm.selDependent){
      //     throw "Debe seleccionar un familiar";
      //   }
      //   if(vm.inv1){
      //     vm.activity.guests += ""+vm.inv1;
      //   }
      //   if(vm.inv2){
      //     vm.activity.guests += ", "+vm.inv2;
      //   }
      //   if(vm.inv3){
      //     vm.activity.guests += ", "+vm.inv3;
      //   }
      //   $uibModalInstance.close({dependent: vm.selDependent, activity:vm.activity, status: 'OK'});
      // } catch (error) {
      //   toaster.pop('error', "", error);
      // }
    };
  
    vm.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  }]);
  angular.module('ufcw').component('modalComponent', {
    templateUrl: 'clasevirtual.html',
    bindings: {
      resolve: '<',
      close: '&',
      dismiss: '&'
    },
    controller: function () {
      var vm = this;
  
      vm.$onInit = function () {
        vm.title = vm.resolve.title;
        vm.activity = vm.resolve.activity;
        vm.day = vm.resolve.day;
        vm.dependents = vm.resolve.dependents;
        vm.action = vm.resolve.action;
      };
  
      vm.ok = function () {
        vm.close({$value: 'OK'});
      };
  
      vm.cancel = function () {
        vm.dismiss({$value: 'cancel'});
      };
    }
  });


  angular.module('ufcw').component('modalComponent', {
    templateUrl: 'confirmareserva.html',
    bindings: {
      resolve: '<',
      close: '&',
      dismiss: '&'
    },
    controller: function () {
      var vm = this;
  
      vm.$onInit = function () {
        vm.title = vm.resolve.title;
        vm.activity = vm.resolve.activity;
        vm.day = vm.resolve.day;
        vm.dependents = vm.resolve.dependents;
        vm.action = vm.resolve.action;
      };
  
      vm.ok = function () {
        vm.close({$value: 'OK'});
      };
  
      vm.cancel = function () {
        vm.dismiss({$value: 'cancel'});
      };
    }
  });

  angular.module('ufcw').component('modalComponent', {
    templateUrl: 'waitingreservation.html',
    bindings: {
      resolve: '<',
      close: '&',
      dismiss: '&'
    },
    controller: function () {
      var vm = this;
  
      vm.$onInit = function () {
        vm.title = vm.resolve.title;
        vm.activity = vm.resolve.activity;
        vm.day = vm.resolve.day;
        vm.user = vm.resolve.user;
        vm.members = vm.resolve.members;
      };
  
      vm.ok = function () {
        vm.close({$value: 'OK'});
      };
  
      vm.cancel = function () {
        vm.dismiss({$value: 'cancel'});
      };
    }
  });

  angular.module('ufcw').component('modalComponent', {
    templateUrl: 'showInfo.html',
    bindings: {
      resolve: '<',
      close: '&',
      dismiss: '&'
    },
    controller: function () {
      var vm = this;
  
      vm.$onInit = function () {
        vm.activity = vm.resolve.activity;
      };
  
      vm.ok = function () {
        vm.close({$value: 'OK'});
      };
  
      vm.cancel = function () {
        vm.dismiss({$value: 'cancel'});
      };
    }
  });

  angular.module('ufcw').component('modalComponent', {
    templateUrl: 'showInfo.html',
    bindings: {
      resolve: '<',
      close: '&',
      dismiss: '&'
    },
    controller: function () {
      var vm = this;
  
      vm.$onInit = function () {
        vm.activity = vm.resolve.activity;
      };
  
      vm.ok = function () {
        vm.close({$value: 'OK'});
      };
    }
  });

  angular.module('ufcw').component('modalComponent', {
    templateUrl: 'detailCupon.html',
    bindings: {
      resolve: '<',
      close: '&',
      dismiss: '&'
    },
    controller: function () {
      var vm = this;
  
      vm.$onInit = function () {
        vm.detail = vm.resolve.detail;
      };
  
      vm.ok = function () {
        vm.close({$value: 'OK'});
      };
    }
  });

  angular.module('ufcw').controller('ModalWaitingController', ["$uibModalInstance", "$log", "title", "day", "user", "members", "activity", "toaster", function ($uibModalInstance,$log,title,day,user,members,activity,toaster) {
    var vm = this;
    
    vm.title = title;
    vm.activity = activity;
    vm.day = day;
    vm.user = user;
    vm.members = members;
    vm.has_dependent = (vm.members.length === 0)?false:true;

    vm.ok = function () {
      try {
        if(!vm.member){
          vm.member = vm.user.id;
        }
        $uibModalInstance.close({member: vm.member, activity:vm.activity, status: 'OK'});
      } catch (error) {
        toaster.pop('error', "", error);
      }
    };
  
    vm.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  }]);

  angular.module('ufcw').component('modalComponent', {
    templateUrl: 'waitingreservation.html',
    bindings: {
      resolve: '<',
      close: '&',
      dismiss: '&'
    },
    controller: function () {
      var vm = this;
  
      vm.$onInit = function () {
        vm.title = vm.resolve.title;
        vm.activity = vm.resolve.activity;
        vm.day = vm.resolve.day;
        vm.user = vm.resolve.user;
        vm.members = vm.resolve.members;
      };
  
      vm.ok = function () {
        vm.close({$value: 'OK'});
      };
  
      vm.cancel = function () {
        vm.dismiss({$value: 'cancel'});
      };
    }
  });
'use strict';

angular.module('ufcw')
  .controller('FixedactivitiesController', ["$rootScope", "$log", "$location", "Request", "$localstorage", "$uibModal", "$document", "$state", "toaster", "$translate", "EnvConfig", function ($rootScope,$log,$location,Request,$localstorage,$uibModal,$document,$state,toaster,$translate,EnvConfig) {
    var vm = this;
    vm.reservation_enabled = true;
    vm.carnetExpirated = false;
    vm.payExpirated = false;
    vm.payment_text = "";
    
    // var id = $location.search().id; 
    var user  = $localstorage.getObject('user');
    vm.dependents = $localstorage.getObject('dependents');
    vm.has_dependent = (vm.dependents.length === 0)?false:true;
    var config  = $localstorage.getObject('config');
    vm.useBuilding = config.useBuilding;
    if(config.reservationEnabled===0){
      vm.reservation_enabled = false;
      vm.notice_title = config.noticeAlertTitle;
      vm.notice_text = config.noticeAlertText;
    }else{
      vm.reservation_enabled = true;
    }

    $rootScope.user = user;
    vm.showNextDay = true;

    if(EnvConfig.show_carnet_expiration){
      if($rootScope.user.carnetExpiration && $rootScope.user.carnetExpiration !== '0000-00-00'){
        var currentDateCar = new Date();
        var carnetExpirationDate = new Date($rootScope.user.carnetExpiration);
        var dateDiffCar = carnetExpirationDate.getTime() - currentDateCar.getTime();
        var daysCar = Math.round(dateDiffCar / (1000 * 60 * 60 * 24));
        vm.carnetExpirated = daysCar < 30 ? true:false;
      }
    }
    
    if(EnvConfig.show_payment_expiration){
      if($rootScope.user.payExpiration && $rootScope.user.payExpiration !== '0000-00-00'){
        var currentDatePay = new Date();
        var payExpirationDate = new Date($rootScope.user.payExpiration);
        var dateDiffPay = payExpirationDate.getTime() - currentDatePay.getTime();
        var daysPay = Math.round(dateDiffPay / (1000 * 60 * 60 * 24));
        if($rootScope.user.payMode === 'ANUAL'){
          vm.payment_text = $translate.instant('general.payExpiration_anual_message');
          vm.payExpirated = daysPay < 15 ? true:false;
        }
        if($rootScope.user.payMode === 'MENSUAL'){
          vm.payment_text = $translate.instant('general.payExpiration_mensual_message');
          vm.payExpirated = daysPay < 0 ? true:false;
        }
      }
    }
    
    $rootScope.headLander = true;
    $rootScope.titulopagina = $translate.instant("reserve.title");
   
    // var days = [$translate.instant('days.sunday'), $translate.instant('days.monday'), $translate.instant('days.tuesday'), $translate.instant('days.wednesday'), $translate.instant('days.thursday'), $translate.instant('days.friday'), $translate.instant('days.saturday')];
    // var months = [$translate.instant('months.january'), $translate.instant('months.february'), $translate.instant('months.march'), $translate.instant('months.april'), $translate.instant('months.may'), $translate.instant('months.june'), $translate.instant('months.july'),$translate.instant('months.august'),$translate.instant('months.september'),$translate.instant('months.october'),$translate.instant('months.november'),$translate.instant('months.december')];
    // var d = new Date();
    
    // function unique(value, index, self){
    //   return self.indexOf(value) === index;
    // }

    vm.sendNotification = function(id,action){
      var data = {"type":"email","action":action,"reservationId":id};
      Request.post('notification/',true,data,function() { });

    };
    
    // vm.daycount = 0;

    
    vm.load = function(){
      // var inicio=new Date("2020-08-17 23:59:59");
      // if(d<=inicio){
        // vm.reservation_enabled=false;
      // }else{
        // vm.reservation_enabled=true;
      // }
      // var dayName = days[d.getDay()];
      // var dayNumber = d.getDate();

      // var monthName = months[d.getMonth()];
      
      // vm.day=dayName +" "+ dayNumber+ " " + $translate.instant("general.of") + " "+ monthName;

      var params = "?&userId="+$rootScope.user.id;
      Request.get('fixedactivities/'+params,true,function(response) {
        if(response.status !== 0){
          vm.errorMsg = $translate.instant(response.description);
        }else{
          // $localstorage.setObject('config',response.config);
          // var config  = $localstorage.getObject('config');
          // if(config.reservationEnabled===0){
            // vm.reservation_enabled = false;
            // vm.notice_title = config.noticeAlertTitle;
            // vm.notice_text = config.noticeAlertText;
          // }

          vm.fixedactivities = response.description;
          // var categories = vm.activities.map(function(item) {
          //   return item.CategoryName;
          // });
          // var filter_act = vm.activities.map(function(item) {
          //   return item.name;
          // });
          
          // vm.maxActiveResFlag = response.maxActiveReservation;
          // if (vm.maxActiveResFlag>0){
          //   vm.dayStartRes = response.maxActiveReservationStartDay;
          // }else{
          //   vm.dayStartRes = 0;
          // }
          // vm.categories = categories.filter(unique);
          // vm.filter_act = filter_act.filter(unique);
          vm.no_activities = (vm.fixedactivities.length === 0)?true:false;
          
          // vm.show_selectCat = (vm.categories.length>1)?true:false;
          // vm.show_selectAct = (vm.filter_act.length>1)?true:false;
          
          // if (vm.maxActiveResFlag>0){
          //   if(d.getDay()===vm.dayStartRes){
          //     vm.showNextDay = false;
          //   }else{
          //     vm.showNextDay = true;
          //   }
          // }else{
          //   // para habilitar siguiente día desde el viernes hasta el lunes.
          //   if(d.getDay()===6 || d.getDay()===0){
          //     vm.showNextDay = true;
          //   }
          // }

        }
      });
    };

    vm.reservation = function (id,at,day,desc) {
      
      var data = {"usr":id,"at":at,"day":day,"description":desc};

      Request.post('reservation/',true,data,function(response) {
        if(response.status !== 0){
          var res = response.description.substring(0, 4);
          var prefix = (res==="api.")?res:"";
          toaster.pop('error', "", $translate.instant(prefix+response.description));
        }else{
          toaster.pop('success', "", $translate.instant("reserve.success"));
          vm.load();
          vm.sendNotification(response.description.id,'confirm');

          //if payment post to payment page
          // window.open('payment');
          ////
        }
      });
    };

    vm.cancelReservation = function (id) {
      Request.delete('reservation/?id='+id,true,function(response) {
        if(response.status !== 0){
          var res = response.description.substring(0, 4);
          var prefix = (res==="api.")?res:"";
          toaster.pop('error', "", $translate.instant(prefix+response.description));
          // toaster.pop('error', "", $translate.instant('api.'+response.description));
        }else{
          toaster.pop('success', "", $translate.instant("reserve.canceled_ok"));
          vm.load();
          vm.sendNotification(id,'cancel');
        }
      });
    };

    vm.load();

    // MODAL THINGS
    vm.animationsEnabled = true;
    
    vm.open = function (size, parentSelector) {
      var parentElem = parentSelector ? 
        angular.element($document[0].querySelector('.modal-demo ' + parentSelector)) : undefined;
      var modalInstance = $uibModal.open({
        animation: vm.animationsEnabled,
        ariaLabelledBy: 'modal-title',
        ariaDescribedBy: 'modal-body',
        templateUrl: 'confirmareserva.html',
        controller: 'ModalReservaController',
        controllerAs: 'modalCtrl',
        size: size,
        backdrop: 'static',
        keyboard: false,
        appendTo: parentElem,
        resolve: {
          title: function () {
            return vm.title;
          },
          day: function () {
            return vm.activityDateFixed;
          },
          dependents: function () {
            return vm.dependents;
          },
          action: function () {
            return vm.action;
          },
          activity: function () {
            return vm.activity;
          }
        }
      });
  
      modalInstance.result.then(function (result) {
          if(result.status === 'OK'){
            vm.activity = result.activity;
            switch (vm.action) {
              case 'PARENT':
                vm.reservation(vm.id,vm.at,vm.moredays,vm.activity.guests);  
              break;
              case 'DEPENDENT':
                vm.reservation(result.dependent,vm.at,vm.moredays,vm.activity.guests);
              break;
              case 'CANCEL':
                vm.cancelReservation(vm.activity.reservationId);
              break;
            }
          }
      }, function () {
        // $log.info('Modal dismissed at: ' + new Date());
      });
    };

    vm.openVirtual = function (size, parentSelector) {
      var parentElem = parentSelector ? angular.element($document[0].querySelector('.modal-demo ' + parentSelector)) : undefined;
      var modalInstance = $uibModal.open({
        animation: vm.animationsEnabled,
        ariaLabelledBy: 'modal-title',
        ariaDescribedBy: 'modal-body',
        templateUrl: 'clasevirtual.html',
        controller: 'ModalVirtualController',
        controllerAs: 'modalCtrl',
        size: size,
        backdrop: 'static',
        keyboard: false,
        appendTo: parentElem,
        resolve: {
          title: function () {
            return vm.title;
          },
          activity: function () {
            return vm.activity;
          }
        }
      });
  
      modalInstance.result.then(function () {
        
          // if(result.status === 'OK'){
          //   vm.activity = result.activity;
          //   switch (vm.action) {
          //     case 'PARENT':
          //       vm.reservation(vm.id,vm.at,vm.moredays,vm.activity.guests);  
          //     break;
          //     case 'DEPENDENT':
          //       vm.reservation(result.dependent,vm.at,vm.moredays,vm.activity.guests);
          //     break;
          //     case 'CANCEL':
          //       vm.cancelReservation(vm.activity.reservationId);
          //     break;
          //   }
          // }
      }, function () {
        // $log.info('Modal dismissed at: ' + new Date());
      });
    };

    vm.doReservation = function(action,activity){
      vm.activity = activity;
      vm.activity.guests = "";
      vm.action = action;
      vm.at = vm.activity.id;
      vm.id = $rootScope.user.id;
      vm.moredays = vm.daycount;
      if(action === 'PARENT' || action === 'DEPENDENT'){
        vm.title = $translate.instant("reserve.modal_title_confirm");
      }else if(action === 'CANCEL'){
        vm.title = $translate.instant("reserve.modal_title_cancel");
      }
      
      vm.open();
    };

    vm.showVirtual = function(activity){
      vm.title = $translate.instant("reserve.view_class");
      vm.activity = activity;
      vm.at = vm.activity.id;
      vm.id = $rootScope.user.id;
      
      vm.openVirtual();
    };

    vm.showOccupancy = function(max,total){
      if(max === -1){
        return true;
      }
      var occupancy = max-total;
      if(occupancy<1){
        return false;
      }
      return true;
    };

    vm.showInfo = function(activity){
      vm.activity = activity;
      vm.openInfo();
    };

    vm.openInfo = function (size, parentSelector) {
      var parentElem = parentSelector ? 
        angular.element($document[0].querySelector('.modal-demo ' + parentSelector)) : undefined;
      var modalInstance = $uibModal.open({
        animation: vm.animationsEnabled,
        ariaLabelledBy: 'modal-title',
        ariaDescribedBy: 'modal-body',
        templateUrl: 'showInfo.html',
        controller: 'ModalInfoController',
        controllerAs: 'modalCtrl',
        size: size,
        backdrop: 'static',
        keyboard: false,
        appendTo: parentElem,
        resolve: {
          activity: function () {
            return vm.activity;
          }
        }
      });
  
      modalInstance.result.then(function () {
      }, function () {
        // $log.info('Modal dismissed at: ' + new Date());
      });
    };

    vm.addToWaiting = function(activity){
      vm.activity = activity;
      vm.title = $translate.instant("reserve.add_to_waitinglist");
      vm.openWaitingList();
    };

    vm.openWaitingList = function (size, parentSelector) {
      var parentElem = parentSelector ? 
        angular.element($document[0].querySelector('.modal-demo ' + parentSelector)) : undefined;
      var modalInstance = $uibModal.open({
        animation: vm.animationsEnabled,
        ariaLabelledBy: 'modal-title',
        ariaDescribedBy: 'modal-body',
        templateUrl: 'waitingreservation.html',
        controller: 'ModalWaitingController',
        controllerAs: 'modalCtrl',
        size: size,
        backdrop: 'static',
        keyboard: false,
        appendTo: parentElem,
        resolve: {
          title: function () {
            return vm.title;
          },
          day: function () {
            return vm.day;
          },
          user: function () {
            return $rootScope.user;
          },
          members: function () {
            return vm.dependents;
          },
          activity: function () {
            return vm.activity;
          }
        }
      });
  
      modalInstance.result.then(function (result) {
          if(result.status === 'OK'){
            vm.activity = result.activity;
            vm.member = result.member;
            if(vm.member && vm.activity){

              var data = {"usr":vm.member,"at":vm.activity.id,"day":vm.daycount};

              Request.post('waitinglist/',true,data,function(response) {
                if(response.status !== 0){
                  toaster.pop('error', "", $translate.instant('api.'+response.description));
                }else{
                  toaster.pop('success', "", $translate.instant("reserve.waitinglist_added"));
                }
              });
            }
          }
      }, function () {
        // $log.info('Modal dismissed at: ' + new Date());
      });
    };

    vm.openGuests = function (size, parentSelector) {
      var parentElem = parentSelector ? 
        angular.element($document[0].querySelector('.modal-demo ' + parentSelector)) : undefined;
      var modalInstance = $uibModal.open({
        animation: vm.animationsEnabled,
        ariaLabelledBy: 'modal-title',
        ariaDescribedBy: 'modal-body',
        templateUrl: 'guests.html',
        controller: 'ModalEditGuestsController',
        controllerAs: 'modalCtrl',
        size: size,
        backdrop: 'static',
        keyboard: false,
        appendTo: parentElem,
        resolve: {
          activity: function () {
            return vm.activity;
          }
        }
      });
  
      modalInstance.result.then(function () {
      }, function () {
        // $log.info('Modal dismissed at: ' + new Date());
      });
    };

    vm.editGuests = function(activity){
      if(activity.id){
        Request.get('reservation/?id='+activity.reservationId,true,function(response) {
          var reservation = response.description;
          if(angular.isString(reservation.description)){
            activity.guests = reservation.description.split(',');
          }
          activity.editGuests = true;
          activity.day = vm.day;
          vm.activity = activity;
          vm.openGuests();
        });
      }
    };

  }]);

  angular.module('ufcw').controller('ModalReservaController', ["$uibModalInstance", "$log", "title", "day", "dependents", "action", "activity", "toaster", "$translate", "Request", "$localstorage", function ($uibModalInstance,$log,title,day,dependents,action,activity,toaster,$translate,Request,$localstorage) {
    var vm = this;
    
    vm.title = title;
    vm.activity = activity;
    vm.day = day;
    vm.dependents = dependents;
    vm.action = action;
    vm.guest = [];
    vm.error_guest = [];
    var config  = $localstorage.getObject('config');
    vm.useBuilding = config.useBuilding;
    vm.ok = function () {
      try {
        if(vm.action === 'DEPENDENT' && !vm.selDependent){
          throw $translate.instant("reserve.must_select_dependent");
        }
        
        if(vm.guest.length > 0){
          vm.activity.guests = vm.guest.toString();
        }

        if(!vm.activity.reservationId && vm.activity.validateGuest===1){
          Request.get('user/?guests='+vm.guest.toString(),true,function(response) {
            if(response.code === "ERROR"){
              vm.error_guest = response.description;
              toaster.pop('error', "", $translate.instant("reserve.invalid_guest"));
            }else{
              $uibModalInstance.close({dependent: vm.selDependent, activity:vm.activity, status: 'OK'});
            }
          });
        }else{
          $uibModalInstance.close({dependent: vm.selDependent, activity:vm.activity, status: 'OK'});
        }

      } catch (error) {
        toaster.pop('error', "", error);
      }
    };
  
    vm.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  }]);

  angular.module('ufcw').controller('ModalWaitingController', ["$uibModalInstance", "$log", "title", "day", "user", "members", "activity", "toaster", function ($uibModalInstance,$log,title,day,user,members,activity,toaster) {
    var vm = this;
    
    vm.title = title;
    vm.activity = activity;
    vm.day = day;
    vm.user = user;
    vm.members = members;
    vm.has_dependent = (vm.members.length === 0)?false:true;

    vm.ok = function () {
      try {
        if(!vm.member){
          vm.member = vm.user.id;
        }
        $uibModalInstance.close({member: vm.member, activity:vm.activity, status: 'OK'});
      } catch (error) {
        toaster.pop('error', "", error);
      }
    };
  
    vm.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  }]);

  angular.module('ufcw').controller('ModalInfoController', ["$uibModalInstance", "$log", "activity", "toaster", function ($uibModalInstance,$log,activity,toaster) {
    var vm = this;
    
    vm.activity = activity;
    
    vm.ok = function () {
      try {
        $uibModalInstance.close({status: 'OK'});
      } catch (error) {
        toaster.pop('error', "", error);
      }
    };
  
    vm.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  }]);

  angular.module('ufcw').controller('ModalVirtualController', ["$uibModalInstance", "$log", "title", "activity", function ($uibModalInstance,$log,title,activity) {
    var vm = this;
    
    vm.title = title;
    vm.activity = activity;
  
    

    vm.ok = function () {
      // try {
      //   if(vm.action === 'DEPENDENT' && !vm.selDependent){
      //     throw "Debe seleccionar un familiar";
      //   }
      //   if(vm.inv1){
      //     vm.activity.guests += ""+vm.inv1;
      //   }
      //   if(vm.inv2){
      //     vm.activity.guests += ", "+vm.inv2;
      //   }
      //   if(vm.inv3){
      //     vm.activity.guests += ", "+vm.inv3;
      //   }
      //   $uibModalInstance.close({dependent: vm.selDependent, activity:vm.activity, status: 'OK'});
      // } catch (error) {
      //   toaster.pop('error', "", error);
      // }
    };
  
    vm.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  }]);
  angular.module('ufcw').component('modalComponent', {
    templateUrl: 'clasevirtual.html',
    bindings: {
      resolve: '<',
      close: '&',
      dismiss: '&'
    },
    controller: function () {
      var vm = this;
  
      vm.$onInit = function () {
        vm.title = vm.resolve.title;
        vm.activity = vm.resolve.activity;
        vm.day = vm.resolve.day;
        vm.dependents = vm.resolve.dependents;
        vm.action = vm.resolve.action;
      };
  
      vm.ok = function () {
        vm.close({$value: 'OK'});
      };
  
      vm.cancel = function () {
        vm.dismiss({$value: 'cancel'});
      };
    }
  });


  angular.module('ufcw').component('modalComponent', {
    templateUrl: 'confirmareserva.html',
    bindings: {
      resolve: '<',
      close: '&',
      dismiss: '&'
    },
    controller: function () {
      var vm = this;
  
      vm.$onInit = function () {
        vm.title = vm.resolve.title;
        vm.activity = vm.resolve.activity;
        vm.day = vm.resolve.day;
        vm.dependents = vm.resolve.dependents;
        vm.action = vm.resolve.action;
      };
  
      vm.ok = function () {
        vm.close({$value: 'OK'});
      };
  
      vm.cancel = function () {
        vm.dismiss({$value: 'cancel'});
      };
    }
  });

  angular.module('ufcw').component('modalComponent', {
    templateUrl: 'waitingreservation.html',
    bindings: {
      resolve: '<',
      close: '&',
      dismiss: '&'
    },
    controller: function () {
      var vm = this;
  
      vm.$onInit = function () {
        vm.title = vm.resolve.title;
        vm.activity = vm.resolve.activity;
        vm.day = vm.resolve.day;
        vm.user = vm.resolve.user;
        vm.members = vm.resolve.members;
      };
  
      vm.ok = function () {
        vm.close({$value: 'OK'});
      };
  
      vm.cancel = function () {
        vm.dismiss({$value: 'cancel'});
      };
    }
  });

  angular.module('ufcw').component('modalComponent', {
    templateUrl: 'showInfo.html',
    bindings: {
      resolve: '<',
      close: '&',
      dismiss: '&'
    },
    controller: function () {
      var vm = this;
  
      vm.$onInit = function () {
        vm.activity = vm.resolve.activity;
      };
  
      vm.ok = function () {
        vm.close({$value: 'OK'});
      };
  
      vm.cancel = function () {
        vm.dismiss({$value: 'cancel'});
      };
    }
  });

  angular.module('ufcw').controller('ModalInfoController', ["$uibModalInstance", "$log", "activity", "toaster", function ($uibModalInstance,$log,activity,toaster) {
    var vm = this;
    
    vm.activity = activity;
    
    vm.ok = function () {
      try {
        $uibModalInstance.close({status: 'OK'});
      } catch (error) {
        toaster.pop('error', "", error);
      }
    };
  
    vm.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  }]);

  angular.module('ufcw').component('modalComponent', {
    templateUrl: 'showInfo.html',
    bindings: {
      resolve: '<',
      close: '&',
      dismiss: '&'
    },
    controller: function () {
      var vm = this;
  
      vm.$onInit = function () {
        vm.activity = vm.resolve.activity;
      };
  
      vm.ok = function () {
        vm.close({$value: 'OK'});
      };
    }
  });

  angular.module('ufcw').controller('ModalWaitingController', ["$uibModalInstance", "$log", "title", "day", "user", "members", "activity", "toaster", function ($uibModalInstance,$log,title,day,user,members,activity,toaster) {
    var vm = this;
    
    vm.title = title;
    vm.activity = activity;
    vm.day = day;
    vm.user = user;
    vm.members = members;
    vm.has_dependent = (vm.members.length === 0)?false:true;

    vm.ok = function () {
      try {
        if(!vm.member){
          vm.member = vm.user.id;
        }
        $uibModalInstance.close({member: vm.member, activity:vm.activity, status: 'OK'});
      } catch (error) {
        toaster.pop('error', "", error);
      }
    };
  
    vm.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  }]);

  angular.module('ufcw').component('modalComponent', {
    templateUrl: 'waitingreservation.html',
    bindings: {
      resolve: '<',
      close: '&',
      dismiss: '&'
    },
    controller: function () {
      var vm = this;
  
      vm.$onInit = function () {
        vm.title = vm.resolve.title;
        vm.activity = vm.resolve.activity;
        vm.day = vm.resolve.day;
        vm.user = vm.resolve.user;
        vm.members = vm.resolve.members;
      };
  
      vm.ok = function () {
        vm.close({$value: 'OK'});
      };
  
      vm.cancel = function () {
        vm.dismiss({$value: 'cancel'});
      };
    }
  });

  angular.module('ufcw').controller('ModalEditGuestsController', ["$uibModalInstance", "$log", "Request", "toaster", "$translate", "activity", function ($uibModalInstance,$log,Request,toaster,$translate,activity) {
    var vm = this;
    
    vm.activity = activity;
    vm.guests = activity.guests;
    vm.action = $translate.instant("general.view_action");
    
    if(vm.activity.editGuests){
      vm.action = $translate.instant("general.edit_action");
    }

    if(!activity.reservationId){
      activity.reservationId = activity.id;
    }

    vm.ok = function () {
      if(vm.activity.editGuests){
        if(vm.activity.validateGuest===1){
          Request.get('user/?guests='+vm.guests.toString(),true,function(response) {
            if(response.code === "ERROR"){
              vm.error_guest = response.description;
              toaster.pop('error', "", $translate.instant("reserve.invalid_guest"));
            }else{
              vm.updateReservation(vm.activity.reservationId,vm.guests);
              $uibModalInstance.close({status: 'OK'});
            }
          });
        }else{
          vm.updateReservation(vm.activity.reservationId,vm.guests);
          $uibModalInstance.close({status: 'OK'});
        }
      }
    };
  
    vm.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };

    vm.updateReservation = function(id,guests){

      var data = {description: guests.toString()};
      Request.put('reservationfixed/?id='+id,true,data,function(response) {
        if(response.status !== 0){
          toaster.pop('error', "", $translate.instant('api.'+response.description));
        }else{
          toaster.pop('success', "", $translate.instant("reserve.reservation_updated"));
        }
      });
    };

  }]);

  angular.module('ufcw').component('modalComponent', {
    templateUrl: 'guests.html',
    bindings: {
      resolve: '<',
      close: '&',
      dismiss: '&'
    },
    controller: function () {
      var vm = this;
  
      vm.$onInit = function () {
        vm.activity = vm.resolve.activity;
      };
  
      vm.ok = function () {
        vm.close({$value: 'OK'});
      };
  
      vm.cancel = function () {
        vm.dismiss({$value: 'cancel'});
      };
    }
  });
'use strict';

angular.module('ufcw')
  .controller('FronthorariosController', ["$log", "Request", "$rootScope", "$location", "toaster", "$translate", function ($log,Request,$rootScope,$location,toaster,$translate) {
    var vm = this;
    vm.errorMsg = null;
    var id = $location.search().id; 
    
    $rootScope.headLander = false;
    
    function unique(value, index, self){
      return self.indexOf(value) === index;
    }



    function UniqueArraybyhour(collection, keyname) {
      var output = []; 
        var  keys = [];

        var aux_list_field = Object.keys(collection);
        // $log.log(aux_list_field,'pino');
  
      angular.forEach(aux_list_field, function(itemWeek) {
        // $log.log(itemWeek);
        angular.forEach(collection[itemWeek], function(item) {
          var key = item[keyname];
         
          if(keys.indexOf(key) === -1) {
              keys.push(key);
              var newHour = {};
              newHour.time =item.starttime;

              if  (item.starttime<'12:00:00'){
                newHour.turno ='#3abba9';
                newHour.text = 'white';
              }else{
                if  (item.starttime<'16:00:00'){
                  newHour.turno ='#3095bb';
                  newHour.text = 'white';
                }else{
                  if  (item.starttime<'19:00:00'){
                    newHour.turno ='#164d7b';
                    newHour.text = 'white';
                  }else{
                    newHour.turno ='#082b49';
                    newHour.text = 'white';
                  }
                  
                }
  
              }
              newHour.days = vm.createEmptyArrayDay();

  
              output.push(newHour);
          }
      });
      // $log.log(output,'output');

    });
  
      output.sort(function(a, b) {
        return a.time.localeCompare(b.time);
      });
  return output;
  }


  vm.findWithAttr=function(array, attr, value) {
 
    for(var i = 0; i < array.length; i += 1) {
        if(array[i][attr] === value) {
            return i;
        }
    }
    return -1;
};

    vm.load = function(id){
      //get activities for user category
      if(id){
        Request.get('nooauthactivity/?type=act&id='+id,true,function(response) {
          if(response.status !== 0){
            toaster.pop('info', "", $translate.instant('api.'+response));
          }else{
            var categories = [];
            for (var day in response.description) {
              for (var acts in response.description[day]) {
                categories.push(response.description[day][acts].UserCategory);
              }
            }
            vm.activities = response.description;
            vm.ucategories = categories.filter(unique);
            vm.ucategories.sort();
            
            vm.show_select = (vm.ucategories.length>1)?true:false;
            vm.selCategory = "";
  
            vm.arrayHour = UniqueArraybyhour(vm.activities, "starttime");
  
            
            var auxKeys = Object.keys(vm.activities);
            
            angular.forEach(auxKeys, function(mItemk){
              // $log.log(mItemk);
              angular.forEach( vm.activities[mItemk], function(mItem){
                // $log.log(mItem,'coco');
                // $log.log(vm.arrayHour);
                var hourPosition = vm.findWithAttr(vm.arrayHour,'time',mItem.starttime);
  
                if (hourPosition>=0){
  
                  var dayPosition = vm.findWithAttr(vm.arrayHour[hourPosition].days,'dofw',mItem.dayoftheweek);
  
                  
                  if (dayPosition>=0){
                    vm.arrayHour[hourPosition].days[dayPosition].list_at.push(mItem);
                  }
  
  
                }
              });
  
            });
            
          } 
        });
      }
    };

    vm.loadActivities = function (){
      Request.get('nooauthactivity/get/?type=cat',true,function(response) {
        vm.listactivities = response.description;
        vm.listactivities.sort();
        vm.show_selectAct = (vm.listactivities.length>1)?true:false;
      });
    };

    vm.createEmptyArrayDay=function(){
      var m_Array = [];
      var ix ;
      for ( ix = 0; ix < 7; ix++) {
        var m_item = {};
        m_item.dofw = ix+1;
  
        if (ix===6){
          m_item.dofw=0;
        }
       var nameday = "";
  
        switch (m_item.dofw) {
          case 0:
            nameday = $translate.instant("days.sunday");
            break;
          case 1:
            nameday = $translate.instant("days.monday");
            break;
          case 2:
            nameday = $translate.instant("days.tuesday");
            break;
          case 3:
            nameday = $translate.instant("days.wednesday");
            break;
          case 4:
            nameday = $translate.instant("days.thursday");
            break;
          case 5:
            nameday = $translate.instant("days.friday");
            break;
          case 6:
            nameday = $translate.instant("days.saturday");
            break;
        }
        m_item.nameDay = nameday;
        m_item.list_at =[];
        m_Array.push(m_item);
      }

      return m_Array;
  
      
    };




    
    vm.getnameday=function(codday){
      var nameday = "";
      switch (codday) {
        case 0:
          nameday = $translate.instant('days.sunday');
          break;
        case 1:
          nameday = $translate.instant('days.monday');
          break;
        case 2:
          nameday = $translate.instant('days.tuesday');
          break;
        case 3:
          nameday = $translate.instant('days.wednesday');
          break;
        case 4:
          nameday = $translate.instant('days.thursday');
          break;
        case 5:
          nameday = $translate.instant('days.friday');
          break;
        case 6:
          nameday = $translate.instant('days.saturday');
          break;
    
        default:
          break;
      }
      return nameday;
    };

    vm.load(id);
    vm.loadActivities();

  }]);

  




'use strict';

angular.module('ufcw')
  .controller('HomeController', ["$log", "Request", "$localstorage", "$state", "$rootScope", "toaster", "$uibModal", "$document", "EnvConfig", "$window", "$translate", function ($log,Request,$localstorage,$state,$rootScope,toaster,$uibModal,$document,EnvConfig,$window,$translate) {
    var vm = this;

    vm.reservation_enabled = true;
    vm.carnetExpirated = false;
    vm.carnetAlreadyExpired = false;
    vm.payExpirated = false;
    vm.payment_text = "";
    vm.favorites = false;
    
    vm.errorMsg = null;
    var user  = $localstorage.getObject('user');
    vm.show_msg = $localstorage.get('showMsg');
    var config  = $localstorage.getObject('config');
    vm.alerts  = $localstorage.getObject('alerts');
    vm.showAlerts = (vm.alerts.length>0)?true:false;
    vm.useRoutines = $localstorage.get('useRoutines');
    
    if(config.reservationEnabled===0){
      vm.reservation_enabled = false;
      vm.notice_title = config.noticeAlertTitle;
      vm.notice_text = config.noticeAlertText;
    }

    $rootScope.user = user;
    $rootScope.headLander = true;
    $rootScope.titulopagina = $translate.instant("general.activities");

    // if(EnvConfig.show_carnet_expiration || config.validateCarnetExpiration === 1){
    //   if($rootScope.user.carnetExpiration && $rootScope.user.carnetExpiration !== '0000-00-00'){
    //     var currentDate = new Date();
    //     var carnetExpirationDate = new Date($rootScope.user.carnetExpiration);
    //     var dateDiff = carnetExpirationDate.getTime() - currentDate.getTime();
    //     var days = Math.round(dateDiff / (1000 * 60 * 60 * 24));
    //     vm.carnetExpirated = days < 30 ? true:false;
    //     vm.carnetAlreadyExpired = days < 0 ? true:false;
    //   }
    // }
    
    // if(EnvConfig.show_payment_expiration){
    //   if($rootScope.user.payExpiration && $rootScope.user.payExpiration !== '0000-00-00'){
    //     var currentDatePay = new Date();
    //     var payExpirationDate = new Date($rootScope.user.payExpiration);
    //     var dateDiffPay = payExpirationDate.getTime() - currentDatePay.getTime();
    //     var daysPay = Math.round(dateDiffPay / (1000 * 60 * 60 * 24));
    //     if($rootScope.user.payMode === 'ANUAL'){
    //       vm.payment_text = $translate.instant('general.payExpiration_anual_message');
    //       vm.payExpirated = daysPay < 30 ? true:false;
    //     }
    //     if($rootScope.user.payMode === 'MENSUAL'){
    //       vm.payment_text = $translate.instant('general.payExpiration_mensual_message');
    //       vm.payExpirated = daysPay < 0 ? true:false;
    //     }
    //   }
    // }
    
        //para sinergia
        // if($rootScope.projectname==="sinergia"){    
        //   if(angular.isDefined($window.Beacon)){
        //     $window.Beacon('identify', {
        //       name: user.firstName+" "+user.lastName,
        //       email: user.email,
        //       });
        //   }
        // }
        
        //fin sinergia

    //get activities for user category
    Request.get('activitycategory/?from=FRONTEND',true,function(response) {
      if(response.status !== 0){
        toaster.pop('info', "", $translate.instant('api.'+response.description));
      }else{
        vm.activities = response.description;
        vm.activities.map(function(item){
          item.icon = (item.icon)?EnvConfig.URL_IMAGES+item.icon:EnvConfig.URL_IMAGES+'icono_calendario.png';
          item.description = (item.description)?angular.fromJson(item.description):item.description;
        });

        vm.activities.forEach(function (activity) {
          if(activity.favorite===1){
            vm.favorites = true;
          }            
        }
        );
        
        // vm.favorites = vm.activities.reduce((accumulator, current) => {
        //   if (current.favorite === 1) {
        //     accumulator.push(current);
        //   }
        //   return accumulator;
        // }, []);
        // $log.log(vm.favorites);
      }
    });

    //CUPONERAS
    Request.get('usercuponera',true,function(response) {
      if(response.status !== 0){
        toaster.pop('info', "", $translate.instant('api.'+response.description));
      }else{
        vm.cuponeras = response.description;
        if($rootScope.user.userAccess === "CUPONERA" && vm.cuponeras.length === 0 && config.usePayment === 1){
          $state.go('comprarcuponera');
        }
        vm.cuponeras.map(function(item){
          item.usercuponeraCreated = (item.usercuponeraCreated)? new Date(item.usercuponeraCreated):item.usercuponeraCreated;
        });
      }
    });

    // //FIXED ACTIVITIES
    // Request.get('fixedactivities',true,function(response) {
    //   if(response.status !== 0){
    //     toaster.pop('info', "", $translate.instant('api.'+response.description));
    //   }else{
    //     vm.fixedActivities = response.description;
    //     //if($rootScope.user.userAccess === "CUPONERA" && vm.cuponeras.length === 0){
    //     //  $state.go('comprarcuponera');
    //     //}
    //     //vm.cuponeras.map(function(item){
    //     //  item.usercuponeraCreated = (item.usercuponeraCreated)? new Date(item.usercuponeraCreated):item.usercuponeraCreated;
    //    // });
    //   }
    // });

  // MODAL
  vm.animationsEnabled = true;

  vm.open = function (size, parentSelector) {
    var tam = (size)?size:'lg';
    var parentElem = parentSelector ?
      angular.element($document[0].querySelector('.modal-demo ' + parentSelector)) : undefined;
    var modalInstance = $uibModal.open({
      animation: vm.animationsEnabled,
      ariaLabelledBy: 'modal-title',
      ariaDescribedBy: 'modal-body',
      templateUrl: 'initialmsg.html',
      controller: 'ModalInitialMsgController',
      controllerAs: 'modalCtrl',
      backdrop: 'static',
      keyboard: false,
      size: tam,
      appendTo: parentElem,
      resolve: {
        msg: function () {
          return vm.msg;
        }
      }
    });

    modalInstance.result.then(function () {
      }, function () {
    });
  };

  vm.showInit = function(){
    var config = $localstorage.getObject('config');
    vm.msg = config.initialMsg;
    vm.open();
  };

  if(vm.show_msg === 'true'){
    vm.showInit();
  }

  vm.viewDetail = function(cuponera_detail){
    if(cuponera_detail.activityCatType === 'INTERNAL'){
      Request.get('usercuponera/?id='+cuponera_detail.usercuponeraId+"&detail=true",true,function(response) {
        if(response.status !== 0){
          toaster.pop('info', "", $translate.instant('api.'+response.description));
        }else{
          vm.cuponera_detail = {}; 
          vm.cuponera_detail.reservations = response.description.reservations;
          vm.cuponera_detail.title = response.description.cuponera[0];
          vm.detail = vm.cuponera_detail;
          vm.openDetailCupon();
        }
      });
    }
  };

  vm.openDetailCupon = function (size, parentSelector) {
    var parentElem = parentSelector ? 
      angular.element($document[0].querySelector('.modal-demo ' + parentSelector)) : undefined;
    size = "lg";
    var modalInstance = $uibModal.open({
      animation: vm.animationsEnabled,
      ariaLabelledBy: 'modal-title',
      ariaDescribedBy: 'modal-body',
      templateUrl: 'detailCupon.html',
      controller: 'ModalDetalleCuponeraHomeController',
      controllerAs: 'modalCtrl',
      size: size,
      backdrop: 'static',
      keyboard: false,
      appendTo: parentElem,
      resolve: {
        detail: function () {
          return vm.detail;
        }
      }
    });

    modalInstance.result.then(function () {
    }, function () {
      // $log.info('Modal dismissed at: ' + new Date());
    });
  };


  }]);

  angular.module('ufcw').controller('ModalInitialMsgController', ["$uibModalInstance", "$log", "msg", "$localstorage", "$sce", function ($uibModalInstance,$log,msg,$localstorage,$sce) {
    var vm = this;
    vm.msg = $sce.trustAsHtml(msg);

    vm.ok = function () {
      $uibModalInstance.close({status: 'OK'});
    };

    vm.cancel = function () {
      $localstorage.set('showMsg',false);
      $uibModalInstance.dismiss('cancel');
    };

  }]);

  angular.module('ufcw').component('modalComponent', {
  templateUrl: 'initialmsg.html',
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&'
  },
  controller: function () {
    var vm = this;

    vm.$onInit = function () {
      vm.msg = vm.resolve.msg;
    };

    vm.ok = function () {
      vm.close({$value: 'OK'});
    };

    vm.cancel = function () {
      vm.dismiss({$value: 'cancel'});
    };
  }
});

angular.module('ufcw').controller('ModalDetalleCuponeraHomeController', ["$uibModalInstance", "$log", "detail", "toaster", "$translate", function ($uibModalInstance,$log,detail,toaster,$translate) {
  var vm = this;
  
  vm.detail = detail.reservations;
  vm.title = detail.title;

  var STATUS_MAP = {
    CUPONERARESERVED: $translate.instant('reserve.status_reserved'),
    CUPONERACANCELED: $translate.instant('reserve.status_canceled'),
    null: '',
    '': '',
    undefined: '',
  };

  vm.haveRes = (vm.detail.length>0)?true:false;
  
  vm.detail.map(function(item){
    item.statusText = STATUS_MAP[item.status];
    item.reservationdate = new Date(item.reservationdate);
  });

  vm.ok = function () {
    try {
      $uibModalInstance.close({status: 'OK'});
    } catch (error) {
      toaster.pop('error', "", error);
    }
  };

  vm.cancel = function () {
    $uibModalInstance.dismiss('cancel');
  };
}]);

angular.module('ufcw').component('modalComponent', {
  templateUrl: 'detailCupon.html',
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&'
  },
  controller: function () {
    var vm = this;

    vm.$onInit = function () {
      vm.detail = vm.resolve.detail;
    };

    vm.ok = function () {
      vm.close({$value: 'OK'});
    };
  }
});
'use strict';

angular.module('ufcw')
  .controller('HomeCanchaController', ["$log", "Request", "$localstorage", "$state", "$rootScope", "toaster", "$uibModal", "$document", "EnvConfig", "$window", "$translate", function ($log,Request,$localstorage,$state,$rootScope,toaster,$uibModal,$document,EnvConfig,$window,$translate) {
    var vm = this;

    vm.reservation_enabled = true;
    
    vm.errorMsg = null;
    var user  = $localstorage.getObject('user');
    vm.show_msg = $localstorage.get('showMsg');
    
    //redirect signin if show cachas config is disabled
    vm.showCanchas = EnvConfig.show_canchas;
    if(!vm.showCanchas){
      $state.go('signin');
    }

    if(user){
      user.profile_img = "images/Icono_persona.png";
      user.nameToShow = user.firstName.split(" ");
      user.nameToShow = user.nameToShow[0];
      $rootScope.user = user;
      $rootScope.headLander = true;
    }
    
    $rootScope.titulopagina = $translate.instant("general.activities");

    //get activities for user category
    Request.get('canchaactivity',false,function(response) {
      if(response.status !== 0){
        toaster.pop('info', "", $translate.instant('api.'+response.description));
      }else{
        vm.activities = response.description;
        vm.activities.map(function(item){
          item.icon = (item.icon)?EnvConfig.URL_IMAGES+item.icon:EnvConfig.URL_IMAGES+'icono_calendario.png';
          item.description = (item.description)?angular.fromJson(item.description):item.description;
        });
      }
    });


  }]);
'use strict';

angular.module('ufcw')
  .controller('HorariosController', ["$log", "Request", "$localstorage", "$state", "$rootScope", "EnvConfig", function ($log,Request,$localstorage,$state,$rootScope,EnvConfig) {
    var vm = this;
    vm.errorMsg = null;
    
    $rootScope.headLander = false;

    var token  = $localstorage.get('token');
    var user   = $localstorage.getObject('user');
    vm.backPath = "/home";
    if(!token){
      vm.backPath = "/signin";
    }
    if(user.userAccess === "CANCHA"){
      vm.backPath = "/homecanchas";
    }
    
    //get activities for user category
    Request.get('nooauthactivity/get/?type=cat',true,function(response) {
        vm.activities = response.description;
        vm.activities.map(function(item){
          item.icon = (item.icon)?EnvConfig.URL_IMAGES+item.icon:EnvConfig.URL_IMAGES+'icono_calendario.png';
        });
    });
      

  
  }]);

  




'use strict';

angular.module('ufcw')
  .controller('HorarioszaController', ["$rootScope", function ($rootScope) {
    var vm = this;
    vm.errorMsg = null;
    
    $rootScope.headLander = false;
    
  
  }]);

  




'use strict';

angular.module('ufcw')
  .controller('LoginController', ["$rootScope", "$localstorage", "Request", "$log", "$state", "toaster", "EnvConfig", "$window", "$translate", "$location", function ($rootScope,$localstorage,Request,$log,$state,toaster,EnvConfig,$window,$translate,$location) {
    
    $rootScope.headLander = false;
    var vm = this;
    vm.useRoutines = $localstorage.get('useRoutines');
    vm.errorMsg = null;
    $localstorage.clear();
    vm.showCanchas = EnvConfig.show_canchas;

    var id = $location.search().id; 
    var token = $location.search().token; 
    
    vm.login = function(){

      if(EnvConfig.SIGNIN_ENABLED || vm.email==="gonzalo@squal.us" || vm.email==="juanto@squal.us"){
        if(token && id){
          $localstorage.set('token', token);
          
          Request.get('user/?id='+id,true,function(response) {
            if(response.status !== 0){
              // vm.errorMsg = response.description;
              toaster.pop('error', "", $translate.instant('api.'+response.description));
              $localstorage.clear();
            }else{
              $localstorage.setObject('user',response.description);
              var user_access = response.description.userAccess;
              $localstorage.setObject('dependents',response.dependants);
              $localstorage.setObject('user_files',response.u_files);
              $translate.use(response.description.lang);
              $localstorage.set('showMsg',(response.configuration.showInitialMsg===1)?true:false);
              $localstorage.setObject('config',response.configuration);
              $localstorage.setObject('messages',response.messages);
              $localstorage.setObject('alerts',response.alerts);
              $localstorage.set('showLogout',false);
              $rootScope.messages = response.messages;
              if(user_access === 'CANCHA'){
                $state.go('homecanchas');
              }else{
                $state.go('home');
              }
                
              // });
  
            }
          });
        }

      }else{
        toaster.pop('info',"", $translate.instant("signin.soon"));
      }
    };

    vm.login();
  }]);

'use strict';

angular.module('ufcw')
  .controller('MapController', ["$rootScope", "$translate", function ($rootScope, $translate) {
    var vm = this;
    vm.errorMsg = null;

    $rootScope.headLander = true;
    $rootScope.titulopagina = $translate.instant("");    

  }]);
'use strict';

angular.module('ufcw')
  .controller('MessageController', ["$log", "Request", "$localstorage", "$state", "$rootScope", "toaster", "$uibModal", "$document", "$translate", function ($log,Request,$localstorage,$state,$rootScope,toaster,$uibModal,$document, $translate) {
    var vm = this;
    vm.errorMsg = null;
    var user  = $localstorage.getObject('user');

    vm.users = $localstorage.getObject('dependents');
    vm.has_dependent = (vm.users.length === 0)?false:true;
    vm.users.push(user);

    vm.type_msg = 0;
    vm.type_selected = 'S';

    $rootScope.user = user;
    $rootScope.headLander = true;
    $rootScope.titulopagina = $translate.instant("general.messages");

    vm.loadMessages = function(type){
      //get next reservations for user
      if(!type){
        type = vm.type_msg;
      }
      
      Request.get('message/?seen='+type,true,function(response) {
        if(response.status !== 0){
          toaster.pop('info', "", response.description);
        }else{
          vm.messages = response.description;
          vm.messages.messages.map(function(item){
            item.created = new Date(item.created);
          });
          $localstorage.setObject('messages',response.description);
          $rootScope.messages = response.description;
          vm.no_messages = (vm.messages.messages.length === 0)?true:false;
        }
      });
    };

    vm.loadMessages(vm.type_msg);

    vm.selectedType = function(value){
      vm.type_selected = value;
    };

    vm.msg_types = [{key: 0, value: 'Sin leer'}, {key: 1, value: 'Leídos'}, {key: '', value: 'Todos'}];

    // vm.sendNotification = function(id,action){
    //   var data = {"type":"email","action":action,"reservationId":id};
    //   Request.post('notification/',true,data,function() {
    //   });
    // };

    // vm.cancelReservation = function (id) {
    //   Request.delete('reservation/?id='+id,true,function(response) {
    //     if(response.status !== 0){
    //       toaster.pop('error', "", response.description);
    //     }else{
    //       toaster.pop('success', "", "Reserva cancelada correctamente");
    //       vm.load();
    //       vm.sendNotification(id,'cancel');
    //     }
    //   });
    // };

    // MODAL THINGS
    vm.animationsEnabled = true;
    
    // vm.open = function (size, parentSelector) {
    //   var parentElem = parentSelector ? 
    //     angular.element($document[0].querySelector('.modal-demo ' + parentSelector)) : undefined;
    //   var modalInstance = $uibModal.open({
    //     animation: vm.animationsEnabled,
    //     ariaLabelledBy: 'modal-title',
    //     ariaDescribedBy: 'modal-body',
    //     templateUrl: 'confirmareserva.html',
    //     controller: 'ModalReservaController',
    //     controllerAs: 'modalCtrl',
    //     size: size,
    //     backdrop: 'static',
    //     keyboard: false,
    //     appendTo: parentElem,
    //     resolve: {
    //       title: function () {
    //         return vm.title;
    //       },
    //       day: function () {
    //         return vm.activity.day;
    //       },
    //       dependents: function () {
    //         return vm.dependents;
    //       },
    //       action: function () {
    //         return vm.action;
    //       },
    //       activity: function () {
    //         return vm.activity;
    //       },
    //       showInvitation: function () {
    //         return vm.showInvitation;
    //       }
    //     }
    //   });
  
    //   modalInstance.result.then(function (result) {
    //       if(result.status === 'OK'){
    //         vm.activity = result.activity;
    //         vm.cancelReservation(vm.activity.id);
    //       }
    //   }, function () {
    //     // $log.info('Modal dismissed at: ' + new Date());
    //   });
    // };



    // vm.cancelRes = function(activity){
    //   vm.activity = activity;
    //   vm.activity.description = "";
    //   vm.at = vm.activity.id;
    //   vm.id = $rootScope.user.id;
    //   vm.moredays = (vm.showNextDay)?0:1;
    //   vm.title = "Cancelar reserva";
    //   vm.showInvitation = false;
    //   if($rootScope.inArray(vm.activity.activityId,activity_invitations)){
    //     vm.showInvitation = true;
    //   }
    //   vm.open();
    // };

    vm.openViewMsg = function (size, parentSelector) {
      var parentElem = parentSelector ? 
        angular.element($document[0].querySelector('.modal-demo ' + parentSelector)) : undefined;
      var modalInstance = $uibModal.open({
        animation: vm.animationsEnabled,
        ariaLabelledBy: 'modal-title',
        ariaDescribedBy: 'modal-body',
        templateUrl: 'message.html',
        controller: 'ModalMessageController',
        controllerAs: 'modalCtrl',
        size: size,
        backdrop: 'static',
        keyboard: false,
        appendTo: parentElem,
        resolve: {
          msg: function () {
            return vm.msg;
          }
        }
      });
  
      modalInstance.result.then(function () {
      }, function () {
        vm.loadMessages();
      });
    };

    vm.viewMsg = function(msg){
      vm.msg = msg;
      Request.put('message/'+msg.id,true,function(response) {
        if(response.status !== 0){
          toaster.pop('info', "", response.description);
        }
      });
      vm.openViewMsg();
    };

    vm.deleteMsg = function(msg){
      Request.delete('message/'+msg.id,true,function(response) {
        if(response.status !== 0){
          toaster.pop('info', "", response.description);
        }
        vm.loadMessages();
      });
    };

  }]);

  angular.module('ufcw').controller('ModalMessageController', ["$uibModalInstance", "$log", "msg", function ($uibModalInstance,$log,msg) {
    var vm = this;
    
    vm.msg = msg;
  
    vm.ok = function () {
      
    };
  
    vm.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  }]);

  angular.module('ufcw').component('modalComponent', {
    templateUrl: 'message.html',
    bindings: {
      resolve: '<',
      close: '&',
      dismiss: '&'
    },
    controller: function () {
      var vm = this;
  
      vm.$onInit = function () {
        vm.msg = vm.resolve.msg;
      };
  
      vm.ok = function () {
        vm.close({$value: 'OK'});
      };
  
      vm.cancel = function () {
        vm.dismiss({$value: 'cancel'});
      };
    }
  });
'use strict';
angular.module('ufcw')
  .controller('MyProfileController', ["$log", "Request", "$localstorage", "$rootScope", "$uibModal", "$document", "toaster", "$translate", "$state", "EnvConfig", function ($log, Request, $localstorage, $rootScope, $uibModal, $document, toaster, $translate, $state, EnvConfig) {
    var vm = this;
    vm.errorMsg = null;
    vm.user = $localstorage.getObject('user');
    vm.dependents = $localstorage.getObject('dependents');
    var config  = $localstorage.getObject('config');
    vm.has_dependent = (vm.dependents.length === 0) ? false : true;
    vm.user_files = $localstorage.getObject('user_files');
    vm.user.profile_img = "images/Icono_persona.png";
    vm.user.cedula = "";
    vm.user.csalud = "";
    vm.user.certif = "";
    
    vm.hideMemberId = (config.hideMemberId===1)?true:false;
    vm.allowUploadPic = (config.dontAllowProfileImgChange===1)?false:true;
    
    var documents = ['txt', 'doc', 'docx'];
    var spreadsheet = ['csv', 'xls', 'xlsx'];
    var html = ['html'];
    var pdf = ['pdf'];

    vm.getFileIcon = function (file) {
      if(file){
        var dot = file.lastIndexOf('.');
        var extension = file.substring(dot+1,file.length);
        if (documents.includes(extension)) {
          return EnvConfig.URL_IMAGES + 'file-doc.png';
        }
        if (spreadsheet.includes(extension)) {
          return EnvConfig.URL_IMAGES + 'file-excel.png';
        }
        if (html.includes(extension)) {
          return EnvConfig.URL_IMAGES + 'file-html.png';
        }
        if (pdf.includes(extension)) {
          return EnvConfig.URL_IMAGES + 'file-pdf.png';
        }
        return file;
      }
      return null;
    };

    if(vm.user.carnetExpiration === "0000-00-00"){
      vm.user.carnetExpiration = '';
    }
    if(vm.user.payExpiration === "0000-00-00"){
      vm.user.payExpiration = '';
    }

    if (vm.user_files) {
      vm.user_profile = vm.user_files.filter(function (item) {
        if (item.fileType === "PERFIL") {
          return item;
        }
      });
      vm.user_cedula = vm.user_files.filter(function (item) {
        if (item.fileType === "CEDULA") {
          return item;
        }
      });
      vm.user_csalud = vm.user_files.filter(function (item) {
        if (item.fileType === "C_SALUD") {
          return item;
        }
      });
      vm.user_certif = vm.user_files.filter(function (item) {
        if (item.fileType === "NO_COVID") {
          return item;
        }
      });


      if (vm.user_profile.length > 0) {
        vm.user.profile_img = EnvConfig.URL_FILES + vm.user_profile[0].companyId + '/' + vm.user_profile[0].userId + '/' + vm.user_profile[0].fileName;
      }
      if (vm.user_cedula.length > 0) {
        vm.user.cedula = {};
        vm.user.cedula.path = EnvConfig.URL_FILES + vm.user_cedula[0].companyId + '/' + vm.user_cedula[0].userId + '/' + vm.user_cedula[0].fileName;
        vm.user.cedula.icon = vm.getFileIcon(vm.user.cedula.path);
      }
      if (vm.user_csalud.length > 0) {
        vm.user.csalud = {};
        vm.user.csalud.path = EnvConfig.URL_FILES + vm.user_csalud[0].companyId + '/' + vm.user_csalud[0].userId + '/' + vm.user_csalud[0].fileName;
        vm.user.csalud.icon = vm.getFileIcon(vm.user.csalud.path);
      }
      if (vm.user_certif.length > 0) {
        vm.user.certif = {};
        vm.user.certif.path = EnvConfig.URL_FILES + vm.user_certif[0].companyId + '/' + vm.user_certif[0].userId + '/' + vm.user_certif[0].fileName;
        vm.user.certif.icon = vm.getFileIcon(vm.user.certif.path);
      }
    }
    $rootScope.headLander = true;
    $rootScope.titulopagina = $translate.instant("general.my_profile");
    vm.update = function (user) {
      Request.put('user/?id=' + user.id, true, user, function (response) {
        if (response.status !== 0) {
          vm.user = $localstorage.getObject('user');
          toaster.pop('error', "", $translate.instant('api.' + response.description));
        } else {
          vm.changeLang(user.lang);
          $localstorage.setObject('user', response.description);
          toaster.pop('success', "", $translate.instant("profile.saved"));
        }
      });
    };
    var LANG_MAP = {
      es: $translate.instant('lang.es'),
      en: $translate.instant('lang.en'),
      null: '',
      '': '',
      undefined: '',
    };
    vm.user.lang_text = LANG_MAP[vm.user.lang];
    // MODAL
    vm.animationsEnabled = true;
    
    vm.open = function (size, parentSelector) {
      var parentElem = parentSelector ?
        angular.element($document[0].querySelector('.modal-demo ' + parentSelector)) : undefined;
      var modalInstance = $uibModal.open({
        animation: vm.animationsEnabled,
        ariaLabelledBy: 'modal-title',
        ariaDescribedBy: 'modal-body',
        templateUrl: 'profile.html',
        controller: 'ModalProfileController',
        controllerAs: 'modalCtrl',
        size: 'md',
        backdrop: 'static',
        keyboard: false,
        appendTo: parentElem,
        resolve: {
          user: function () {
            return vm.user;
          }
        }
      });
      modalInstance.result.then(function (result) {
        if (result.status === 'OK') {
          if (result.img) {
            vm.uploadProfileImg(result.img, result.user);
          }
          vm.update(result.user);
        }
      }, function () {
        // $log.info('Modal dismissed at: ' + new Date());
      });
    };
    vm.edit = function () {
      vm.open();
    };
    vm.changeLang = function (lang) {
      $translate.use(lang);
      $state.reload();
    };
    vm.uploadProfileImg = function (file, user) {
      var data = [{ name: "userId", value: user.id }, { name: "fileType", value: "PERFIL" }];
      Request.file('userfile', true, data, file, function (response) {
        if (response.status !== 0) {
          toaster.pop('error', "", $translate.instant('api.' + response.description));
        } else {
          $localstorage.setObject('user_files', response.description);
          toaster.pop('success', "", $translate.instant("profile.saved"));
        }
      });
    };

  }]);

angular.module('ufcw').controller('ModalProfileController', ["$uibModalInstance", "$log", "user", "toaster", "$translate", "$window", "Request", "$localstorage", function ($uibModalInstance, $log, user, toaster, $translate, $window, Request, $localstorage) {
  var vm = this;
  vm.user = user;

  var config  = $localstorage.getObject('config');
  vm.allowUploadPic = (config.dontAllowProfileImgChange===1)?false:true;
  vm.ok = function () {
    try {
      if (vm.user.passwordOld && !vm.user.passwordNew) {
        throw $translate.instant("profile.new_pwd_invalid");
      }
      if (vm.user.passwordNew && !vm.user.passwordOld) {
        throw $translate.instant("profile.current_pwd_invalid");
      }
      if (!vm.user.email) {
        throw $translate.instant("signin.email_invalid");
      }
      if (!vm.user.phone) {
        throw $translate.instant("profile.phone_invalid");
      }else{
        vm.user.phone = vm.user.phone.replaceAll(/\s/g,'');
        if(isNaN(vm.user.phone)){
          throw $translate.instant("profile.phone_invalid");
        }
      }
      // llamo a guardar los archivos
      if(vm.fileCedula){
        vm.uploadFile(vm.fileCedula, vm.user.id, "CEDULA");
      }
      if(vm.fileCsalud){
        vm.uploadFile(vm.fileCsalud, vm.user.id, "C_SALUD");
      }
      if(vm.fileCertificado){
        vm.uploadFile(vm.fileCertificado, vm.user.id, "NO_COVID");
      }
      $uibModalInstance.close({ user: vm.user, img: vm.file, status: 'OK' });
    } catch (error) {
      toaster.pop('error', "", error);
    }
  };
  vm.cancel = function () {
    $uibModalInstance.dismiss('cancel');
  };
  vm.langs = [{ "id": "es", "value": $translate.instant('lang.es') }, { "id": "en", "value": $translate.instant('lang.en') }];

  vm.openFile = function (value) {
    $window.document.getElementById(value).click();
  };

  vm.uploadFile = function (file, userid, filetype) {
    var data = [{ name: "userId", value: userid }, { name: "fileType", value: filetype }];

    Request.file('userfile', true, data, file, function (response) {
      if (response.status !== 0) {
        toaster.pop('error', "", $translate.instant('api.' + response.description));
      } else {
        
        $localstorage.setObject('user_files', response.description);
        toaster.pop('success', "", $translate.instant("profile.saved"));
      }
    });
  };

}]);

angular.module('ufcw').component('modalComponent', {
  templateUrl: 'profile.html',
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&'
  },
  controller: function () {
    var vm = this;
    vm.$onInit = function () {
      vm.user = vm.resolve.user;
    };
    vm.ok = function () {
      vm.close({ $value: 'OK' });
    };
    vm.cancel = function () {
      vm.dismiss({ $value: 'cancel' });
    };
  }
});


'use strict';

angular.module('ufcw')
  .controller('NoticeController', ["$log", "Request", "$localstorage", "$rootScope", "$uibModal", "$document", "toaster", "$translate", "$sce", function ($log,Request,$localstorage,$rootScope,$uibModal,$document,toaster,$translate,$sce) {
    var vm = this;
    
    vm.user  = $localstorage.getObject('user');
    vm.dependents = $localstorage.getObject('dependents');
    
    $rootScope.headLander = true;
    $rootScope.titulopagina = $translate.instant('notice.title');
    var text = $translate.instant('notice.text');
    vm.text = $sce.trustAsHtml(text);
    
    var msg = "El socio "+vm.user.firstName+" "+vm.user.lastName+", Nro. "+vm.user.externalId+" o un integrante de su grupo familiar";
        msg+= " da aviso de que posee COVID-19 positivo. <br><br>";
        msg+= "Sus datos de contacto son: <br><br>";
        msg+= "Tel&eacute;fono: "+vm.user.phone+". <br>";
        msg+= "C&eacute;dula: "+vm.user.identificationNumber+". <br><br>";
        msg+= "Grupo familiar: <br>";

    vm.dependents.map(function(item){
      msg+= "      "+item.firstName+" "+item.lastName+", Nro. "+item.externalId+". <br>";
    });


    vm.send = function(){
      var data = {subject:"Club Malvín - Aviso de COVID-19 positivo",title:"Aviso de COVID-19 Positivo",user:vm.user.id,msg:msg};
      Request.post('email/',true,data,function(response) {
        if(response.status !== 0){
          toaster.pop('error', "", $translate.instant('api.'+response.description));
        }else{
          toaster.pop('success', "", $translate.instant('notice.msg_sent'));
        }
      });
    };

    // MODAL
    vm.animationsEnabled = true;
    
    vm.open = function (size, parentSelector) {
      var parentElem = parentSelector ? 
        angular.element($document[0].querySelector('.modal-demo ' + parentSelector)) : undefined;
      var modalInstance = $uibModal.open({
        animation: vm.animationsEnabled,
        ariaLabelledBy: 'modal-title',
        ariaDescribedBy: 'modal-body',
        templateUrl: 'confirm.html',
        controller: 'ModalNoticeController',
        controllerAs: 'modalCtrl',
        size: size,
        backdrop: 'static',
        keyboard: false,
        appendTo: parentElem,
        resolve: {
          msg: function () {
            return vm.msg;
          }
        }
      });
  
      modalInstance.result.then(function (result) {
          if(result.status === 'OK'){
              vm.send();
          }
      }, function () {
        // $log.info('Modal dismissed at: ' + new Date());
      });
    };

    vm.confirm = function(){
      vm.msg = "";
      vm.open();
    };

  }]);

  angular.module('ufcw').controller('ModalNoticeController', ["$uibModalInstance", "$log", "msg", "$translate", function ($uibModalInstance, $log, msg, $translate) {
    var vm = this;
    
    vm.msg = msg;
    vm.text = $translate.instant('confirm.text');
    
    vm.ok = function () {
      $uibModalInstance.close({status: 'OK'});
    };
  
    vm.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  }]);

  angular.module('ufcw').component('modalComponent', {
    templateUrl: 'confirm.html',
    bindings: {
      resolve: '<',
      close: '&',
      dismiss: '&'
    },
    controller: function () {
      var vm = this;
  
      vm.$onInit = function () {
        vm.msg = vm.resolve.msg;
      };
  
      vm.ok = function () {
        vm.close({$value: 'OK'});
      };
  
      vm.cancel = function () {
        vm.dismiss({$value: 'cancel'});
      };
    }
  });

'use strict';

angular.module('ufcw')
  .controller('QrController', ["$rootScope", "$localstorage", "$translate", function ($rootScope,$localstorage,$translate) {
    var vm = this;
    vm.errorMsg = null;

    vm.user  = $localstorage.getObject('user');
    vm.QRFromS3 = $localstorage.get('QRFromS3');
    
    $rootScope.headLander = true;
    $rootScope.titulopagina = $translate.instant("Código de Ingreso");



    

  }]);
'use strict';

angular.module('ufcw')
  .controller('RecoveryController', ["$rootScope", "$localstorage", "Request", "$log", "$state", "toaster", "$translate", function ($rootScope,$localstorage,Request,$log,$state,toaster,$translate) {
    $rootScope.headLander = false;
    var vm = this;
    vm.errorMsg = null;
    
    vm.recovery = function(){  
      var data = {email:vm.email};
      Request.post('recovery',false,data,function(response) {
        if(response.status !== 0){
          toaster.pop('error', "", $translate.instant('api.'+response.description));
        }else{
          toaster.pop('success', "", $translate.instant('api.'+response.description));
          $state.go('setpwd');
        }
      });
      
    };

    vm.setpwd = function(){  
      
      var data = {password:vm.password};

      Request.put('recovery/?token='+vm.token,false,data,function(response) {
        if(response.status !== 0){
          toaster.pop('error', "", $translate.instant('api.'+response.description));
        }else{
          toaster.pop('success', "", $translate.instant('api.'+response.description));
          $state.go('signin');
        }
      });
      
    };
  }]);

'use strict';

angular.module('ufcw')
  .controller('ReglasController', ["$rootScope", function ($rootScope) {
    $rootScope.headLander = false;

  }]);

'use strict';

angular.module('ufcw')
  .controller('ResCanchaController', ["$rootScope", "$log", "$location", "Request", "$localstorage", "toaster", "$translate", function ($rootScope,$log,$location,Request,$localstorage,toaster,$translate) {

    var vm = this;    
    var id = $location.search().id; 

    var user  = $localstorage.getObject('user');
    vm.dependents = $localstorage.getObject('dependents');
    vm.has_dependent = (vm.dependents.length === 0)?false:true;
    var config  = $localstorage.getObject('config');
    if(config.reservationEnabled===0){
      vm.reservation_enabled = false;
      vm.notice_title = config.noticeAlertTitle;
      vm.notice_text = config.noticeAlertText;
    }else{
      vm.reservation_enabled = true;
    }

    $rootScope.user = user;    
    $rootScope.headLander = true;
    $rootScope.titulopagina = $translate.instant("Confirmación de Reserva");
   
    var days = [$translate.instant('days.sunday'), $translate.instant('days.monday'), $translate.instant('days.tuesday'), $translate.instant('days.wednesday'), $translate.instant('days.thursday'), $translate.instant('days.friday'), $translate.instant('days.saturday')];
    var months = [$translate.instant('months.january'), $translate.instant('months.february'), $translate.instant('months.march'), $translate.instant('months.april'), $translate.instant('months.may'), $translate.instant('months.june'), $translate.instant('months.july'),$translate.instant('months.august'),$translate.instant('months.september'),$translate.instant('months.october'),$translate.instant('months.november'),$translate.instant('months.december')];
    
    vm.load = function(id){
      Request.get('canchareservation/?id='+id,true,function(response) {
        if(response.status !== 0){
          toaster.pop('info', "", $translate.instant('api.'+response.description));
        }else{
          vm.reserva = response.description;
          if(vm.reserva.status === "CANCHARESERVED"){
            toaster.pop('success', "", $translate.instant("reserve.success"));
          }
          vm.reserva.cost = parseFloat(vm.reserva.cost);
          vm.reserva.reservationdate = vm.reserva.reservationdate.replace(/-/g, '/');
          var d = new Date(vm.reserva.reservationdate);
          var dayName = days[d.getDay()];
          var dayNumber = d.getDate();
          var monthName = months[d.getMonth()];
          vm.reserva.day = dayName +" "+ dayNumber+ " " + $translate.instant('general.of') + " "+ monthName;
        }
      });
    };

    if(id){
      vm.load(id);
    }

  }]);
'use strict';

angular.module('ufcw')
  .controller('ReservaactividadController', ["$rootScope", "$log", "$location", "Request", "$localstorage", "$uibModal", "$document", "$state", "toaster", "$translate", "EnvConfig", function ($rootScope,$log,$location,Request,$localstorage,$uibModal,$document,$state,toaster,$translate,EnvConfig) {
    var vm = this;
    vm.reservation_enabled = true;
    vm.carnetExpirated = false;
    vm.payExpirated = false;
    vm.payment_text = "";
    
    var id = $location.search().id; 
    var user  = $localstorage.getObject('user');
    vm.dependents = $localstorage.getObject('dependents');
    vm.has_dependent = (vm.dependents.length === 0)?false:true;
    var config  = $localstorage.getObject('config');
    vm.useBuilding = config.useBuilding;

    $localstorage.setObject('activitycustom', {});

    if(config.reservationEnabled===0){
      vm.reservation_enabled = false;
      vm.notice_title = config.noticeAlertTitle;
      vm.notice_text = config.noticeAlertText;
    }else{
      vm.reservation_enabled = true;
    }

    $rootScope.user = user;
    vm.showNextDay = true;

    if(EnvConfig.show_carnet_expiration || config.validateCarnetExpiration === 1){
      if($rootScope.user.carnetExpiration && $rootScope.user.carnetExpiration !== '0000-00-00'){
        var currentDate = new Date();
        var carnetExpirationDate = new Date($rootScope.user.carnetExpiration);
        var dateDiff = carnetExpirationDate.getTime() - currentDate.getTime();
        var diffdays = Math.round(dateDiff / (1000 * 60 * 60 * 24));
        vm.carnetExpirated = diffdays < 30 ? true:false;
        vm.carnetAlreadyExpired = diffdays < 0 ? true:false;
      }
    }
    
    if(EnvConfig.show_payment_expiration){
      if($rootScope.user.payExpiration && $rootScope.user.payExpiration !== '0000-00-00'){
        var currentDatePay = new Date();
        var payExpirationDate = new Date($rootScope.user.payExpiration);
        var dateDiffPay = payExpirationDate.getTime() - currentDatePay.getTime();
        var daysPay = Math.round(dateDiffPay / (1000 * 60 * 60 * 24));
        if($rootScope.user.payMode === 'ANUAL'){
          vm.payment_text = $translate.instant('general.payExpiration_anual_message');
          vm.payExpirated = daysPay < 15 ? true:false;
        }
        if($rootScope.user.payMode === 'MENSUAL'){
          vm.payment_text = $translate.instant('general.payExpiration_mensual_message');
          vm.payExpirated = daysPay < 0 ? true:false;
        }
      }
    }
    
    $rootScope.headLander = true;
    $rootScope.titulopagina = $translate.instant("reserve.title");
   
    var days = [$translate.instant('days.sunday'), $translate.instant('days.monday'), $translate.instant('days.tuesday'), $translate.instant('days.wednesday'), $translate.instant('days.thursday'), $translate.instant('days.friday'), $translate.instant('days.saturday')];
    var months = [$translate.instant('months.january'), $translate.instant('months.february'), $translate.instant('months.march'), $translate.instant('months.april'), $translate.instant('months.may'), $translate.instant('months.june'), $translate.instant('months.july'),$translate.instant('months.august'),$translate.instant('months.september'),$translate.instant('months.october'),$translate.instant('months.november'),$translate.instant('months.december')];
    var d = new Date();
    
    function unique(value, index, self){
      return self.indexOf(value) === index;
    }

    vm.sendNotification = function(id,action){
      var data = {"type":"email","action":action,"reservationId":id};
      Request.post('notification/',true,data,function() { });

    };
    
    vm.daycount = 0;

    vm.changeDate = function(option){
      if(option==='ADD'){
        vm.daycount+=1;
        d.setDate(d.getDate() + 1);
      }else{
        vm.daycount-=1;
        d.setDate(d.getDate() - 1);
      }
      if(vm.daycount>0){
        vm.showNextDay = false;
      }else if(vm.daycount===0){
        vm.showNextDay = true;
      }
      
      
      if (vm.maxActiveResFlag>0){
        if(d.getDay()===vm.dayStartRes){
          vm.showNextDay = false;
        }else{
          vm.showNextDay = true;
        }
      }else{
        // para habilitar siguiente día desde el viernes hasta el lunes.
        if(d.getDay()===6 || d.getDay()===0){
          vm.showNextDay = true;
        }
      }

    };

    vm.load = function(){
      // var inicio=new Date("2020-08-17 23:59:59");
      // if(d<=inicio){
        // vm.reservation_enabled=false;
      // }else{
        // vm.reservation_enabled=true;
      // }
      var dayName = days[d.getDay()];
      var dayNumber = d.getDate();

      var monthName = months[d.getMonth()];
      
      vm.day=dayName +" "+ dayNumber+ " " + $translate.instant("general.of") + " "+ monthName;

      var params = "?id="+id+"&dow="+d.getDay()+"&userId="+$rootScope.user.id;
      Request.get('activitytime/'+params,true,function(response) {
        if(response.status !== 0){
          // vm.errorMsg = $translate.instant(response.description);
          var res = response.description.substring(0, 4);
          var prefix = (res==="api.")?res:"";
          toaster.pop('error', "", $translate.instant(prefix+response.description));
        }else{
          $localstorage.setObject('config',response.config);
          var config  = $localstorage.getObject('config');
          if(config.reservationEnabled===0){
            vm.reservation_enabled = false;
            vm.notice_title = config.noticeAlertTitle;
            vm.notice_text = config.noticeAlertText;
          }

          vm.activities = response.description;

          vm.filters = response.filters;
          
          var categories = vm.activities.map(function(item) {
            return item.CategoryName;
          });
          var filter_act = vm.activities.map(function(item) {
            return item.name;
          });
          var listbuildings = vm.activities.map(function(item) {
            return item.buildname;
          });
          
          vm.maxActiveResFlag = response.maxActiveReservation;
          if (vm.maxActiveResFlag>0){
            vm.dayStartRes = response.maxActiveReservationStartDay;
          }else{
            vm.dayStartRes = 0;
          }
          vm.categories = categories.filter(unique);
          vm.categories.sort();
          vm.filter_act = filter_act.filter(unique);
          vm.filter_act.sort();
          vm.listbuildings = listbuildings.filter(unique);
          vm.listbuildings.sort();
          vm.no_activities = (vm.activities.length === 0)?true:false;
          
          vm.show_selectCat = (vm.categories.length>1)?true:false;
          vm.show_selectAct = (vm.filter_act.length>1)?true:false;
          vm.show_selectBuild = (vm.listbuildings.length>1)?true:false;
          
          if (vm.maxActiveResFlag>0){
            if(d.getDay()===vm.dayStartRes){
              vm.showNextDay = false;
            }else{
              vm.showNextDay = true;
            }
          }else{
            // para habilitar siguiente día desde el viernes hasta el lunes.
            if(d.getDay()===6 || d.getDay()===0){
              vm.showNextDay = true;
            }
          }

        }
      });
    };

    vm.reservation = function (id,at,day,desc) {
      
      var data = {"usr":id,"at":at,"day":day,"description":desc};

      Request.post('reservation/',true,data,function(response) {
        if(response.status !== 0){
          var res = response.description.substring(0, 4);
          var prefix = (res==="api.")?res:"";
          toaster.pop('error', "", $translate.instant(prefix+response.description));
        }else{
          toaster.pop('success', "", $translate.instant("reserve.success"));
          vm.load();
          vm.sendNotification(response.description.id,'confirm');

          //if payment post to payment page
          // window.open('payment');
          ////
        }
      });
    };

    vm.cancelReservation = function (id) {
      Request.delete('reservation/?id='+id,true,function(response) {
        if(response.status !== 0){
          var res = response.description.substring(0, 4);
          var prefix = (res==="api.")?res:"";
          toaster.pop('error', "", $translate.instant(prefix+response.description));
          // toaster.pop('error', "", $translate.instant('api.'+response.description));
        }else{
          toaster.pop('success', "", $translate.instant("reserve.canceled_ok"));
          vm.load();
          vm.sendNotification(id,'cancel');
        }
      });
    };

    vm.load();
    

    // MODAL THINGS
    vm.animationsEnabled = true;
    
    vm.open = function (size, parentSelector) {      
      var modalInstance;
      var parentElem = parentSelector ? 
        angular.element($document[0].querySelector('.modal-demo ' + parentSelector)) : undefined;
        if(vm.activity.typeReservation==="CUSTOM"){
          //Enviar a page
          vm.activity.day = vm.day;
          vm.activity.actualday = d;
          vm.activity.moredays = vm.moredays;
          vm.activity.actCategoryId = id;
          $localstorage.setObject('activitycustom', vm.activity);
          $state.go('reservaactividadcustom',{activity:vm.activity});
          return;
        }else{
          modalInstance = $uibModal.open({
              animation: vm.animationsEnabled,
              ariaLabelledBy: 'modal-title',
              ariaDescribedBy: 'modal-body',
              templateUrl: 'confirmareserva.html',
              controller: 'ModalReservaController',
              controllerAs: 'modalCtrl',
              size: size,
              backdrop: 'static',
              keyboard: false,
              appendTo: parentElem,
              resolve: {
                title: function () {
                  return vm.title;
                },
                day: function () {
                  return vm.day;
                },
                dependents: function () {
                  return vm.dependents;
                },
                action: function () {
                  return vm.action;
                },
                activity: function () {
                  return vm.activity;
                }
            }
          });
        }
        
      
  
      modalInstance.result.then(function (result) {
          if(result.status === 'OK'){
            vm.activity = result.activity;
            switch (vm.action) {
              case 'PARENT':
                vm.reservation(vm.id,vm.at,vm.moredays,vm.activity.guests);  
              break;
              case 'DEPENDENT':
                vm.reservation(result.dependent,vm.at,vm.moredays,vm.activity.guests);
              break;
              case 'CANCEL':
                vm.cancelReservation(vm.activity.reservationId);
              break;
            }
          }
      }, function () {
        // $log.info('Modal dismissed at: ' + new Date());
      });
    };

    vm.openVirtual = function (size, parentSelector) {
      var parentElem = parentSelector ? angular.element($document[0].querySelector('.modal-demo ' + parentSelector)) : undefined;
      var modalInstance = $uibModal.open({
        animation: vm.animationsEnabled,
        ariaLabelledBy: 'modal-title',
        ariaDescribedBy: 'modal-body',
        templateUrl: 'clasevirtual.html',
        controller: 'ModalVirtualController',
        controllerAs: 'modalCtrl',
        size: size,
        backdrop: 'static',
        keyboard: false,
        appendTo: parentElem,
        resolve: {
          title: function () {
            return vm.title;
          },
          activity: function () {
            return vm.activity;
          }
        }
      });
  
      modalInstance.result.then(function () {
        
          // if(result.status === 'OK'){
          //   vm.activity = result.activity;
          //   switch (vm.action) {
          //     case 'PARENT':
          //       vm.reservation(vm.id,vm.at,vm.moredays,vm.activity.guests);  
          //     break;
          //     case 'DEPENDENT':
          //       vm.reservation(result.dependent,vm.at,vm.moredays,vm.activity.guests);
          //     break;
          //     case 'CANCEL':
          //       vm.cancelReservation(vm.activity.reservationId);
          //     break;
          //   }
          // }
      }, function () {
        // $log.info('Modal dismissed at: ' + new Date());
      });
    };

    vm.doReservation = function(action,activity){
      vm.activity                 = activity;
      vm.activity.guests          = "";
      vm.action                   = action;
      vm.activity.typeReservation = activity.typeReservation;
      vm.at                       = vm.activity.id;
      vm.id                       = $rootScope.user.id;
      vm.moredays                 = vm.daycount;
      if(action === 'PARENT' || action === 'DEPENDENT'){
        vm.title = $translate.instant("reserve.modal_title_confirm");
      }else if(action === 'CANCEL'){
        vm.title = $translate.instant("reserve.modal_title_cancel");
      }
      
      vm.open();            
    };

    vm.showVirtual = function(activity){
      vm.title = $translate.instant("reserve.view_class");
      vm.activity = activity;
      vm.at = vm.activity.id;
      vm.id = $rootScope.user.id;
      
      vm.openVirtual();
    };

    vm.showOccupancy = function(max,total){
      if(max === -1){
        return true;
      }
      var occupancy = max-total;
      if(occupancy<1){
        return false;
      }
      return true;
    };

    vm.showInfo = function(activity, type){
      vm.activity = activity;
      vm.info_type = type;
      vm.openInfo();
    };

    vm.openInfo = function (size, parentSelector) {
      var parentElem = parentSelector ? 
        angular.element($document[0].querySelector('.modal-demo ' + parentSelector)) : undefined;
      var modalInstance = $uibModal.open({
        animation: vm.animationsEnabled,
        ariaLabelledBy: 'modal-title',
        ariaDescribedBy: 'modal-body',
        templateUrl: 'showInfo.html',
        controller: 'ModalInfoController',
        controllerAs: 'modalCtrl',
        size: size,
        backdrop: 'static',
        keyboard: false,
        appendTo: parentElem,
        resolve: {
          activity: function () {
            return vm.activity;
          },
          info_type: function () {
            return vm.info_type;
          }
        }
      });
  
      modalInstance.result.then(function () {
      }, function () {
        // $log.info('Modal dismissed at: ' + new Date());
      });
    };

    vm.addToWaiting = function(activity){
      vm.activity = activity;
      vm.title = $translate.instant("reserve.add_to_waitinglist");
      vm.openWaitingList();
    };

    vm.openWaitingList = function (size, parentSelector) {
      var parentElem = parentSelector ? 
        angular.element($document[0].querySelector('.modal-demo ' + parentSelector)) : undefined;
      var modalInstance = $uibModal.open({
        animation: vm.animationsEnabled,
        ariaLabelledBy: 'modal-title',
        ariaDescribedBy: 'modal-body',
        templateUrl: 'waitingreservation.html',
        controller: 'ModalWaitingController',
        controllerAs: 'modalCtrl',
        size: size,
        backdrop: 'static',
        keyboard: false,
        appendTo: parentElem,
        resolve: {
          title: function () {
            return vm.title;
          },
          day: function () {
            return vm.day;
          },
          user: function () {
            return $rootScope.user;
          },
          members: function () {
            return vm.dependents;
          },
          activity: function () {
            return vm.activity;
          }
        }
      });
  
      modalInstance.result.then(function (result) {
          if(result.status === 'OK'){
            vm.activity = result.activity;
            vm.member = result.member;
            if(vm.member && vm.activity){

              var data = {"usr":vm.member,"at":vm.activity.id,"day":vm.daycount};

              Request.post('waitinglist/',true,data,function(response) {
                if(response.status !== 0){
                  toaster.pop('error', "", $translate.instant('api.'+response.description));
                }else{
                  toaster.pop('success', "", $translate.instant("reserve.waitinglist_added"));
                }
              });
            }
          }
      }, function () {
        // $log.info('Modal dismissed at: ' + new Date());
      });
    };

    vm.openGuests = function (size, parentSelector) {
      var parentElem = parentSelector ? 
        angular.element($document[0].querySelector('.modal-demo ' + parentSelector)) : undefined;
      var modalInstance = $uibModal.open({
        animation: vm.animationsEnabled,
        ariaLabelledBy: 'modal-title',
        ariaDescribedBy: 'modal-body',
        templateUrl: 'guests.html',
        controller: 'ModalEditGuestsController',
        controllerAs: 'modalCtrl',
        size: size,
        backdrop: 'static',
        keyboard: false,
        appendTo: parentElem,
        resolve: {
          activity: function () {
            return vm.activity;
          }
        }
      });
  
      modalInstance.result.then(function () {
      }, function () {
        // $log.info('Modal dismissed at: ' + new Date());
      });
    };

    vm.editGuests = function(activity){
      if(activity.id){
        Request.get('reservation/?id='+activity.reservationId,true,function(response) {
          var reservation = response.description;
          if(angular.isString(reservation.description)){
            activity.guests = reservation.description.split(',');
          }
          activity.editGuests = true;
          activity.day = vm.day;
          vm.activity = activity;
          vm.openGuests();
        });
      }
    };

  }]);

  angular.module('ufcw').controller('ModalReservaController', ["$uibModalInstance", "$log", "title", "day", "dependents", "action", "activity", "toaster", "$translate", "Request", "$localstorage", function ($uibModalInstance,$log,title,day,dependents,action,activity,toaster,$translate,Request,$localstorage) {

    var vm = this;    
    vm.title = title;
    vm.activity = activity;
    vm.day = day;
    vm.dependents = dependents;
    vm.action = action;
    vm.guest = [];
    vm.error_guest = [];
    vm.isDisabled = false;

    var d = new Date(vm.activity.reservationdate);
    var hours = d.getHours().toString(); 
    var minutes = d.getMinutes().toString(); 
    hours = (hours.length===1)?"0"+hours:hours;
    minutes = (minutes.length===1)?minutes+"0":minutes;
    vm.activity.start_custom = hours + ":" + minutes;
    
    var config  = $localstorage.getObject('config');
    vm.useBuilding = config.useBuilding;

    vm.ok = function () {
      try {
        if(vm.action === 'DEPENDENT' && !vm.selDependent){
          throw $translate.instant("reserve.must_select_dependent");
        }
        
        if(vm.guest.length > 0){
          vm.activity.guests = vm.guest.toString();
        }

        if(!vm.activity.reservationId && vm.activity.validateGuest===1){
          Request.get('user/?guests='+vm.guest.toString(),true,function(response) {
            if(response.code === "ERROR"){
              vm.error_guest = response.description;
              toaster.pop('error', "", $translate.instant("reserve.invalid_guest"));
            }else{
              $uibModalInstance.close({dependent: vm.selDependent, activity:vm.activity, status: 'OK'});
            }
          });
        }else{
          $uibModalInstance.close({dependent: vm.selDependent, activity:vm.activity, status: 'OK'});
        }

      } catch (error) {
        toaster.pop('error', "", error);
        vm.isDisabled = false;
      }
    };
  
    vm.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  }]);

  angular.module('ufcw').controller('ModalWaitingController', ["$uibModalInstance", "$log", "title", "day", "user", "members", "activity", "toaster", function ($uibModalInstance,$log,title,day,user,members,activity,toaster) {
    var vm = this;
    
    vm.title = title;
    vm.activity = activity;
    vm.day = day;
    vm.user = user;
    vm.members = members;
    vm.has_dependent = (vm.members.length === 0)?false:true;

    vm.ok = function () {
      try {
        if(!vm.member){
          vm.member = vm.user.id;
        }
        $uibModalInstance.close({member: vm.member, activity:vm.activity, status: 'OK'});
      } catch (error) {
        toaster.pop('error', "", error);
      }
    };
  
    vm.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  }]);

  angular.module('ufcw').controller('ModalInfoController', ["$uibModalInstance", "$log", "activity", "info_type", "toaster", function ($uibModalInstance,$log,activity,info_type,toaster) {
    var vm = this;
    
    vm.activity = activity;
    vm.info_type = info_type;
    
    
    vm.ok = function () {
      try {
        $uibModalInstance.close({status: 'OK'});
      } catch (error) {
        toaster.pop('error', "", error);
      }
    };
  
    vm.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  }]);

  angular.module('ufcw').controller('ModalVirtualController', ["$uibModalInstance", "$log", "title", "activity", function ($uibModalInstance,$log,title,activity) {
    var vm = this;
    
    vm.title = title;
    vm.activity = activity;
  
    

    vm.ok = function () {
      // try {
      //   if(vm.action === 'DEPENDENT' && !vm.selDependent){
      //     throw "Debe seleccionar un familiar";
      //   }
      //   if(vm.inv1){
      //     vm.activity.guests += ""+vm.inv1;
      //   }
      //   if(vm.inv2){
      //     vm.activity.guests += ", "+vm.inv2;
      //   }
      //   if(vm.inv3){
      //     vm.activity.guests += ", "+vm.inv3;
      //   }
      //   $uibModalInstance.close({dependent: vm.selDependent, activity:vm.activity, status: 'OK'});
      // } catch (error) {
      //   toaster.pop('error', "", error);
      // }
    };
  
    vm.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  }]);
  angular.module('ufcw').component('modalComponent', {
    templateUrl: 'clasevirtual.html',
    bindings: {
      resolve: '<',
      close: '&',
      dismiss: '&'
    },
    controller: function () {
      var vm = this;
  
      vm.$onInit = function () {
        vm.title = vm.resolve.title;
        vm.activity = vm.resolve.activity;
        vm.day = vm.resolve.day;
        vm.dependents = vm.resolve.dependents;
        vm.action = vm.resolve.action;
      };
  
      vm.ok = function () {
        vm.close({$value: 'OK'});
      };
  
      vm.cancel = function () {
        vm.dismiss({$value: 'cancel'});
      };
    }
  });


  

  angular.module('ufcw').component('modalComponent', {
    templateUrl: 'confirmareserva.html',
    bindings: {
      resolve: '<',
      close: '&',
      dismiss: '&'
    },
    controller: function () {
      var vm = this;
  
      vm.$onInit = function () {
        vm.title = vm.resolve.title;
        vm.activity = vm.resolve.activity;
        vm.day = vm.resolve.day;
        vm.dependents = vm.resolve.dependents;
        vm.action = vm.resolve.action;
      };
  
      vm.ok = function () {
        vm.close({$value: 'OK'});
      };
  
      vm.cancel = function () {
        vm.dismiss({$value: 'cancel'});
      };
    }
  });

  angular.module('ufcw').component('modalComponent', {
    templateUrl: 'waitingreservation.html',
    bindings: {
      resolve: '<',
      close: '&',
      dismiss: '&'
    },
    controller: function () {
      var vm = this;
  
      vm.$onInit = function () {
        vm.title = vm.resolve.title;
        vm.activity = vm.resolve.activity;
        vm.day = vm.resolve.day;
        vm.user = vm.resolve.user;
        vm.members = vm.resolve.members;
      };
  
      vm.ok = function () {
        vm.close({$value: 'OK'});
      };
  
      vm.cancel = function () {
        vm.dismiss({$value: 'cancel'});
      };
    }
  });

  angular.module('ufcw').component('modalComponent', {
    templateUrl: 'showInfo.html',
    bindings: {
      resolve: '<',
      close: '&',
      dismiss: '&'
    },
    controller: function () {
      var vm = this;
  
      vm.$onInit = function () {
        vm.activity = vm.resolve.activity;
        vm.info_type = vm.resolve.info_type;
      };
  
      vm.ok = function () {
        vm.close({$value: 'OK'});
      };
  
      vm.cancel = function () {
        vm.dismiss({$value: 'cancel'});
      };
    }
  });

  // angular.module('ufcw').controller('ModalInfoController', function ($uibModalInstance,$log,activity,toaster) {
  //   var vm = this;
    
  //   vm.activity = activity;
    
  //   vm.ok = function () {
  //     try {
  //       $uibModalInstance.close({status: 'OK'});
  //     } catch (error) {
  //       toaster.pop('error', "", error);
  //     }
  //   };
  
  //   vm.cancel = function () {
  //     $uibModalInstance.dismiss('cancel');
  //   };
  // });

  // angular.module('ufcw').component('modalComponent', {
  //   templateUrl: 'showInfo.html',
  //   bindings: {
  //     resolve: '<',
  //     close: '&',
  //     dismiss: '&'
  //   },
  //   controller: function () {
  //     var vm = this;
  
  //     vm.$onInit = function () {
  //       vm.activity = vm.resolve.activity;
  //     };
  
  //     vm.ok = function () {
  //       vm.close({$value: 'OK'});
  //     };
  //   }
  // });

  angular.module('ufcw').controller('ModalWaitingController', ["$uibModalInstance", "$log", "title", "day", "user", "members", "activity", "toaster", function ($uibModalInstance,$log,title,day,user,members,activity,toaster) {
    var vm = this;
    
    vm.title = title;
    vm.activity = activity;
    vm.day = day;
    vm.user = user;
    vm.members = members;
    vm.has_dependent = (vm.members.length === 0)?false:true;

    vm.ok = function () {
      try {
        if(!vm.member){
          vm.member = vm.user.id;
        }
        $uibModalInstance.close({member: vm.member, activity:vm.activity, status: 'OK'});
      } catch (error) {
        toaster.pop('error', "", error);
      }
    };
  
    vm.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  }]);

  angular.module('ufcw').component('modalComponent', {
    templateUrl: 'waitingreservation.html',
    bindings: {
      resolve: '<',
      close: '&',
      dismiss: '&'
    },
    controller: function () {
      var vm = this;
  
      vm.$onInit = function () {
        vm.title = vm.resolve.title;
        vm.activity = vm.resolve.activity;
        vm.day = vm.resolve.day;
        vm.user = vm.resolve.user;
        vm.members = vm.resolve.members;
      };
  
      vm.ok = function () {
        vm.close({$value: 'OK'});
      };
  
      vm.cancel = function () {
        vm.dismiss({$value: 'cancel'});
      };
    }
  });

  angular.module('ufcw').controller('ModalEditGuestsController', ["$uibModalInstance", "$log", "Request", "toaster", "$translate", "activity", function ($uibModalInstance,$log,Request,toaster,$translate,activity) {
    var vm = this;
    
    vm.activity = activity;
    vm.guests = activity.guests;
    
    vm.action = $translate.instant("general.view_action");
    
    if(vm.activity.editGuests){
      vm.action = $translate.instant("general.edit_action");
    }
    // vm.activity.typeReservation === 'CUSTOM' && 
    if(vm.activity.guestQty === 0 && vm.guests.length > 0){
      vm.activity.guestQty = vm.guests.length;
    }

    if(!activity.reservationId){
      activity.reservationId = activity.id;
    }

    vm.ok = function () {
      if(vm.activity.editGuests){
        if(vm.activity.validateGuest===1){
          Request.get('user/?guests='+vm.guests.toString(),true,function(response) {
            if(response.code === "ERROR"){
              vm.error_guest = response.description;
              toaster.pop('error', "", $translate.instant("reserve.invalid_guest"));
            }else{
              vm.updateReservation(vm.activity.reservationId,vm.guests);
              $uibModalInstance.close({status: 'OK'});
            }
          });
        }else{
          vm.updateReservation(vm.activity.reservationId,vm.guests);
          $uibModalInstance.close({status: 'OK'});
        }
      }
    };
  
    vm.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };

    vm.updateReservation = function(id,guests){

      var data = {description: guests.toString()};
      Request.put('reservation/?id='+id,true,data,function(response) {
        if(response.status !== 0){
          toaster.pop('error', "", $translate.instant('api.'+response.description));
        }else{
          toaster.pop('success', "", $translate.instant("reserve.reservation_updated"));
        }
      });

    };

  }]);

  angular.module('ufcw').component('modalComponent', {
    templateUrl: 'guests.html',
    bindings: {
      resolve: '<',
      close: '&',
      dismiss: '&'
    },
    controller: function () {
      var vm = this;
  
      vm.$onInit = function () {
        vm.activity = vm.resolve.activity;
      };
  
      vm.ok = function () {
        vm.close({$value: 'OK'});
      };
  
      vm.cancel = function () {
        vm.dismiss({$value: 'cancel'});
      };
    }
  });
"use strict";

angular
  .module("ufcw")
  .controller(
    "ReservaactividadcustomController",
    ["$rootScope", "$log", "$location", "Request", "$localstorage", "$uibModal", "$document", "$state", "toaster", "$translate", function (
      $rootScope,
      $log,
      $location,
      Request,
      $localstorage,
      $uibModal,
      $document,
      $state,
      toaster,
      $translate
    ) {
      var vm = this;
      $rootScope.headLander = true;
      $rootScope.titulopagina = $translate.instant("reserve.title");

      vm.reservation_enabled = true;
      vm.carnetExpirated = false;
      vm.payExpirated = false;
      vm.payment_text = "";
      vm.inittimeshow = "";
      
      vm.guest_control = "OK";

      vm.activity = $localstorage.getObject("activitycustom");
      var id = vm.activity.id || $state.params.activity.id;

      if (!id) {
        $state.go("home");
      }

      vm.moredays = vm.activity.moredays;
      vm.actualday = vm.activity.actualday;
      vm.day = vm.activity.day;

      vm.type_activity = "";
      vm.time_activity = "";

      vm.guestQty = 0;
      vm.customers_guests = [];
      vm.all_customers = [];
      vm.guest = [];
      vm.ci_valid = [];
      vm.only_customer = false;
      vm.customer_search = [];
      vm.searchActive = [];

      var user = $localstorage.getObject("user");
      vm.dependents = $localstorage.getObject("dependents");
      vm.has_dependent = vm.dependents.length === 0 ? false : true;
      var config = $localstorage.getObject("config");
      vm.useBuilding = config.useBuilding;
      if (config.reservationEnabled === 0) {
        vm.reservation_enabled = false;
        vm.notice_title = config.noticeAlertTitle;
        vm.notice_text = config.noticeAlertText;
      } else {
        vm.reservation_enabled = true;
      }

      $rootScope.user = user;
      vm.showNextDay = true;

      vm.sendNotification = function (id, action) {
        var data = { type: "email", action: action, reservationId: id };
        Request.post("notification/", true, data, function () {});
      };

      vm.load_activitytime_custom = function () {
        Request.get("activitytimecustom/", true, function (response) {
          if (response.status !== 0) {
            vm.errorMsg = $translate.instant(response.description);
          } else {
            vm.type_activity_list = response.description;
          }
        });
      };

      vm.getCustomersGuests = function(){
        Request.get("user/?searchBy=name&name=gonza", true, function (response) {
          if (response.status !== 0) {
            vm.errorMsg = $translate.instant(response.description);
          } else {
            vm.customers_guests[0] = response.description;
          }
        });
      };
      
      vm.changeTimeCustom = function () {
        vm.guestQty = 0;
        vm.guest = [];
        vm.ci_valid = [];

        var item = vm.type_activity_list.filter(function (item) {
          if (item.id === vm.type_activity) {
            return item;
          }
        });

        var retorno = [];
        var index = item[0].mintime;
        while (index <= item[0].maxtime) {
          retorno.push(index);
          index += 30;
        }

        vm.ci_valid = [];
        vm.searchActive = [];
        for (var i = 0; i < item[0].guestsqts; i++) {
          vm.ci_valid[i] = true;
          vm.searchActive[i] = true;
        }

        vm.options_time_custom = retorno;
        vm.time_activity = retorno[0];
      };

      vm.daycount = 0;

      vm.changeInitTime = function (reservation) {
        var i = 0;
        var error = false;
        var error_msg = "Su reserva se superpone con una existente";
        for (var index = 0; index < vm.options_time.length; index++) {
          if (vm.options_time[index].time === reservation.time) {
            i = index;
            if (vm.time_activity === 60) {
              //validate if have a resevation
              if (
                vm.options_time[index].reserved !== -1 ||
                vm.options_time[index + 1].reserved !== -1
              ) {
                error = true;
              }
              //validate if have a suspended
              if (
                vm.options_time[index].suspended !== -1 ||
                vm.options_time[index + 1].suspended !== -1
              ) {
                error_msg = "Su reserva se superpone con una hora suspendida";
                error = true;
              }
              //validate if have a block
              if (
                vm.options_time[index].block !== -1 ||
                vm.options_time[index + 1].block !== -1
              ) {
                error_msg = "Su reserva se superpone con una hora cancelada";
                error = true;
              }
            }
            if (vm.time_activity === 90) {
              if (
                vm.options_time[index].reserved !== -1 ||
                vm.options_time[index + 1].reserved !== -1 ||
                vm.options_time[index + 2].reserved !== -1
              ) {
                error = true;
              }

              if (
                vm.options_time[index].suspended !== -1 ||
                vm.options_time[index + 1].suspended !== -1 ||
                vm.options_time[index + 2].suspended !== -1
              ) {
                error_msg = "Su reserva se superpone con una hora suspendida";
                error = true;
              }

              if (
                vm.options_time[index].block !== -1 ||
                vm.options_time[index + 1].block !== -1 ||
                vm.options_time[index + 2].block !== -1
              ) {
                error_msg = "Su reserva se superpone con una hora bloqueada";
                error = true;
              }
            }
          } else {
            vm.options_time[index].selected = false;
          }
        }
        if (error) {
          toaster.pop("error", "", error_msg);
          return;
        }

        if (vm.time_activity === 60) {
          vm.options_time[i].selected = true;
          vm.options_time[i + 1].selected = true;
        }
        if (vm.time_activity === 90) {
          vm.options_time[i].selected = true;
          vm.options_time[i + 1].selected = true;
          vm.options_time[i + 2].selected = true;
        }

        if (reservation.reserved === -1) {
          var date_init = new Date(reservation.time);
          var date_format = date_init.getFullYear() + "-" + (date_init.getMonth() + 1) + "-" + date_init.getDate() + " " + date_init.getHours() + ":" + date_init.getMinutes() + ":00";
          vm.inittimeshow = date_init.getHours() + ":" + date_init.getMinutes();
          vm.inittime = date_format;
        } else {
          vm.inittime = null;
        }
      };

      vm.load = function () {
        
        Request.get(
          "reservationcustom/?activityId=" + id + "&date=" + vm.actualday,
          true,
          function (response) {
            if (response.status !== 0) {
              //  $state.go("signin");
            } else {
              vm.reservations = response.description.reservations;
              vm.suspended = response.description.activitycanceled;
              vm.activity_sel = response.description.activity;

              //create array reservations times
              var reservations_time = [];
              var my_reservations = [];
              var my_reservations_id = [];
              var suspended_time = [];
              var block_time = [];

              //process all reservation
              vm.reservations.forEach(function (val) {
                var starttime_reservation = new Date(val.reservationdate);

                //add starttime
                reservations_time.push(starttime_reservation.getTime());

                if (val.userId === $rootScope.user.id) {
                  my_reservations.push(starttime_reservation.getTime());
                  my_reservations_id.push({
                    time: starttime_reservation.getTime(),
                    id: val.id,
                  });
                }

                //add first hour
                if (val.reservationtime === 60 || val.reservationtime === 90) {
                  reservations_time.push(
                    starttime_reservation.setMinutes(
                      starttime_reservation.getMinutes() + 30
                    )
                  );
                  if (val.userId === $rootScope.user.id) {
                    my_reservations.push(starttime_reservation.getTime());
                    my_reservations_id.push({
                      time: starttime_reservation.getTime(),
                      id: val.id,
                    });
                  }
                }

                //add second hour
                if (val.reservationtime === 90) {
                  reservations_time.push(
                    starttime_reservation.setMinutes(
                      starttime_reservation.getMinutes() + 30
                    )
                  );
                  if (val.userId === $rootScope.user.id) {
                    my_reservations.push(starttime_reservation.getTime());
                    my_reservations_id.push({
                      time: starttime_reservation.getTime(),
                      id: val.id,
                    });
                  }
                }
              });

              var today = new Date(vm.actualday);
              var starttime_suspended = null;
              var endtime_suspended = null;

              
              vm.suspended.forEach(function (val) {
                if (val.hours) {
                  if (val.hours === "[]") {
                    // all hours
                    starttime_suspended = new Date(today.getFullYear() +"-" +(today.getMonth() + 1) + "-" + today.getDate() + " " + vm.activity_sel.starttime);
                    endtime_suspended = new Date(today.getFullYear() +"-" +(today.getMonth() + 1) + "-" + today.getDate() + " " + vm.activity_sel.endtime);

                    while ( starttime_suspended.getTime() < endtime_suspended.getTime()) {
                      if (val.status === "BLOCK") {
                        block_time.push({time:starttime_suspended.getTime(), reason: val.reason});
                      } else {
                        suspended_time.push({time:starttime_suspended.getTime(), reason: val.reason});
                      }

                      starttime_suspended.setMinutes(starttime_suspended.getMinutes() + 30);
                    }
                  } else {
                    var hours = angular.fromJson(val.hours);
                    
                    hours.forEach(function (periods) {
                      starttime_suspended = new Date(today.getFullYear() +"-" +(today.getMonth() + 1) +"-" +today.getDate() +" " +periods.hour[0] +":00");
                      endtime_suspended = new Date(today.getFullYear() +"-" +(today.getMonth() + 1) + "-" + today.getDate() + " " + periods.hour[1] + ":00");

                      while (starttime_suspended.getTime() < endtime_suspended.getTime()) {
                        if (val.status === "BLOCK") {
                          block_time.push({time:starttime_suspended.getTime(), reason: periods.reason});
                      } else {
                        suspended_time.push({time:starttime_suspended.getTime(), reason: periods.reason});
                        }
                        starttime_suspended.setMinutes(starttime_suspended.getMinutes() + 30);
                      }
                    });
                  }
                }
              });

              var stime = vm.moredays === 0 ? today.getMinutes() > 29 ? today.getHours() + 1 + ":00" : today.getHours() + ":30" : vm.activity.starttime;
              var starttime = new Date(today.getFullYear() +"-" +(today.getMonth() + 1) +"-" +today.getDate() +" " +stime);
              var endtime = new Date(today.getFullYear() +"-" +(today.getMonth() + 1) +"-" +today.getDate() +" " +vm.activity.endtime);
              var time_diff = endtime.getTime() - starttime.getTime();
              var time_diff_minutes = parseInt(Math.abs(time_diff)) / (1000 * 60);
              var options_limit = parseInt(time_diff_minutes / 30) - 1;
              var options = [];
              var temp_startime2 = starttime.getTime();
              var reservationId2 = my_reservations_id.filter(function (item) {
                if (item.time === temp_startime2) {
                  return item;
                }
              });
              
              var susptime_index = suspended_time.map(function(o) { return o.time; }).indexOf(temp_startime2);
              var blocktime_index = block_time.map(function(o) { return o.time; }).indexOf(temp_startime2);
              options.push({
                id: reservationId2.length !== 0 ? reservationId2[0].id : 0,
                time: temp_startime2,
                reserved: reservations_time.indexOf(temp_startime2),
                // suspended: suspended_time.indexOf(temp_startime2),
                suspended: susptime_index,
                susp_reason: (susptime_index!==-1)?suspended_time[susptime_index]['reason']:'',
                block: blocktime_index,
                block_reason: (blocktime_index!==-1)?block_time[blocktime_index]['reason']:'',
                my: my_reservations.indexOf(temp_startime2),
                selected: false,
              });

              for (var index = 0; index <= options_limit; index++) {
                var temp_startime = starttime.setMinutes(starttime.getMinutes() + 30);
                var reservationId = my_reservations_id.filter(function (item) {
                  if (item.time === temp_startime) {
                    return item;
                  }
                });
                
                susptime_index = suspended_time.map(function(o) { return o.time; }).indexOf(temp_startime);
                blocktime_index = block_time.map(function(o) { return o.time; }).indexOf(temp_startime);
                options.push({
                  id: reservationId.length !== 0 ? reservationId[0].id : 0,
                  time: temp_startime,
                  reserved: reservations_time.indexOf(temp_startime),
                  // suspended: suspended_time.indexOf(temp_startime),
                  suspended: susptime_index,
                  susp_reason: (susptime_index!==-1)?suspended_time[susptime_index]['reason']:'',
                  block: blocktime_index,
                  block_reason: (blocktime_index!==-1)?block_time[blocktime_index]['reason']:'',
                  my: my_reservations.indexOf(temp_startime),
                  selected: false,
                });
              }
              vm.options_time = options;
            }
          }
        );
      };

      vm.reservation = function (id, at, day, desc, inittime, type_activity) {
        var data = {
          usr: id,
          at: at,
          day: day,
          description: desc,
          inittime: inittime,
          type: type_activity,
        };
        $log.log("DATA", data);
        
        Request.post("reservationcustom/", true, data, function (response) {
          if (response.status !== 0) {
            var res = response.description.substring(0, 4);
            var prefix = res === "api." ? res : "";
            toaster.pop(
              "error",
              "",
              $translate.instant(prefix + response.description)
            );
          } else {
            toaster.pop("success", "", $translate.instant("reserve.success"));
            vm.load();
            vm.sendNotification(response.description.id, "confirm");
            $state.go("home");
          }
        });
      };

      vm.cancelReservation = function (id) {
        Request.delete(
          "reservationcustom/?id=" + id,
          true,
          function (response) {
            if (response.status !== 0) {
              var res = response.description.substring(0, 4);
              var prefix = res === "api." ? res : "";
              toaster.pop(
                "error",
                "",
                $translate.instant(prefix + response.description)
              );
            } else {
              toaster.pop(
                "success",
                "",
                $translate.instant("reserve.canceled_ok")
              );
              vm.load();
              $state.go("home");
            }
          }
        );
      };

      vm.load_activitytime_custom();
      
      // MODAL THINGS
      vm.animationsEnabled = true;

      vm.open = function (size, parentSelector) {
        var modalInstance;
        var parentElem = parentSelector ? angular.element( $document[0].querySelector(".modal-demo " + parentSelector)): undefined;
        modalInstance = $uibModal.open({
          animation: vm.animationsEnabled,
          ariaLabelledBy: "modal-title",
          ariaDescribedBy: "modal-body",
          templateUrl: "confirmareservacustom.html",
          controller: "ModalReservaCustomController",
          controllerAs: "modalCtrl",
          size: size,
          backdrop: "static",
          keyboard: false,
          appendTo: parentElem,
          resolve: {
            title: function () {
              return vm.title;
            },
            day: function () {
              return vm.day;
            },
            dependents: function () {
              return vm.dependents;
            },
            action: function () {
              return vm.action;
            },
            activity: function () {
              return vm.activity;
            },
            inittime: function () {
              return vm.inittime;
            },
            time_activity: function () {
              return vm.time_activity;
            },
            type_activity: function () {
              return vm.type_activity;
            },
            guests: function () {
              return vm.guest;
            },
          },
        });

        modalInstance.result.then(
          function (result) {
            if (result.status === "OK") {
              vm.activity = result.activity;
              // var guests = vm.guest.map(function(item){return item.id});
              switch (vm.action) {
                case "PARENT":
                  vm.reservation(
                    vm.id,
                    vm.at,
                    vm.moredays,
                    vm.guest.toString(),
                    vm.inittime,
                    vm.time_activity
                  );
                  break;
                case "DEPENDENT":
                  vm.reservation(
                    result.dependent,
                    vm.at,
                    vm.moredays,
                    vm.guest.toString()
                  );
                  break;
                case "CANCEL":
                  vm.cancelReservation(vm.reservationId);
                  break;
              }
            } else {
              vm.options_time.map(function (item) {
                item.selected = false;
              });
            }
          },
          function () {}
        );
      };

      vm.gridenable = function (type_activity, time_activity) {
        if (type_activity !== "" && time_activity !== "") {
          vm.guestQty = vm.getActivityCustom(type_activity).guestsqts;
          vm.load();
        }
      };

      vm.getActivityCustom = function (activity) {
        var ret = vm.type_activity_list.filter(function (item) {
          if (item.id === activity) {
            return item;
          }
        });
        return ret[0];
      };

      vm.checkGuests = function (guests) {
        Request.get("user/?guests=" + guests.toString(), true, function (response) {
            if (response.code === "ERROR") {
              return false;
            } else {
              return true;
            }
          }
        );
      };

      vm.doReservation = function (
        action,
        activity,
        inittime,
        type_activity,
        time_activity,
        reservationId,
        guests
      ) {
        if (action === "CANCEL") {
          var itime;
          vm.options_time.map(function (item) {
            if (item.id === reservationId && !itime) {
              itime = item.time;
            }
          });
          vm.inittime = itime;
          vm.reservationId = reservationId;
          vm.action = action;
          vm.title = $translate.instant("reserve.modal_title_cancel");
          vm.open();
        } else {
          vm.validateGuests(guests, type_activity, function (res) {
            if (res !== "OK") {
              toaster.pop("error", "", res);
              return;
            }

            if (angular.isDefined(inittime) && inittime !== null && type_activity !== "" && time_activity !== "") {
              vm.activity = activity;
              vm.guestQty = vm.getActivityCustom(type_activity).guestsqts;
              vm.reservationId = reservationId;
              vm.action = action;
              vm.activity.guestQty = vm.guestQty;
              vm.activity.typeReservation = activity.typeReservation;
              vm.at = vm.activity.id;
              vm.id = $rootScope.user.id;
              vm.moredays = vm.daycount;
              $log.log("GUESTS", vm.guests);
              $log.log("GUESTS P", guests);
              vm.title = $translate.instant("reserve.modal_title_confirm");
            } else {
              toaster.pop(
                "error",
                "",
                $translate.instant(
                  "Debe seleccionar tipo, tiempo y hora de la actividad"
                )
              );
              return;
            }
            vm.open();
          });
        }
      };

      vm.customers_guests_search = function(text,index){
        
        if(text.length > 0){
          if(text[index]!== "" && text[index].length > 2 ){

            Request.get("user/?searchBy=name&name="+text[index], true, function (response) {
              if (response.status !== 0) {
                vm.errorMsg = $translate.instant(response.description);
              } else {
                vm.customers_guests[index] = response.description;
                if(vm.customers_guests.length > 0){
                  vm.guest[index] = vm.customers_guests[index][0].id;
                } 
              }
            });
            
          }
        }
      };

      vm.validateGuests = function (guests, type_activity, cb) {
        var responseCB = "";
        Request.get(
          "user/?guests=" + guests.toString(),
          true,
          function (response) {
            if (response.code === "ERROR") {
              response.description.map(function(item){
                if(!item.exists){
                  switch (item.cause) {
                    case 'INVUSER':
                      responseCB += " El Nro. de socio o el documento ingresado no corresponde a un socio.";
                      break;
                    case 'SAMEUSER':
                      responseCB += " El Nro. de socio/documento ingresado es el suyo.";
                      break;
                    default:
                      responseCB += " " + item.cause + ".";
                      break;
                  }
                }
              });
              
            } else {
              responseCB = "OK";

              var guestQty = vm.getActivityCustom(type_activity).guestsqts;

              if (guestQty !== guests.length) {
                responseCB = "Debe ingresar el/los Nros. de socio/documentos";
              }

              var exists_null = guests.filter(function (item) {
                return item === null;
              });

              if (exists_null.length) {
                responseCB = "Debe ingresar el/los Nros. de socio/documentos";
              }

              var exists_duplicate = guests.filter(function (item, index) {
                return guests.indexOf(item) !== index;
              });

              if (exists_duplicate.length) {
                responseCB = "Hay uno o más Nros. de socio/documentos que se repiten";
              }

              //check if same documents of current user
              var exists_same_doc = guests.filter(function (item) {
                return $rootScope.user.identificationNumber === item;
              });

              if (exists_same_doc.length) {
                responseCB = "El Nro. de socio/documento ingresado es el suyo";
              }
            }
            return cb(responseCB);
          }
        );
      };
    }]
  );

angular
  .module("ufcw")
  .controller(
    "ModalReservaCustomController",
    ["$uibModalInstance", "$log", "title", "day", "dependents", "action", "activity", "time_activity", "type_activity", "inittime", "guests", "toaster", "$translate", "Request", "$localstorage", function (
      $uibModalInstance,
      $log,
      title,
      day,
      dependents,
      action,
      activity,
      time_activity,
      type_activity,
      inittime,
      guests,
      toaster,
      $translate,
      Request,
      $localstorage
    ) {
      var vm = this;
      vm.title = title;
      vm.activity = activity;
      vm.time_activity = time_activity;
      vm.type_activity = type_activity;
      vm.inittime = new Date(inittime);

      vm.day = day;
      vm.dependents = dependents;
      vm.action = action;
      vm.guests = guests;
      vm.isDisabled = false;

      var config = $localstorage.getObject("config");
      vm.useBuilding = config.useBuilding;

      vm.load_activitytime_custom = function () {
        Request.get(
          "activitytimecustom/?id=" + vm.type_activity,
          true,
          function (response) {
            if (response.status !== 0) {
              vm.errorMsg = $translate.instant(response.description);
            } else {
              vm.type_activity_label = response.description.name;
            }
          }
        );
      };
      vm.load_activitytime_custom();

      vm.ok = function () {
        try {
          if (vm.action === "DEPENDENT" && !vm.selDependent) {
            throw $translate.instant("reserve.must_select_dependent");
          }

          $uibModalInstance.close({
            dependent: vm.selDependent,
            activity: vm.activity,
            status: "OK",
          });
        } catch (error) {
          toaster.pop("error", "", error);
          vm.isDisabled = false;
        }
      };

      vm.cancel = function () {
        $uibModalInstance.close({ status: "cancel" });
      };
    }]
  );

angular.module("ufcw").component("modalComponent", {
  templateUrl: "confirmareservacustom.html",
  bindings: {
    resolve: "<",
    close: "&",
    dismiss: "&",
  },
  controller: function () {
    var vm = this;

    vm.$onInit = function () {
      vm.title = vm.resolve.title;
      vm.activity = vm.resolve.activity;
      vm.day = vm.resolve.day;
      vm.dependents = vm.resolve.dependents;
      vm.time_activity = vm.resolve.time_activity;
      vm.type_activity = vm.resolve.type_activity;
      vm.inittime = vm.resolve.inittime;
      vm.guests = vm.resolve.guests;
    };

    vm.ok = function () {
      vm.close({ $value: "OK" });
    };

    vm.cancel = function () {
      vm.dismiss({ $value: "cancel" });
    };
  },
});

'use strict';

angular.module('ufcw')
  .controller('RoutineController', ["$log", "Request", "$localstorage", "$state", "$rootScope", function ($log,Request,$localstorage,$state,$rootScope) {
    var vm = this;
    vm.errorMsg = null;
    vm.selectedActivity = {};
    vm.selectedTarget = {};
    vm.selectedType = {};
    vm.level =0; 
    
    $rootScope.headLander = false;

    var token  = $localstorage.get('token');
    vm.backPath = "/home";
    if(!token){
      vm.backPath = "/signin";
    }

    vm.selectActivity = function(id){
      // $log.log('chino');
      // vm.selectedActivity = {};
      vm.routinesActivities.map(function(item){

        if (item.id===id){
          vm.selectedActivity = item;
          vm.selectedType = {};
          vm.level =0;
        }
        
  
      });
      

    };

    vm.selectTarget = function(id){
      
      // vm.selectedActivity = {};
      vm.routinesTargets.map(function(item){

        if (item.id===id){
          // vm.selectedActivity = item;
          vm.selectedTarget = item;
          vm.selectedType = {};
          vm.level =0;
        }
        
  
      });
      

    };


    vm.selectTypes = function(id){
      // $log.log(vm.selectedActivity.types,'chino2');

      //  var hax= vm.selectedActivity.types;
      var hax= vm.selectedTarget.types;

      //  $log.log(hax,'chino2');

      hax.map(function(item){

        if (item.id===id){
          vm.selectedType = item;
          vm.level =0;
        }
        
  
      });
      

    };

   
    

    Request.get('routine/?type=1',true,function(response) {
      // vm.routinesActivities = response.description;  
      vm.routinesTargets = response.description;  
    });


    vm.showRoutine = function(id){

      // var activity = vm.selectedActivity.id;
      var target = vm.selectedTarget.id;
      var type = vm.selectedType.id;
      vm.level = id;

      // var data = {"typeId":type,"level":vm.level,"activity":activity};
      var data = {"typeId":type,"level":vm.level,"target":target};
      Request.post('routine/',true,data,function(response) {
        vm.routines = response.description;
       });
      // Request.get('routine/?type=2&activity='+activity+"&typeId="+type+"&level="+level,true,function(response) {
      //   vm.routines = response.description;  
      // });
  
      

    };
    
    //get activities for user category
    // Request.get('nooauthactivity/get/?type=cat',true,function(response) {
    //     vm.activities = response.description;
    //     vm.activities.map(function(item){
    //       item.icon = (item.icon)?EnvConfig.URL_IMAGES+item.icon:EnvConfig.URL_IMAGES+'icono_calendario.png';
    //     });
    // });

    // Request.get('routine/',true,function(response) {
    //   vm.allroutines = response.description;  
    // });
      
    vm.UNIT_MAP = {MIN: "Minutos",SEC: "Segundos",RPT: "Repeticiones",KG: "Kilos",MTS: "Metros",KM: "Kilometros"};

  }]);

  




'use strict';

angular.module('ufcw')
  .controller('SigninController', ["$rootScope", "$localstorage", "Request", "$log", "$state", "toaster", "EnvConfig", "$window", "$uibModal", "$document", "$translate", function ($rootScope,$localstorage,Request,$log,$state,toaster,EnvConfig,$window,$uibModal,$document,$translate) {
    
    $rootScope.headLander = false;
    var vm = this;
    vm.useRoutines = $localstorage.get('useRoutines');
    vm.errorMsg = null;
    $localstorage.clear();
    vm.showCanchas = EnvConfig.show_canchas;
    
    vm.signin = function(){

      if(EnvConfig.SIGNIN_ENABLED || vm.email==="gonzalo@squal.us" || vm.email==="juanto@squal.us"){
        var data = {user:vm.email,password:vm.password};

        Request.put('signin',false,data,function(response) {
          if(response.status !== 0){
            // vm.errorMsg = response.description;
            toaster.pop('error', "", $translate.instant('api.'+response.description));
            $localstorage.clear();
          }else{
            $localstorage.set('token',response.token);
            $localstorage.setObject('user',response.description);
            vm.user_access = response.description.userAccess;
            $localstorage.setObject('dependents',response.dependents);
            $localstorage.setObject('user_files',response.user_files);
            $translate.use(response.description.lang);
            $localstorage.set('showMsg',(response.configuration.showInitialMsg===1)?true:false);
            $localstorage.setObject('config',response.configuration);
            $localstorage.setObject('messages',response.messages);
            $localstorage.setObject('alerts',response.alerts);
            $localstorage.set('showLogout',true);
            $rootScope.messages = response.messages;

            vm.loadQR(response.description.id, response.description.identificationNumber);

            var validate_terms = (response.configuration.validateTerms===1)?true:false;
            if(validate_terms){
              if(response.validate_terms.status === 'VALIDATE'){
                vm.termsId = response.validate_terms.termsId;
                vm.userId = response.description.id;
                vm.terms_text = response.validate_terms.text.replaceAll('\n','<BR>');
                vm.openTerms();
              }else{
                if(vm.user_access === 'CANCHA'){
                  $state.go('homecanchas');
                }else{
                  $state.go('home');
                }  
              }
            }else{
              if(vm.user_access === 'CANCHA'){
                $state.go('homecanchas');
              }else{
                $state.go('home');
              }
            }
          }
        });


      }else{
        toaster.pop('info',"", $translate.instant("signin.soon"));
      }

      vm.openTerms = function (size, parentSelector) {
        var parentElem = parentSelector ? 
          angular.element($document[0].querySelector('.modal-demo ' + parentSelector)) : undefined;
        var modalInstance = $uibModal.open({
          animation: vm.animationsEnabled,
          ariaLabelledBy: 'modal-title',
          ariaDescribedBy: 'modal-body',
          templateUrl: 'terms.html',
          controller: 'ModalTermsController',
          controllerAs: 'modalCtrl',
          size: size,
          backdrop: 'static',
          keyboard: false,
          appendTo: parentElem,
          resolve: {
            terms_text: function () {
              return vm.terms_text;
            }
          }
        });
    
        modalInstance.result.then(function (result) {
          if(result.status === 'OK'){
            var date_agreed = new Date();
            var agreed_date = date_agreed.getFullYear() + '-' + (date_agreed.getMonth()+1) + '-' + date_agreed.getDate();
            var data = {termsId: vm.termsId, agreed: 1, date_agreed: agreed_date};
            Request.put('userterms/?user='+vm.userId,true,data,function(response) {
              if(response.status !== 0){
                $rootScope.logout();
                toaster.pop('error', "", $translate.instant('api.'+response.description));
              }else{
                if(vm.user_access === 'CANCHA'){
                  $state.go('homecanchas');
                }else{
                  $state.go('home');
                }
              }
            });
          }else{
            $rootScope.logout();
          }
        }, function () {
          // $log.info('Modal dismissed at: ' + new Date());
        });
      };

    };


    vm.loadQR = function(id, idNum){
      $localstorage.set('QRFromS3', false);
      Request.getExternal('https://squalus-public-production.s3.us-east-2.amazonaws.com/agenda/bohemios/1/'+id+'/'+idNum+'.png',false,function(response) {
        if(response.status === 200){
          $localstorage.set('QRFromS3', true);
        }
      });
    };

  }]);
  angular.module('ufcw').controller('ModalTermsController', ["$uibModalInstance", "$log", "terms_text", "toaster", function ($uibModalInstance,$log,terms_text,toaster) {
    var vm = this;
    
    vm.terms_text = terms_text;
    vm.terms = 0;

    vm.ok = function () {
      try {
        $uibModalInstance.close({status: 'OK'});
      } catch (error) {
        toaster.pop('error', "", error);
      }
    };
  
    vm.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  }]);

  angular.module('ufcw').component('modalComponent', {
    templateUrl: 'terms.html',
    bindings: {
      resolve: '<',
      close: '&',
      dismiss: '&'
    },
    controller: function () {
      var vm = this;
  
      vm.$onInit = function () {
        vm.terms_text = vm.resolve.terms_text;
      };
  
      vm.ok = function () {
        vm.close({$value: 'OK'});
      };
  
      vm.cancel = function () {
        vm.dismiss({$value: 'cancel'});
      };
    }
  });
'use strict';

angular.module('ufcw')
  .controller('SignupController', ["$rootScope", "EnvConfig", "Request", "$log", "$state", "toaster", "$translate", function ($rootScope,EnvConfig,Request,$log,$state,toaster,$translate) {
    $rootScope.headLander = false;
    var vm = this;
    vm.errorMsg = null;
    vm.user = {};
    vm.user.companyId = EnvConfig.COMPANY_ID;
    vm.option_cuponera = EnvConfig.show_cuponeras;
    vm.company_name = EnvConfig.footer_app_name;

    if (vm.company_name!=='ENFOQUE'){
      vm.tipoSocio = "socio";
    }else{
      vm.tipoSocio = "cuponera";
    }
   

    function formatDate(num){
      return (num.toString().length===1)?"0"+num:""+num;
    }
    
    vm.signup = function(){

      if(vm.tipoSocio === "cuponera"){
        vm.user.dob = vm.dob.getFullYear()+"-"+formatDate(vm.dob.getMonth()+1)+"-"+formatDate(vm.dob.getDate());  
        Request.post('signupcuponera',false,vm.user,function(response) {
          if(response.status !== 0){
            // vm.errorMsg = response.description;
            toaster.pop('error', "", $translate.instant('api.'+response.description));
          }else{
            toaster.pop('success', "", $translate.instant("signup.success"));
            $state.go('signin');
          }
        });
      }else{
        if(EnvConfig.signup_dob){
          vm.user.dob = vm.dob.getFullYear()+"-"+formatDate(vm.dob.getMonth()+1)+"-"+formatDate(vm.dob.getDate());  
        }
        Request.post('signup',false,vm.user,function(response) {
          if(response.status !== 0){
            // vm.errorMsg = response.description;
            toaster.pop('error', "", $translate.instant('api.'+response.description));
          }else{
            toaster.pop('success', "", $translate.instant("signup.success"));
            $state.go('signin');
          }
        });
      }
      
    };

  }]);

'use strict';

angular.module('ufcw')
  .controller('StartController', ["$log", "Request", "$localstorage", "$state", "$rootScope", "toaster", "$uibModal", "$document", "EnvConfig", "$window", "$translate", function ($log,Request,$localstorage,$state,$rootScope,toaster,$uibModal,$document,EnvConfig,$window,$translate) {
    var vm = this;

    $rootScope.headLander = false;
    vm.errorMsg = null;
    


    vm.loadGridItems = function(){
      Request.get('appconfiguration?id=1',false,function(response) {
        if(response.status !== 0){
          toaster.pop('info', "", $translate.instant('api.'+response.description));
        }else{
          vm.griditems = response.description.griditems;
          $log.log(vm.griditems);
        }
      });
    };

    vm.loadGridItems();

  }]);
'use strict';

angular.module('ufcw')
  .controller('WaitinglistController', ["$log", "Request", "$localstorage", "$state", "$rootScope", "toaster", "$translate", function ($log,Request,$localstorage,$state,$rootScope,toaster,$translate) {
    var vm = this;
    vm.errorMsg = null;
    var user  = $localstorage.getObject('user');

    vm.users = $localstorage.getObject('dependents');
    vm.has_dependent = (vm.users.length === 0)?false:true;
    vm.users.push(user);

    vm.from_date = new Date();
    vm.from_date.setDate(vm.from_date.getDate() - 7);
    vm.to_date   = new Date();

    $rootScope.user = user;
    $rootScope.headLander = true;
    $rootScope.titulopagina = $translate.instant("waitinglist.title");

    var STATUS_MAP = {
      ACTIVE: $translate.instant("reserve.status_active"),
      CANCELED: $translate.instant("reserve.status_canceled"),
      null: '',
      '': '',
      undefined: '',
    };

    var days = [$translate.instant('days.sunday'), $translate.instant('days.monday'), $translate.instant('days.tuesday'), $translate.instant('days.wednesday'), $translate.instant('days.thursday'), $translate.instant('days.friday'), $translate.instant('days.saturday')];
    var months = [$translate.instant('months.january'), $translate.instant('months.february'), $translate.instant('months.march'), $translate.instant('months.april'), $translate.instant('months.may'), $translate.instant('months.june'), $translate.instant('months.july'),$translate.instant('months.august'),$translate.instant('months.september'),$translate.instant('months.october'),$translate.instant('months.november'),$translate.instant('months.december')];

    vm.load = function(){
      //get next reservations for user
      var params = "";
      var from = "";
      var to   = "";
      if(vm.from_date && vm.to_date){
        if(vm.from_date > vm.to_date){
          toaster.pop('info', "", $translate.instant('La fecha DESDE no puede ser mayor a la fecha HASTA'));
          return;
        }
        from = vm.from_date.getFullYear() + "-" + (vm.from_date.getMonth()+1) + "-" + vm.from_date.getDate();
        to = vm.to_date.getFullYear() + "-" + (vm.to_date.getMonth()+1) + "-" + vm.to_date.getDate();
        params = "?from="+from+"&to="+to;
      }
      Request.get('waitinglist/'+params,true,function(response) {
        if(response.status !== 0){
          toaster.pop('info', "", $translate.instant('api.'+response.description));
        }else{
          vm.reservas = response.description;
          vm.reservas.map(function(item){
            item.status = STATUS_MAP[item.status];
            item.reservationdate = item.reservationdate.replace(/-/g, '/');
            var d = new Date(item.reservationdate);
            var dayName = days[d.getDay()];
            var dayNumber = d.getDate();
            var monthName = months[d.getMonth()];
            item.day = dayName +" "+ dayNumber+ " " + $translate.instant('general.of') + " "+ monthName;
          });
          vm.no_reservations = (vm.reservas.length === 0)?true:false;
        }
      });
    };

    vm.load();


    vm.removeItem = function(item){
      var id = item.id;
      Request.delete('waitinglist/?id='+id,true,function(response) {
        if(response.status !== 0){
          toaster.pop('info', "", $translate.instant('api.'+response.description));
        }else{
          toaster.pop('success', "", $translate.instant("waitinglist.removed"));
          vm.load();
        }
      });
    };



  //DATETIMEPICKERS

  function getDayClass(data) {
    var date = data.date,
      mode = data.mode;
    if (mode === 'day') {
      var dayToCheck = new Date(date).setHours(0,0,0,0);

      for (var i = 0; i < vm.events.length; i++) {
        var currentDay = new Date(vm.events[i].date).setHours(0,0,0,0);

        if (dayToCheck === currentDay) {
          return vm.events[i].status;
        }
      }
    }

    return '';
  }

  vm.today = function() {
    vm.dt = new Date();
  };
  vm.today();

  vm.clear = function() {
    vm.dt = null;
  };

  vm.inlineOptions = {
    customClass: getDayClass,
    minDate: new Date(),
    showWeeks: true
  };

  // Disable weekend selection
  function disabled(data) {
    var date = data.date,
      mode = data.mode;
    return mode === 'day' && (date.getDay() === 0 || date.getDay() === 6);
  }

  vm.dateOptions = {
    dateDisabled: disabled,
    formatYear: 'yy',
    maxDate: new Date(2020, 5, 22),
    minDate: new Date(),
    startingDay: 1
  };

  vm.toggleMin = function() {
    vm.inlineOptions.minDate = vm.inlineOptions.minDate ? null : new Date();
    vm.dateOptions.minDate = vm.inlineOptions.minDate;
  };

  vm.toggleMin();

  vm.open1 = function() {
    vm.popup1.opened = true;
  };

  vm.open2 = function() {
    vm.popup2.opened = true;
  };

  vm.setDate = function(year, month, day) {
    vm.dt = new Date(year, month, day);
  };

  vm.formats = ['dd-MMMM-yyyy', 'yyyy/MM/dd', 'dd.MM.yyyy', 'shortDate'];
  vm.format = vm.formats[0];
  vm.altInputFormats = ['M!/d!/yyyy'];

  vm.popup1 = {
    opened: false
  };

  vm.popup2 = {
    opened: false
  };

  var tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  var afterTomorrow = new Date();
  afterTomorrow.setDate(tomorrow.getDate() + 1);
  vm.events = [
    {
      date: tomorrow,
      status: 'full'
    },
    {
      date: afterTomorrow,
      status: 'partially'
    }
  ];

}]);

'use strict';

angular.module('ufcw')
  .controller('WelcomeController', ["$rootScope", "EnvConfig", "Request", "$log", "$state", "toaster", "$location", "$translate", function ($rootScope,EnvConfig,Request,$log,$state,toaster,$location,$translate) {
    $rootScope.headLander = false;
    var vm = this;
    
    var token = $location.search().token; 
    
    
    vm.activate = function(token){
      var data = {};
      Request.put('signup/?token='+token,false,data,function(response) {
        if(response.status !== 0){
          toaster.pop('error', "", $translate.instant('api.'+response.description));
        }else{
          toaster.pop('success', "", $translate.instant("welcome.success"));
        }
      });
    };

    if(token){
      vm.activate(token);
    }
    

  }]);
