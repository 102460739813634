'use strict';

angular.module('ufcw')
  .controller('HorarioszaController', function ($rootScope) {
    var vm = this;
    vm.errorMsg = null;
    
    $rootScope.headLander = false;
    
  
  });

  



