'use strict';

angular.module('ufcw')
  .controller('LoginController', function ($rootScope,$localstorage,Request,$log,$state,toaster,EnvConfig,$window,$translate,$location) {
    
    $rootScope.headLander = false;
    var vm = this;
    vm.useRoutines = $localstorage.get('useRoutines');
    vm.errorMsg = null;
    $localstorage.clear();
    vm.showCanchas = EnvConfig.show_canchas;

    var id = $location.search().id; 
    var token = $location.search().token; 
    
    vm.login = function(){

      if(EnvConfig.SIGNIN_ENABLED || vm.email==="gonzalo@squal.us" || vm.email==="juanto@squal.us"){
        if(token && id){
          $localstorage.set('token', token);
          
          Request.get('user/?id='+id,true,function(response) {
            if(response.status !== 0){
              // vm.errorMsg = response.description;
              toaster.pop('error', "", $translate.instant('api.'+response.description));
              $localstorage.clear();
            }else{
              $localstorage.setObject('user',response.description);
              var user_access = response.description.userAccess;
              $localstorage.setObject('dependents',response.dependants);
              $localstorage.setObject('user_files',response.u_files);
              $translate.use(response.description.lang);
              $localstorage.set('showMsg',(response.configuration.showInitialMsg===1)?true:false);
              $localstorage.setObject('config',response.configuration);
              $localstorage.setObject('messages',response.messages);
              $localstorage.setObject('alerts',response.alerts);
              $localstorage.set('showLogout',false);
              $rootScope.messages = response.messages;
              if(user_access === 'CANCHA'){
                $state.go('homecanchas');
              }else{
                $state.go('home');
              }
                
              // });
  
            }
          });
        }

      }else{
        toaster.pop('info',"", $translate.instant("signin.soon"));
      }
    };

    vm.login();
  });
