'use strict';

angular.module("ufcw")
.filter('ageRange', function() {
    return function( items, ageselected ) {
        var filtered = [];
        if(typeof ageselected == 'undefined'){
            return items;
        }
        angular.forEach(items, function(item) {
            if( item.agefrom <= ageselected && item.ageto >= ageselected ) {
                filtered.push(item);
            }
        });
        return filtered;
    };
});
