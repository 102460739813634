'use strict';

angular.module('ufcw')
  .controller('StartController', function ($log,Request,$localstorage,$state,$rootScope,toaster,$uibModal,$document,EnvConfig,$window,$translate) {
    var vm = this;

    $rootScope.headLander = false;
    vm.errorMsg = null;
    


    vm.loadGridItems = function(){
      Request.get('appconfiguration?id=1',false,function(response) {
        if(response.status !== 0){
          toaster.pop('info', "", $translate.instant('api.'+response.description));
        }else{
          vm.griditems = response.description.griditems;
          $log.log(vm.griditems);
        }
      });
    };

    vm.loadGridItems();

  });