var es_lang = {
    "signin":{
        "email_required": "Email requerido",
        "email_invalid": "Email inválido",
        "pwd_required": "Contraseña requerida",
        "do_not_register": "¿No estás registrado?",
        "create_account": "Crear cuenta",
        "soon": "Proximamente vas a poder acceder a hacer tus reservas online. Gracias por tu paciencia!"
    },
    "recovery":{
        "text": "No hay problema, ingresá tu dirección de correo y te enviaremos un e-mail con un código para que puedas ingresar tu nueva contraseña."
    },
    "rest": {
        "session_expired": "Sesión expirada, ingrese nuevamente"
    },
    "reservationcustom":{
        "type_custom": "Tipo de juego",
        "single": "Single", 
        "time_custom": "Tiempo de juego",
        "inittime": "Hora de inicio",
        "doble": "Doble",
        "error_document": "Cédula incorrecta",
        "member_num_id": "Ingrese Nro. de socio o cédula"
    },
    "general":{
        "view_schedule": "Ver horarios",
        "forgot_your_pwd": "¿Olvidaste la contraseña?",
        "email": "Email",
        "password": "Contraseña",
        "developed_by": "Desarrollado por",
        "back": "Volver",
        "next_day": "Siguiente día",
        "my_profile": "Mi Perfil",
        "my_bookings": "Mis Reservas",
        "rules": "Reglas",
        "covid_notice": "Aviso COVID-19",
        "session_close": "Cerrar sesión",
        "reserve": "Reservar",
        "page_title": "Gestor de Reservas",
        "contact_us": "Contáctanos",
        "all_activities": "Todas las actividades",
        "all_categories": "Todas las categorías",
        "all_buildings": "Todas las sucursales",
        "all_people": "Todas las personas",
        "all_ages": "Todas las edades",
        "select_activity": "Seleccione una actividad",
        "member_num": "Socio Nro.",
        "member_id": "Cédula",
        "dob": "Nacimiento",
        "phone": "Teléfono",
        "my_family_group": "Mi grupo familiar",
        "yes": "SI",
        "no": "NO",
        "of": "de",
        "activities": "Actividades",
        "messages": "Mis Mensajes",
        "carnetExpiration": "Venc. C. Salud",
        "payExpiration": "Venc. de Pago",
        "expiration_title": "Aviso",
        "carnetExpiration_message": "Le informamos que su C. Salud vence el ",
        "carnetAlreadyExpirated_message": "Le informamos que su C. Salud venció el ",
        "payExpiration_mensual_message": "Le informamos que su Mensualidad venció el ",
        "payExpiration_anual_message": "Le informamos que su Anualidad vence el ",
        "documents": "Documentos",
        "buy_cuponeras": "Comprar cuponeras",
        "edit_action": "Editar",
        "view_action": "Ver",
        "view_routine":"Rutinas de ejercicios"
        },
    "advsearch":{
        "filter": "Filtro",
        "schedule": "Horario",
        "age": "Edad",
        "no_activities": "No existen actividades para la selección",
        "title": "Búsqueda avanzada"
    },
    "button": {
        "send": "Enviar",
        "signin": "Entrar",
        "search": "Buscar",
        "cancel": "Cancelar",
        "reserve": "Reservar",
        "register": "Registrar",
        "enter": "Ingresar",
        "notify": "Notificar",
        "close": "Cerrar",
        "save": "Guardar",
        "confirm": "Confirmar",
        "pending":"En proceso",
        "login": "Iniciar sesión"
    },
    "days": {
        "mon": "LUN",
        "tue": "MAR",
        "wed": "MIE",
        "thu": "JUE",
        "fri": "VIE",
        "sat": "SAB",
        "sun": "DOM",
        "monday": "Lunes",
        "tuesday": "Martes",
        "wednesday": "Miércoles",
        "thursday": "Jueves",
        "friday": "Viernes",
        "saturday": "Sábado",
        "sunday": "Domingo"
    },
    "months": {
        "january": "Enero",
        "february": "Febrero",
        "march": "Marzo",
        "april": "Abril",
        "may": "Mayo",
        "june": "Junio",
        "july": "Julio",
        "august": "Agosto",
        "september": "Setiembre",
        "october": "Octubre",
        "november": "Noviembre",
        "december": "Diciembre"
    },
    "reserve": {
        "reserve": "Reserva",
        "gender": "Género",
        "activity": "Actividad",
        "place": "Lugar",
        "status": "Estado",
        "date": "Fecha",
        "hour": "Hora",
        "member": "Socio",
        "assistance": "Asistencia",
        "info": "Info",
        "guests": "Invitados",
        "view_guests": "Ver Invitados",
        "view_my_waitinglist": "Ver mis listas de espera",
        "view_my_history": "Ver historial de reservas",
        "reserved": "RESERVADA",
        "canceled": "CANCELADA",
        "activity_suspended": "SUSPENDIDA",
        "does_not_have_reserve" : "No tiene reservas para listar",
        "info_activity" : "Información sobre la actividad",
        "add_to_waitinglist" : "Agendarse a la lista de espera",
        "available_places": "lugares disponibles",
        "unlimited_places": "Lugares ilimitados",
        "there_not": "No hay",
        "view_class": "Ver clase",
        "reserve_for_family": "Reservar para un familiar",
        "no_activities_for_today": "No hay actividades para el día de hoy",
        "actions": "Acciones",
        "remove_from_waitinglist": "Quitarme de la lista de espera",
        "family_assign": "Asignar a un familiar",
        "member_num_id": "Cédula",
        "guests_to": "Invitados a",
        "for_me": "Para mí",
        "waiting_text": "Enterate cuando quede un cupo disponible.",
        "history": "Historial de Reservas",
        "status_reserved": "Reservado",
        "status_canceled": "Cancelado",
        "status_active": "Activo",
        "my_reserves": "Mis Reservas",
        "canceled_ok": "Reserva cancelada correctamente",
        "cancel": "Cancelar reserva",
        "must_select_dependent": "Debe seleccionar un familiar",
        "title": "Horarios de actividades",
        "success": "Reserva realizada correctamente",
        "modal_title_confirm": "Confirme la reserva",
        "modal_title_cancel": "Cancelar reserva",
        "waitinglist_added": "Ingresado a lista de espera correctamente",
        "invalid_guest": "La cédula ingresada no es correcta",
        "edit_guests": "Ver/Modificar invitados",
        "reservation_updated": "Reserva modificada correctamente",
        "no_activities_yet": "Aún no hay actividades para mostrar",
        "building": "Sucursal",
        "customer_search_txt": "Buscar socio"
    },
    "maintenance": {
        "title": "La plataforma de reservas está en mantenimiento!",
        "msg": "Por favor disculpa las molestias.",
        "msg2": "En breve podrás reservar nuevamente."
    },
    "setpwd": {
        "title": "Asistente de recuperación de contraseña",
        "sub_title": "Por favor ingresa el código que te enviamos por email, luego puedes ingresar la nueva contraseña y listo!",
        "code": "Código",
        "required_code": "Código requerido",
        "confirm_pwd": "Confirmar contraseña",
        "pwd_not_match": "Las contraseñas no coinciden"
    },
    "signup": {
        "title": "Registro de usuario",
        "sub_title": "Por favor completa tus datos y haz clic en el botón registrarse.",
        "sub_title2": "Recibirás un email a la casilla que ingreses con un link para confirmar tu cuenta.",
        "member_num": "Nro. de socio",
        "member_num_required": "Nro. de socio requerido",
        "member_id_required": "Cédula requerida",
        "member_id_invalid": "La cédula debe ser ingresada sin puntos ni guiones",
        "dob": "Fecha de nacimiento",
        "dob_required": "Fecha de nacimiento requerida",
        "cellphone": "Celular",
        "cellphone_required": "Celular requerido",
        "confirm_pwd_required": "Confirmar contraseña es requerido",
        "success": "Usuario registrado existosamente! Revisa tu correo que te hemos enviado un email de confirmación",
        "first_name": "Nombre",
        "first_name_required": "Nombre requerido",
        "last_name": "Apellido",
        "last_name_required": "Apellido requerido",
        "gender_male": "Masculino",
        "gender_female": "Femenino",
        "gender_required": "Sexo requerido",
        "member_common": "Socio ",
        "member_coupon": "Socio Cuponera"
    },
    "welcome": {
        "title": "¡Excelente, tu cuenta ha sido activada con éxito!",
        "sub_title": "¡Ya puedes ingresar y reservar tus clases!",
        "success": "Usuario activado existosamente!"
    },
    "privacy": {
        "title": "Politicas de privacidad"
    },
    "notice": {
        "title": "Aviso de COVID-19 positivo",
        "text": "Al comunicar que se realizó el examen y dio COVID positivo el club comenzará un protocolo primero corroborando con usted via teléfono que esta información es correcta. Una vez corroborada la información se procederá a comunicar a todos los socios que participaron en alguna actividad con usted que estuvieron en contacto con una persona COVID positivo. El club garantiza la confidencialidad de sus datos.",
        "msg_sent": "Aviso enviado correctamente"
    },
    "rules": {
        "title": "Reglas básicas de uso"
    },
    "confirm": {
        "title": "Confirmo COVID-19 positivo",
        "text": "Esta seguro que desea confirmar que es COVID-19 positivo y dar aviso al club?"
    },
    "profile": {
        "title": "Mis Datos",
        "current_pwd": "Contraseña actual",
        "current_pwd_invalid": "Contraseña actual inválida",
        "current_pwd_placeholder": "Indroduzca contraseña anterior",
        "new_pwd": "Contraseña nueva",
        "new_pwd_invalid": "Contraseña nueva inválida",
        "new_pwd_placeholder": "Introduzca contraseña nueva",
        "saved": "Datos de usuario actualizados correctamente",
        "phone_invalid": "Teléfono incorrecto",
        "select_lang": "Seleccione el idioma",
        "lang": "Idioma",
        "view_doc":"Ver Otro",
        "doc":"Otro",
        "view_cert_aptitude":"Ver C. Salud",
        "cert_aptitude":"C. Salud",
        "view_cert_covid":"Ver Resultado de Hisopado",
        "cert_covid":"Resultado de Hisopado"
    },
    "showinfo": {
        "about": "Información sobre",
        "reason_canceled": "Motivo de la suspensión"
    },
    "waitinglist": {
        "title": "Listas de espera",
        "removed": "Removido de la lista de espera correctamente"
    },
    "lang": {
        "es": "Español",
        "en": "Inglés"
    },
    "message": {
        "open": "Abrir",
        "delete": "Eliminar mensaje",
        "you_dont_have": "No tiene mensajes para listar",
        "received":"Recibido el",
        "at": "a las"
    },
    "api": {
        "general": {
            "days": {
                "monday": "Lunes",
                "tuesday": "Martes",
                "wednesday": "Miércoles",
                "thursday": "Jueves",
                "friday": "Viernes",
                "saturday": "Sábado",
                "sunday": "Domingo"
            },
            "month": {
                "january": "Enero",
                "february": "Febrero",
                "march": "Marzo",
                "april": "Abril",
                "may": "Mayo",
                "june": "Junio",
                "july": "Julio",
                "august": "Agosto",
                "september": "Setiembre",
                "october": "Octubre",
                "november": "Noviembre",
                "december": "Diciembre"
            }
        },
        "api-admin":{
            "activitiescanceled": {
                "id_required": "Identificador de actividad requerido",
                "invalid_id": "Identificador de actividad inválido",
                "date_required": "La fecha de la actividad es requerida",
                "user_id_required": "El identificador del usuario es requerido",
                "cancel_reason": "El motivo de la cancelación es requerida",
                "list_user_required": "La lista de socios es requerida"
            },
            "activitycategory": {
                "id_required": "Identificador de categoría requerido",
                "invalid_id": "Identificador de categoría inválido",
                "was_deleted": "Categoría eliminada correctamente",
                "name_required": "El nombre de la categoría es requerido"
            },
            "activity": {
                "id_required": "Identificador de actividad requerido",
                "invalid_id": "Identificador de actividad inválido",
                "name_required": "El nombre de la actividad es requerido",
                "category_id_required": "El identificador de categoría es requerido",
                "company_id_required": "El identificador de la compañía es requerido",
                "status_required": "Estado de la actividad requerido"
            },
            "activitytime": {
                "user_required": "Usuario requerido",
                "invalid_conf": "Configuración inválida",
                "invalid_activity": "Identificador de actividad inválida",
                "activity_required": "Identificador de actividad requerido",
                "starttime_required": "Hora de inicio requerida",
                "endtime_required": "Hora de fin requerida"
            },
            "adminuser": {
                "invalid_action": "Acción inválida",
                "user_name_required": "Nombre de usuario requerido",
                "user_lastname_required": "Apellido de usuario requerido",
                "company_id_required": "El identificador de la compañía es requerido",
                "user_type_required": "Tipo de usuario requerido",
                "invalid_user_type": "Tipo de usuario inválido",
                "user_email_required": "Usuario requerido",
                "user_id_required": "El identificador del usuario es requerido",
                "invalid_user_id": "Identificador de usuario inválido",
                "email_exist": "Ya existe el email",
                "user_deleted": "Usuario eliminado"
            },
            "assistance": {
                "list_user_required": "La lista de socios es requerida",
                "list_check_user": "Lista ingresada, revise los asistentes, existen socios que no fueron enviados en la lista",
                "list_ok": "Lista ingresada"
            },
            "fixreservation": {
                "user_id_required": "El identificador del usuario es requerido",
                "activity_required": "Identificador de actividad requerido",
                "date_required": "La fecha de la actividad es requerida",
                "invalid_user_id": "Identificador de usuario inválido",
                "invalid_activity": "Actividad inválida",
                "reservation_exists": "Reserva ya existe"
            },
            "holiday": {
                "id_required": "Identificador requerido",
                "invalid_id": "Identificador inválido",
                "deleted_ok": "Eliminado correctamente",
                "name_required": "Nombre requerido",
                "year_required": "Año requerido",
                "month_required": "Mes requerido",
                "day_required": "Día requerido"
            },
            "location": {
                "id_required": "Identificador requerido",
                "invalid_id": "Identificador inválido",
                "name_required": "Nombre requerido"
            },
            "message": {
                "no_msg": "Sin mensajes",
                "msg_not_exist": "No existe el mensaje",
                "saved": "Mensaje guardado",
                "invalid_id": "Identificador de mensaje inválido",
                "read": "Mensaje leído"
            },
            "product": {
                "id_required": "Identificador de producto requerido",
                "invalid_id": "Identificador de producto inválido",
                "deleted_ok": "Eliminado correctamente",
                "name_required": "Nombre requerido",
                "price_required": "Precio requerido",
                "currency_required": "Moneda requerido"
            },
            "recovery": {
                "email_required": "Email de usuario requerido",
                "invalid_email": "Email de usuario inválido",
                "invalid_user_id": "Identificador de usuario inválido",
                "email_sent": "Email enviado",
                "code_required": "Código de recuperación requerido",
                "pwd_required": "Contraseña requerida",
                "invalid_code": "Código inválido",
                "expired_code": "Código expirado",
                "pwd_change_ok": "Contraseña actualizada correctamente",
                "pwd_recovery": "Recuperación de contraseña"
            },
            "report": {
                "type_required": "Tipo requerido",
                "date_from_required": "Fecha incio requerida",
                "date_to_required": "Fecha fin requerida"
            },
            "reservation": {
                "type_required": "Tipo de reserva requerido",
                "invalid_user_id": "Identificador de usuario inválido",
                "id_required": "Identificador de reserva requerido",
                "error_check_attendance": "Hubo un problema con la reserva, no se pudo marcar la asistencia",
                "error_cancel_attendance": "Hubo un problema con la reserva, no se pudo cancelar",
                "user_id_required": "El identificador del usuario es requerido",
                "activity_id_required": "Identificador de actividad requerido",
                "invalid_conf": "Configuración inválida",
                "user_deleted": "Usuario dado de baja",
                "reservation_not_allowed": "Estimado socio, usted no está habilitado para realizar reservas. Motivo:",
                "invalid_schedule": "Horario de Actividad inválida",
                "invalid_reservation": "Reserva inválida",
                "generic_error": "Ocurrió un error, por favor cierre la página e intentelo nuevamente.",
                "invalid_activity": "Actividad inválida",
                "invalid_category": "Categoría inválida",
                "invalid_Location": "Espacio inválido",
                "error_document": "Cédula incorrecta",
                "member_num_id": "Ingrese cédula",
                "no_more_places": "Ya no quedan cupos para esta actividad, intenta reservar otro horario u otra actividad...",
                "invalid_user_cat": "Categoría de usuario inválida",
                "dob_error": "Hay un problema con su fecha de nacimiento, por favor comuníquese con administración para solucionarlo",
                "out_of_age": "Esta actividad no es para su edad.",
                "reservation_same_time": "Ya tiene reservas cuyo horario coincide con otras actividades.",
                "reservation_exists": "Reserva ya existe",
                "penalization_msg_start": "Tiene una penalización de ",
                "penalization_msg_end": "horas",
                "same_activity_msg_start": "No puede reservar",
                "same_activity_msg_end": "actividades consecutivas de la misma categoría",
                "max_per_day_msg_start": "No puede reservar más de",
                "max_per_day_msg_end": "actividades en el mismo día",
                "not_allowed_only_members": "Acción no permitida para su tipo de usuario.",
                "invalid_init_hour": "Hora de inicio inválida",
                "invalid_duration": "Duración inválida",
                "reservation_exceed_end": "Excede la hora de fin de la actividad",
                "reservation_less_current_end": "La hora de inicio debe ser mayor a la actual"
            },
            "responsible": {
                "user_name_required": "Nombre de usuario requerido",
                "user_lastname_required": "Apellido de usuario requerido",
                "user_extid_required": "N° de Socio requerido",
                "company_id_required": "El identificador de la compañía es requerido",
                "user_email_required": "Email requerido",
                "user_id_required": "El identificador del usuario es requerido",
                "invalid_user_id": "Identificador de usuario inválido"
            },
            "signin": {
                "user_id_required": "El identificador del usuario es requerido",
                "pwd_required": "Contraseña requerida",
                "invalid_user_id": "Usuario o contraseña inválidos",
                "invalid_user": "Usuario inválido"
            },
            "signup": {
                "user_email_required": "Identificador de usuario requerido",
                "company_id_required": "El identificador de la compañía es requerido",
                "invalid_user_email": "Identificador de usuario inválido",
                "user_name_required": "Nombre de usuario requerido",
                "user_lastname_required": "Apellido de usuario requerido",
                "user_extid_required": "N° de Socio requerido",
                "pwd_required": "Contraseña requerida",
                "user_type_required": "Tipo de usuario requerido",
                "invalid_user_type": "Tipo de usuario inválido",
                "user_already_registered": "El usuario ya se encuentra registrado",
                "invalid_user": "Identificador de usuario inválido",
                "reg_confirmation": "Confirmación de registro",
                "token_required": "Identificador de usuario requerido",
                "dibaled_user_msg": "Disabled user, remember to check your email to validate it. If you have already done and can't log in, contact administration."
            },
            "uploadimage": {
                "image_required": "La imagen es requerida",
                "image_name_required": "El nombre de imagen es requerido",
                "file_not_image": "El archivo no es una imagen",
                "invalid_type": "Tipo de imagen inválido",
                "error_save": "Error al guardar la imagen",
                "saved_ok": "Imagen guardada correctamente."
            },
            "usercategory": {
                "id_required": "El identificador es requerido",
                "invalid_id": "Identificador inválido",
                "name_required": "Nombre requerido",
                "age_from_required": "Edad desde requerida",
                "age_to_required": "Edad hasta requerida"
            },
            "user": {
                "user_name_required": "Nombre de usuario requerido",
                "user_lastname_required": "Apellido de usuario requerido",
                "user_extid_required": "N° de Socio requerido",
                "company_id_required": "El identificador de la compañía es requerido",
                "user_id_required": "El identificador del usuario es requerido",
                "invalid_user_id": "Identificador de usuario inválido",
                "invalid_email": "Email inválido"
            },
            "usertype": {
                "id_required": "Identificador de tipo de usuario requerido",
                "invalid_id": "Identificador inválido",
                "name_required": "Nombre de tipo de usuario requerido"
            },
            "whassistance": {
                "user_dont_exist": "Usuario inexistente",
                "user_inactive": "Usuario inactivo",
                "not_activities_today": "No tiene actividades para el día de hoy.",
                "assitant_error": "Hubo un problema con la reserva, no se pudo marcar la asistencia"
            },
            "whuser": {
                "user_name_required": "Nombre de usuario requerido. Recibido:",
                "user_lastname_required": "Apellido de usuario requerido. Recibido:",
                "user_extid_required": "N° de Socio requerido. Recibido:",
                "company_id_required": "El identificador de la compañía es requerido. Recibido:",
                "doc_required": "Documento requerido. Recibido:",
                "gender_required": "El género es requerido. Recibido"
            },
            "notification": {
                "id_required": "Identificador de usuario requerido",
                "subject_required": "Sujeto del mensaje requerido",
                "title_required": "Título del mensaje requerido",
                "text_required": "Texto del mensaje requerido",
                "invalid_user": "Usuario inválido",
                "invalid_main_user": "Usuario titular inválido",
                "invalid_action": "Acción inválida",
                "invalid_conf": "Configuración inválida",
                "email_sent": "Email enviado",
                "invalid_schedule": "Horario Actividad inválida",
                "invalid_activity": "Actividad inválida",
                "invalid_reservation": "Identificador de reserva inválido",
                "confirm_reservation": "Confirmación de reserva",
                "you_cancel": "Has cancelado",
                "we_cancel": "Hemos canceldo",
                "reason": "Motivo:",
                "reservation_cancel": "Cancelación de Reserva"
            }
        },
        "api":{
            "activitytime": {
                "invalid_conf": "Configuración inválida",
                "user_required": "Usuario requerido",
                "activity_required": "Actividad requerida",
                "invalid_user": "Usuario inválido",
                "invalid_activity": "Actividad inválida"
            },
            "email": {
                "id_required": "Identificador de usuario requerido",
                "subject_required": "Sujeto del mensaje requerido",
                "title_required": "Título del mensaje requerido",
                "text_required": "Texto del mensaje requerido",
                "invalid_user": "Usuario inválido",
                "invalid_main_user": "Usuario titular inválido",
                "invalid_action": "Acción inválida",
                "invalid_conf": "Configuración inválida",
                "email_sent": "Email enviado"
            },
            "message": {
                "wrong_user": "Usuario incorrecto",
                "msg_not_exist": "No existe el mensaje",
                "was_an_error": "Hubo un error:",
                "msg_saved": "Mensaje guardado",
                "invalid_id": "Identificador de mensaje inválido",
                "msg_read": "Mensaje leído",
                "msg_deleted": "Mensaje eliminado"
            },
            "notification": {
                "id_required": "Identificador de usuario requerido",
                "subject_required": "Sujeto del mensaje requerido",
                "title_required": "Título del mensaje requerido",
                "text_required": "Texto del mensaje requerido",
                "invalid_user": "Usuario inválido",
                "invalid_main_user": "Usuario titular inválido",
                "invalid_action": "Acción inválida",
                "invalid_conf": "Configuración inválida",
                "email_sent": "Email enviado",
                "invalid_schedule": "Horario Actividad inválida",
                "invalid_activity": "Actividad inválida",
                "invalid_reservation": "Identificador de reserva inválido"
            },
            "recovery": {
                "email_required": "Email de usuario requerido",
                "invalid_email": "Email de usuario inválido",
                "invalid_user_id": "Identificador de usuario inválido",
                "email_sent": "Email enviado",
                "code_required": "Codigo de recuperación requerido",
                "pwd_required": "Contraseña requerida",
                "invalid_code": "Código inválido",
                "expired_code": "Código expirado",
                "pwd_change_ok": "Contraseña actualizada correctamente",
                "not_access": "Usuario inhabilitado, comunicarse con administración"
            },
            "reservation": {
                "type_required": "Tipo de reserva requerido",
                "invalid_user_id": "Identificador de usuario inválido",
                "id_required": "Identificador de reserva requerido",
                "user_id_required": "El identificador del usuario es requerido",
                "activity_id_required": "Identificador de actividad requerido",
                "invalid_conf": "Configuración inválida",
                "user_deleted": "Usuario dado de baja",
                "reservation_not_allowed": "Estimado socio, usted no está habilitado para realizar reservas. Motivo:",
                "invalid_schedule": "Horario Actividad inválida",
                "invalid_reservation": "Reserva inválida",
                "generic_error": "Ocurrió un error, por favor cierre la página e intentelo nuevamente.",
                "invalid_activity": "Actividad inválida",
                "invalid_category": "Categoría inválida",
                "invalid_Location": "Espacio inválido",
                "no_more_places": "Ya no quedan cupos para esta actividad, intenta reservar otro horario u otra actividad...",
                "invalid_user_cat": "Categoría de usuario inválida",
                "dob_error": "Hay un problema con su fecha de nacimiento, por favor comuníquese con administración para solucionarlo",
                "out_of_age": "Esta actividad no es para su edad.",
                "reservation_same_time": "Ya tiene reservas cuyo horario coincide con otras actividades.",                
                "reservation_exists": "Reserva ya existe",
                "invalid_main_user_id": "Usuario titular inválido",
                "invalid_action": "Acción inválida",
                "invalid_user_category": "Su categoría de socio no le permite reservar para esta clase.",
                "invalid_guest_category": "La categoría del invitado no le permite reservar para esta clase.",
                "penalization_msg_start": "Tiene una penalización de ",
                "penalization_msg_end": "horas",
                "max_actives_reservation_msg_start": "No puede tener más de",
                "max_actives_reservation_msg_end": "reservas simultaneas.",
                "same_activity_msg_start": "No puede reservar",
                "same_activity_msg_end": "actividades consecutivas de la misma categoría",
                "max_per_day_msg_start": "No puede reservar más de",
                "max_per_day_msg_end": "actividades en el mismo día",
                "invalid_id": "Identificador inválido",
                "cant_cancel_msg": "Lo sentimos ya no puede cancelar esta reserva",
                "cancel_ok": "Reserva cancelada correctamente",
                "free_place_msg": "Aviso de lugar disponible",
                "free_place_body_a": "Se liberó un cupo para la clase",
                "free_place_body_b": "de",
                "free_place_body_c": "a",
                "free_place_body_d": "para el día",
                "not_allowed_only_members": "Acción no permitida para su tipo de usuario.",
                "already_reserved": "Esta actividad acaba de ser reservada por otro socio",
                "invalid_init_hour": "Hora de inicio inválida",
                "invalid_duration": "Duración inválida",
                "reservation_exceed_end": "Excede la hora de fin de la actividad",
                "reservation_less_current_end": "La hora de inicio debe ser mayor a la actual",
                "carnet_expired": "Tiene el C.Salud de salud vencido",
                "no_more_cuponera": "No tiene más actividades disponibles en la cuponera.",
                "cancelation_exists": "El horario seleccionado esta suspendido",
                "invalid_guests": "Los datos de uno o más invitados son incorrectos"
            },
            "signin": {
                "user_id_required": "El identificador del usuario es requerido",
                "pwd_required": "Contraseña requerida",
                "invalid_user_id": "Usuario o contraseña inválidos",
                "dibaled_user_msg": "Usuario inhabilitado, recuerde revisar su email para validarlo. Si ya lo hizo y no puede iniciar sesi&oacute;n comunicarse con administraci&oacute;n.",
                "invalid_conf": "Configuración inválida"
            },
            "signup": {
                "company_id_required": "El identificador de la compañía es requerido",
                "invalid_user_email": "Identificador de usuario inválido",
                "user_name_required": "Nombre de usuario requerido",
                "user_lastname_required": "Apellido de usuario requerido",
                "user_extid_required": "N° de Socio requerido",
                "pwd_required": "Contraseña requerida",
                "user_type_required": "Tipo de usuario requerido",
                "invalid_user_type": "Tipo de usuario inválido",
                "user_already_registered": "El usuario ya se encuentra registrado",
                "invalid_user": "Identificador de usuario inválido",
                "token_required": "Identificador de usuario requerido",
                "user_email_required": "Email de usuario requerido",
                "invalid_email": "Email inválido",
                "invalid_conf": "Configuración inválida",
                "deleted_user": "Usuario inhabilitado",
                "wrong_user_data": "Datos de socio incorrectos",
                "confirmation": "Confirmación de registro"
            },
            "user": {
                "user_name_required": "Nombre de usuario requerido",
                "user_lastname_required": "Apellido de usuario requerido",
                "user_extid_required": "N° de Socio requerido",
                "company_id_required": "El identificador de la compañía es requerido",
                "user_id_required": "El identificador del usuario es requerido",
                "invalid_user_id": "Identificador de usuario inválido",
                "invalid_email": "Email de usuario inválido",
                "invalid_action": "Acción inválida",
                "user_email_required": "Email de usuario requerido",
                "user_already_registered": "El usuario ya se encuentra registrado",
                "last_pwd_invalid": "Contraseña anterior ingresada inválida",
                "user_deleted": "Usuario eliminado",
                "guests_repeated": "Hay uno o más Nros. de socio/documentos que se repiten"
            },
            "waitinglist": {
                "user_id_required": "El identificador del usuario es requerido",
                "invalid_user_id": "Identificador de usuario inválido",
                "activity_id_required": "Identificador de actividad requerido",
                "invalid_schedule": "Horario Actividad inválida",
                "user_already_exist": "Socio ya registrado en esta lista de espera",
                "user_added": "Socio agregado a la lista de espera",
                "invalid_reservation_id": "Identificador de reserva inválido",
                "reservation_id_required": "Identificador de reserva requerido",
                "invalid_action": "Acción inválida",
                "invalid_main_user_id": "Usuario titular inválido",
                "user_removed_from_list": "Socio removido de la lista de espera"
            }
        }
      }
  };
