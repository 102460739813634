'use strict';

angular.module('ufcw')
  .controller('SigninController', function ($rootScope,$localstorage,Request,$log,$state,toaster,EnvConfig,$window,$uibModal,$document,$translate) {
    
    $rootScope.headLander = false;
    var vm = this;
    vm.useRoutines = $localstorage.get('useRoutines');
    vm.errorMsg = null;
    $localstorage.clear();
    vm.showCanchas = EnvConfig.show_canchas;
    
    vm.signin = function(){

      if(EnvConfig.SIGNIN_ENABLED || vm.email==="gonzalo@squal.us" || vm.email==="juanto@squal.us"){
        var data = {user:vm.email,password:vm.password};

        Request.put('signin',false,data,function(response) {
          if(response.status !== 0){
            // vm.errorMsg = response.description;
            toaster.pop('error', "", $translate.instant('api.'+response.description));
            $localstorage.clear();
          }else{
            $localstorage.set('token',response.token);
            $localstorage.setObject('user',response.description);
            vm.user_access = response.description.userAccess;
            $localstorage.setObject('dependents',response.dependents);
            $localstorage.setObject('user_files',response.user_files);
            $translate.use(response.description.lang);
            $localstorage.set('showMsg',(response.configuration.showInitialMsg===1)?true:false);
            $localstorage.setObject('config',response.configuration);
            $localstorage.setObject('messages',response.messages);
            $localstorage.setObject('alerts',response.alerts);
            $localstorage.set('showLogout',true);
            $rootScope.messages = response.messages;

            vm.loadQR(response.description.id, response.description.identificationNumber);

            var validate_terms = (response.configuration.validateTerms===1)?true:false;
            if(validate_terms){
              if(response.validate_terms.status === 'VALIDATE'){
                vm.termsId = response.validate_terms.termsId;
                vm.userId = response.description.id;
                vm.terms_text = response.validate_terms.text.replaceAll('\n','<BR>');
                vm.openTerms();
              }else{
                if(vm.user_access === 'CANCHA'){
                  $state.go('homecanchas');
                }else{
                  $state.go('home');
                }  
              }
            }else{
              if(vm.user_access === 'CANCHA'){
                $state.go('homecanchas');
              }else{
                $state.go('home');
              }
            }
          }
        });


      }else{
        toaster.pop('info',"", $translate.instant("signin.soon"));
      }

      vm.openTerms = function (size, parentSelector) {
        var parentElem = parentSelector ? 
          angular.element($document[0].querySelector('.modal-demo ' + parentSelector)) : undefined;
        var modalInstance = $uibModal.open({
          animation: vm.animationsEnabled,
          ariaLabelledBy: 'modal-title',
          ariaDescribedBy: 'modal-body',
          templateUrl: 'terms.html',
          controller: 'ModalTermsController',
          controllerAs: 'modalCtrl',
          size: size,
          backdrop: 'static',
          keyboard: false,
          appendTo: parentElem,
          resolve: {
            terms_text: function () {
              return vm.terms_text;
            }
          }
        });
    
        modalInstance.result.then(function (result) {
          if(result.status === 'OK'){
            var date_agreed = new Date();
            var agreed_date = date_agreed.getFullYear() + '-' + (date_agreed.getMonth()+1) + '-' + date_agreed.getDate();
            var data = {termsId: vm.termsId, agreed: 1, date_agreed: agreed_date};
            Request.put('userterms/?user='+vm.userId,true,data,function(response) {
              if(response.status !== 0){
                $rootScope.logout();
                toaster.pop('error', "", $translate.instant('api.'+response.description));
              }else{
                if(vm.user_access === 'CANCHA'){
                  $state.go('homecanchas');
                }else{
                  $state.go('home');
                }
              }
            });
          }else{
            $rootScope.logout();
          }
        }, function () {
          // $log.info('Modal dismissed at: ' + new Date());
        });
      };

    };


    vm.loadQR = function(id, idNum){
      $localstorage.set('QRFromS3', false);
      Request.getExternal('https://squalus-public-production.s3.us-east-2.amazonaws.com/agenda/bohemios/1/'+id+'/'+idNum+'.png',false,function(response) {
        if(response.status === 200){
          $localstorage.set('QRFromS3', true);
        }
      });
    };

  });
  angular.module('ufcw').controller('ModalTermsController', function ($uibModalInstance,$log,terms_text,toaster) {
    var vm = this;
    
    vm.terms_text = terms_text;
    vm.terms = 0;

    vm.ok = function () {
      try {
        $uibModalInstance.close({status: 'OK'});
      } catch (error) {
        toaster.pop('error', "", error);
      }
    };
  
    vm.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  });

  angular.module('ufcw').component('modalComponent', {
    templateUrl: 'terms.html',
    bindings: {
      resolve: '<',
      close: '&',
      dismiss: '&'
    },
    controller: function () {
      var vm = this;
  
      vm.$onInit = function () {
        vm.terms_text = vm.resolve.terms_text;
      };
  
      vm.ok = function () {
        vm.close({$value: 'OK'});
      };
  
      vm.cancel = function () {
        vm.dismiss({$value: 'cancel'});
      };
    }
  });