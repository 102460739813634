'use strict';

angular.module('ufcw')

.controller('main', ['$scope','$rootScope','$state','$stateParams','$log','$localstorage','EnvConfig','$translate','Request','toaster', function($scope,$rootScope,$state,$stateParams,$log,$localstorage,EnvConfig,$translate,Request,toaster) {
  $rootScope.footer_app_name    = EnvConfig.footer_app_name;
  $rootScope.footer_email       = EnvConfig.footer_email;
  $rootScope.footer_facebook    = EnvConfig.footer_facebook;
  $rootScope.footer_twitter     = EnvConfig.footer_twitter;
  $rootScope.footer_instagram   = EnvConfig.footer_instagram;
  $rootScope.footer_youtube     = EnvConfig.footer_youtube;
  $rootScope.show_aviso_covid   = EnvConfig.show_aviso_covid;
  $rootScope.show_cuponeras     = EnvConfig.show_cuponeras;
  $rootScope.show_reglas        = EnvConfig.show_reglas;
  $rootScope.signup_nrosocio    = EnvConfig.signup_nrosocio;
  $rootScope.signup_dob         = EnvConfig.signup_dob;
  $rootScope.projectname        = EnvConfig.projectname;
  $rootScope.user               = {};
  $rootScope.user.profile_img   = "images/Icono_persona.png";
  $rootScope.reservasButton     = "/home";
    
  Request.get('appconfiguration/?id='+EnvConfig.COMPANY_ID,false,function(response) {
    if(response.status !== 0){
      alert('Error loading configuration');
    }else{
      var appconfig = response.description.configuration;
      var config = response.description.variables[0];
      if(EnvConfig.useDefaultStyle){
        $rootScope.color1=appconfig.color1;
        $rootScope.color2=appconfig.color2;
        $rootScope.color3=appconfig.color3;
        $rootScope.color4=appconfig.color4;
        $localstorage.set('useRoutines',appconfig.useRoutines);
        $rootScope.useRoutines=appconfig.useRoutines;
      }
      $localstorage.set('maxDaysViewFields',config.maxDaysViewFields);
    }
  });
  
  
  $rootScope.isNumeric = function (n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
  };
  
	$rootScope.$on('$stateChangeSuccess', function(evt, toState, toParams, fromState, fromParams) {

    if(!$rootScope.inArray(toState.name,['signin','start','recovery','setpwd','signup','welcome/:token','horarios','horariosza','fronthorarios/:id','routine','homecanchas','cancha/:id','login/:id/:token'])){
      
      var token = $localstorage.get('token');
      var user  = $localstorage.getObject('user');
      var messages  = $localstorage.getObject('messages');
      var user_files = $localstorage.getObject('user_files');
      $rootScope.validate_terms = $localstorage.getObject('validate_terms');
      
      $rootScope.showLogout = $localstorage.get('showLogout');

      var user_profile = [];

      if(!token || !user){
        $rootScope.user = {};
        $localstorage.clear();
        $state.go('signin');
      }

      if(user){
        user.nameToShow = user.firstName.split(" ");
        user.nameToShow = user.nameToShow[0];
        $translate.use(user.lang);
        
        var today = new Date();
        var dob = new Date(user.dob+" 12:00:00");
        var today_month = today.getMonth()+1;
        var today_day = today.getDate();
        var dob_month = dob.getMonth()+1;
        var dob_day = dob.getDate();
        
        if(today_day === dob_day && today_month === dob_month){
          user.show_globes = true;
        }else{
          user.show_globes = false;
        }

        if(user_files) {
          user_profile = user_files.filter(function (item) {
            if (item.fileType === "PERFIL") {
              return item;
            }
          });
          if (user_profile.length > 0) {
            user.profile_img = EnvConfig.URL_FILES + user_profile[0].companyId + '/' + user_profile[0].userId + '/' + user_profile[0].fileName;
          }else{
            user.profile_img = "images/Icono_persona.png";
          }
        }

        $rootScope.reservasButton = ($rootScope.user.userAccess==='CANCHA')?"/homecanchas":"/home";

        $rootScope.user = user;
        $rootScope.messages = messages;
      }
    }else{
      var user  = $localstorage.getObject('user');
      if(user){
        user.nameToShow  = user.firstName.split(" ");
        user.nameToShow  = user.nameToShow[0];
        user.profile_img = "images/Icono_persona.png";
        $rootScope.user  = user;
      }
    }
    
  });

  $rootScope.logout = function(){
    $localstorage.clear();
    $rootScope.user = {};
    $state.go('signin');
  };

  $rootScope.inArray = function(value, haystack){
    var length = haystack.length;
    for(var i = 0; i < length; i++) {
      if(haystack[i] == value)
      return true;
    }
    return false;
  };

  $rootScope.validateCI = function (pNumero) {
		try {
			if(pNumero){
				pNumero = ""+pNumero;
				if (pNumero.length == 8 || pNumero.length == 7) {
					var _formula = [2, 9, 8, 7, 6, 3, 4];
					var _suma = 0;
					var _guion = 0;
					var _aux = 0;
					var _numero = [0, 0, 0, 0, 0, 0, 0, 0];

					if (pNumero.length == 8) {
						_numero[0] = pNumero[0];
						_numero[1] = pNumero[1];
						_numero[2] = pNumero[2];
						_numero[3] = pNumero[3];
						_numero[4] = pNumero[4];
						_numero[5] = pNumero[5];
						_numero[6] = pNumero[6];
						_numero[7] = pNumero[7];
					}
					//Para cédulas menores a un millón.
					if (pNumero.length == 7) {
						_numero[0] = 0;
						_numero[1] = pNumero[0];
						_numero[2] = pNumero[1];
						_numero[3] = pNumero[2];
						_numero[4] = pNumero[3];
						_numero[5] = pNumero[4];
						_numero[6] = pNumero[5];
						_numero[7] = pNumero[6];
					}

					_suma = (_numero[0] * _formula[0]) + (_numero[1] * _formula[1]) + (_numero[2] * _formula[2]) + (_numero[3] * _formula[3]) + (_numero[4] * _formula[4]) + (_numero[5] * _formula[5]) + (_numero[6] * _formula[6]);

					for (var i = 0; i < 10; i++) {
						_aux = _suma + i;
						if (_aux % 10 == 0) {
							_guion = _aux - _suma;
							i = 10;
						}
					}

					if (_numero[7] == _guion) {
						return true;
					}
					else {
						return false;
					}
				}

				else {
					throw ('La Cédula debe tener 7 u 8 caracteres.');
				}
			}
		}
		catch (ex) { 
      toaster.pop('info', "", ex);
		}
	};

}]);
